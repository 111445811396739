// src/components/universities/BahcesehirUniversity.js
import React from "react";
import AydinUniversityTop from "./Aydin1";
import AydinUniversityMiddle from "./Aydin2";
import AydinUniversityBottom from "./Aydin3";

const AydinUniversity = (props) => (
  <>
    <AydinUniversityTop lang={props.lang} />
    <AydinUniversityMiddle lang={props.lang} />
    <AydinUniversityBottom lang={props.lang} />
  </>
);

AydinUniversity.defaultProps = {
  lang: "fa",
};

export default AydinUniversity;
