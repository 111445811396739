import React, { useState } from "react";
import "../../components/styles/Uni.css";
import "../../components/styles/Uni2.css";
import { FaCheckCircle } from "react-icons/fa";
import harvardImage from "../../assets/image/uni-card/harvard.webp"; // برای نمونه، در صورت نیاز تغییر دهید
import oxfordImage from "../../assets/image/uni-card/oxford.webp";
import tokyoImage from "../../assets/image/uni-card/tokyo.webp";
import technicalImage from "../../assets/image/uni-card/Technical.webp";
import uniManagerImage from "../../assets/image/uni-card/UniManager.png";

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const convertPersianDigitsToEnglish = (str) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let result = str;
  persianDigits.forEach((pd, index) => {
    const reg = new RegExp(pd, "g");
    result = result.replace(reg, englishDigits[index]);
  });
  return result;
};

// نگاشت تاریخ‌های جلالی به میلادی برای داده‌های ثابت
const jalaliToGregorianMapping = {
  "1402/06/10": "2023/09/01",
  "1402/05/25": "2023/08/16",
  "1402/03/20": "2023/06/10",
  "1402/02/10": "2023/04/30",
  "1402/01/30": "2023/04/19",
  "1404/03/10": "2025/05/31",  // 10 خرداد 1404 ~ 31 می 2025
  "1404/03/20": "2025/06/10",  // 20 خرداد 1404 ~ 10 ژوئن 2025
  "1404/04/05": "2025/06/26",  // 5 تیر 1404 ~ 26 ژوئن 2025
  "1404/04/10": "2025/07/01",  // 10 تیر 1404 ~ 1 ژوئیه 2025
  "1404/05/15": "2025/08/06"   // 15 مرداد 1404 ~ 6 اوت 2025
};


// تابع تبدیل تاریخ بر اساس زبان
const formatDate = (dateStr, lang) => {
  if (lang === "fa") return dateStr;
  
  const engDate = convertPersianDigitsToEnglish(dateStr);
  console.log("Converted date:", engDate); // بررسی تاریخ تبدیل شده
  
  if (jalaliToGregorianMapping[engDate]) {
    return jalaliToGregorianMapping[engDate];
  }
  return engDate;
};


const translations = {
  // عنوان دانشکده‌ها
  facultiesTitle: {
    fa: "دانشکده‌های دانشگاه نیشانتاشی",
    en: "Nişantaşı University Faculties",
    tr: "Nişantaşı Üniversitesi Fakülteleri"
  },
  // عنوان جدول شهریه
  tuitionTitle: {
    fa: "جدول شهریه دانشگاه نیشانتاشی در سال 2025",
    en: "Nişantaşı University Tuition Fees 2025",
    tr: "2025 Yılı Nişantaşı Üniversitesi Öğrenim Ücreti Tablosu"
  },
  tuitionTable: {
    headerFaculty: {
      fa: "رشته",
      en: "Faculty",
      tr: "Bölüm"
    },
    headerFee: {
      fa: "شهریه (سالانه)",
      en: "Tuition Fee (Yearly)",
      tr: "Yıllık Öğrenim Ücreti"
    },
    headerDescription: {
      fa: "توضیحات",
      en: "Description",
      tr: "Açıklama"
    }
  },
  // اخبار و رویدادها
  newsTitle: {
    fa: "اخبار و رویدادها",
    en: "News and Events",
    tr: "Haberler ve Etkinlikler"
  },
  newsData: [
    {
      title: {
        fa: "افتتاح مرکز نوآوری جدید دانشگاه",
        en: "New Innovation Center Inaugurated at Nişantaşı University",
        tr: "Nişantaşı Üniversitesi'nde Yeni İnovasyon Merkezi Açıldı"
      },
      date: "۱۴۰۴/۰۳/۱۰",
      description: {
        fa: "دانشگاه نیشانتاشی مرکز نوآوری پیشرفته خود را افتتاح کرد تا امکانات پژوهشی و کارآفرینی دانشجویان را افزایش دهد.",
        en: "Nişantaşı University inaugurated its advanced innovation center to boost research and entrepreneurial opportunities for students.",
        tr: "Nişantaşı Üniversitesi, öğrencilerin araştırma ve girişimcilik olanaklarını artırmak amacıyla gelişmiş inovasyon merkezini açtı."
      }
    },
    {
      title: {
        fa: "برگزاری همایش بین‌المللی فناوری",
        en: "International Technology Conference Held",
        tr: "Uluslararası Teknoloji Konferansı Düzenlendi"
      },
      date: "۱۴۰۴/۰۴/۰۵",
      description: {
        fa: "در این همایش، متخصصان بین‌المللی در زمینه فناوری‌های نوین دیدگاه‌های خود را به اشتراک گذاشتند.",
        en: "At the conference, international experts shared their insights on emerging technologies.",
        tr: "Konferansta, uluslararası uzmanlar yeni teknolojiler hakkında görüşlerini paylaştı."
      }
    },
    {
      title: {
        fa: "گسترش همکاری‌های بین‌المللی",
        en: "Expansion of International Collaborations",
        tr: "Uluslararası İşbirliklerinin Genişletilmesi"
      },
      date: "۱۴۰۴/۰۵/۱۵",
      description: {
        fa: "دانشگاه نیشانتاشی با دانشگاه‌های برتر جهان همکاری‌های پژوهشی خود را افزایش داده است.",
        en: "Nişantaşı University has increased its research collaborations with top global universities.",
        tr: "Nişantaşı Üniversitesi, dünya çapındaki önde gelen üniversitelerle araştırma işbirliklerini artırdı."
      }
    }
  ],
  // مقالات تحقیقاتی و پژوهش‌های دانشجویی
  researchTitle: {
    fa: "مقالات تحقیقاتی و پژوهش‌های دانشجویی",
    en: "Research Articles and Student Research",
    tr: "Araştırma Makaleleri ve Öğrenci Araştırmaları"
  },
  researchData: [
    {
      title: {
        fa: "تحلیل روند نوآوری در آموزش",
        en: "Analyzing Innovation Trends in Education",
        tr: "Eğitimde İnovasyon Trendlerinin Analizi"
      },
      date: "۱۴۰۴/۰۳/۲۰",
      description: {
        fa: "مقاله‌ای پژوهشی در خصوص تاثیر فناوری‌های نوین بر روند نوآوری در آموزش و پرورش.",
        en: "A research article on the impact of emerging technologies on innovation trends in education.",
        tr: "Eğitimde inovasyon trendleri üzerine yeni teknolojilerin etkisi hakkında araştırma makalesi."
      }
    },
    {
      title: {
        fa: "توسعه استراتژی‌های پژوهشی در دانشگاه",
        en: "Developing Research Strategies in University",
        tr: "Üniversitede Araştırma Stratejilerinin Geliştirilmesi"
      },
      date: "۱۴۰۴/۰۴/۱۰",
      description: {
        fa: "بررسی راهکارهای نوین جهت تقویت پژوهش‌های دانشجویی و استادان در دانشگاه.",
        en: "Exploring innovative approaches to strengthen research initiatives among students and faculty.",
        tr: "Öğrenciler ve akademisyenler arasında araştırma faaliyetlerini güçlendirmek için yenilikçi yaklaşımların incelenmesi."
      }
    }
  ],
  // سوالات متداول (7 مورد)
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  faqData: [
    {
      question: {
        fa: "شرایط پذیرش دانشگاه نیشانتاشی چیست؟",
        en: "What are the admission requirements for Nişantaşı University?",
        tr: "Nişantaşı Üniversitesi'ne kabul şartları nelerdir?"
      },
      answer: {
        fa: "برای پذیرش مدارک تحصیلی معتبر، نمرات مطلوب و در صورت نیاز مصاحبه الزامی است.",
        en: "Valid academic certificates, competitive grades, and sometimes an interview are required.",
        tr: "Geçerli akademik belgeler, yeterli notlar ve bazen mülakat gerekmektedir."
      }
    },
    {
      question: {
        fa: "شهریه دانشگاه چقدر است؟",
        en: "What is the tuition fee?",
        tr: "Öğrenim ücreti nedir?"
      },
      answer: {
        fa: "شهریه بسته به رشته از ۳۵۰۰ تا ۹۰۰۰ دلار متغیر است.",
        en: "Tuition fee varies between $3,500 and $9,000 depending on the program.",
        tr: "Öğrenim ücreti, programa bağlı olarak 3,500 ila 9,000 dolar arasında değişmektedir."
      }
    },
    {
      question: {
        fa: "آیا مدرک زبان انگلیسی الزامی است؟",
        en: "Is an English language proficiency certificate required?",
        tr: "İngilizce yeterlilik belgesi gerekli mi?"
      },
      answer: {
        fa: "بله، برای برنامه‌هایی که به زبان انگلیسی تدریس می‌شوند، مدرک زبان انگلیسی (مانند آیلتس یا تافل) الزامی است.",
        en: "Yes, for programs taught in English, an English language proficiency certificate (such as IELTS or TOEFL) is required.",
        tr: "Evet, İngilizce olarak verilen programlar için IELTS veya TOEFL gibi bir İngilizce yeterlilik belgesi gereklidir."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه امکانات خوابگاهی ارائه می‌دهد؟",
        en: "Does the university offer on-campus accommodation?",
        tr: "Üniversitenin yurt imkanları var mı?"
      },
      answer: {
        fa: "بله، دانشگاه نیشانتاشی امکانات خوابگاهی مدرن و کاملی برای دانشجویان ارائه می‌دهد.",
        en: "Yes, Nişantaşı University offers modern and comprehensive on-campus accommodation for its students.",
        tr: "Evet, Nişantaşı Üniversitesi öğrencilere modern ve kapsamlı yurt imkanları sunmaktadır."
      }
    },
    {
      question: {
        fa: "برای دانشجویان بین‌المللی چه مدارکی لازم است؟",
        en: "What documents are required for international students?",
        tr: "Uluslararası öğrenciler için hangi belgeler gereklidir?"
      },
      answer: {
        fa: "دانشجویان بین‌المللی باید مدارکی از قبیل پاسپورت معتبر، ریزنمرات، مدرک زبان و در صورت نیاز توصیه‌نامه ارائه دهند.",
        en: "International students must provide a valid passport, academic transcripts, a language proficiency certificate, and recommendation letters if required.",
        tr: "Uluslararası öğrenciler geçerli pasaport, akademik transkript, dil yeterlilik belgesi ve gerekiyorsa referans mektupları sunmalıdır."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه بورسیه ارائه می‌دهد؟",
        en: "Does the university offer scholarships?",
        tr: "Üniversite burs sağlıyor mu?"
      },
      answer: {
        fa: "بله، دانشگاه نیشانتاشی انواع بورسیه‌های کامل و جزئی برای دانشجویان مستعد ارائه می‌دهد.",
        en: "Yes, Nişantaşı University offers both full and partial scholarships for deserving students.",
        tr: "Evet, Nişantaşı Üniversitesi başarılı öğrenciler için tam و kısmi burs seçenekleri sunmaktadır."
      }
    },
    {
      question: {
        fa: "آیا برنامه‌های تبادل دانشجو وجود دارد؟",
        en: "Are there student exchange programs available?",
        tr: "Öğrenci değişim programları mevcut mu?"
      },
      answer: {
        fa: "بله، دانشگاه نیشانتاشی با دانشگاه‌های بین‌المللی همکاری می‌کند و برنامه‌های تبادل دانشجو را ارائه می‌دهد.",
        en: "Yes, Nişantaşı University collaborates with international institutions and offers student exchange programs.",
        tr: "Evet, Nişantaşı Üniversitesi uluslararası kurumlarla işbirliği yaparak öğrenci değişim programları sunmaktadır."
      }
    }
  ],
  // همکاری‌های بین‌المللی
  collaborationTitle: {
    fa: "همکاری‌های بین‌المللی",
    en: "International Collaborations",
    tr: "Uluslararası İşbirlikleri"
  },
  collaborationData: [
    {
      name: {
        fa: "دانشگاه هاروارد",
        en: "Harvard University",
        tr: "Harvard Üniversitesi"
      },
      link: "https://www.harvard.edu/",
      ranking: "1",
      icon: <FaCheckCircle />,
      background: harvardImage
    },
    {
      name: {
        fa: "دانشگاه آکسفورد",
        en: "Oxford University",
        tr: "Oxford Üniversitesi"
      },
      link: "https://www.ox.ac.uk/",
      ranking: "2",
      icon: <FaCheckCircle />,
      background: oxfordImage
    },
    {
      name: {
        fa: "دانشگاه توکیو",
        en: "University of Tokyo",
        tr: "Tokyo Üniversitesi"
      },
      link: "https://www.u-tokyo.ac.jp/en",
      ranking: "3",
      icon: <FaCheckCircle />,
      background: tokyoImage
    },
    {
      name: {
        fa: "دانشگاه فنی مونیخ",
        en: "Technical University of Munich",
        tr: "Münih Teknik Üniversitesi"
      },
      link: "https://www.tum.de/",
      ranking: "4",
      icon: <FaCheckCircle />,
      background: technicalImage
    }
  ],
  // کارآفرینی و فرصت‌های شغلی
  entrepreneurshipTitle: {
    fa: "کارآفرینی و فرصت‌های شغلی",
    en: "Entrepreneurship and Job Opportunities",
    tr: "Girişimcilik ve İş Fırsatları"
  },
  entrepreneurshipData: [
    {
      title: {
        fa: "کارگاه کارآفرینی دانشجویی",
        en: "Student Entrepreneurship Workshop",
        tr: "Öğrenci Girişimcilik Atölyesi"
      },
      description: {
        fa: "برگزاری کارگاه‌های آموزشی با حضور کارآفرینان موفق جهت ارتقاء مهارت‌های کسب و کار دانشجویان.",
        en: "Hosting workshops with successful entrepreneurs to enhance students' business skills.",
        tr: "Öğrencilerin iş becerilerini artırmak amacıyla başarılı girişimcilerle atölye çalışmaları düzenleniyor."
      }
    },
    {
      title: {
        fa: "راه‌اندازی استارتاپ دانشجویی",
        en: "Student Startup Launch",
        tr: "Öğrenci Start-up Lansmanı"
      },
      description: {
        fa: "تشویق به راه‌اندازی استارتاپ‌های دانشجویی با حمایت از طرح‌های نوآورانه و ارائه منابع مالی.",
        en: "Encouraging student startups by supporting innovative projects and providing financial resources.",
        tr: "Yenilikçi projelere destek ve finansal kaynak sağlayarak öğrenci start-up'ları teşvik ediliyor."
      }
    },
    {
      title: {
        fa: "نمایشگاه نوآوری و کارآفرینی",
        en: "Innovation and Entrepreneurship Expo",
        tr: "İnovasyon ve Girişimcilik Fuarı"
      },
      description: {
        fa: "برگزاری نمایشگاه سالانه جهت معرفی ایده‌های نوآورانه و ایجاد فرصت‌های شغلی.",
        en: "An annual expo showcasing innovative ideas and creating job opportunities.",
        tr: "Yenilikçi fikirlerin tanıtıldığı ve iş fırsatlarının yaratıldığı yıllık fuar."
      }
    }
  ],
  // خدمات مالی و بورسیه‌ها
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  financialAidData: [
    {
      title: {
        fa: "بورسیه کامل تحصیلی",
        en: "Full Scholarship",
        tr: "Tam Burs"
      },
      description: {
        fa: "پوشش کامل شهریه و هزینه‌های زندگی برای دانشجویان برجسته.",
        en: "Full coverage of tuition and living expenses for outstanding students.",
        tr: "Başarılı öğrenciler için öğrenim ve yaşam giderlerinin tam karşılanması."
      }
    },
    {
      title: {
        fa: "بورسیه جزئی",
        en: "Partial Scholarship",
        tr: "Kısmi Burs"
      },
      description: {
        fa: "تخفیف قابل توجه در شهریه همراه با حمایت‌های مالی.",
        en: "Significant tuition discount along with partial financial aid.",
        tr: "Önemli öğrenim ücreti indirimi ve kısmi maddi destek."
      }
    },
    {
      title: {
        fa: "وام دانشجویی ویژه",
        en: "Special Student Loan",
        tr: "Özel Öğrenci Kredisi"
      },
      description: {
        fa: "امکان دریافت وام با شرایط مساعد جهت تأمین هزینه‌های تحصیلی.",
        en: "Availability of student loans under favorable conditions to cover tuition fees.",
        tr: "Öğrenim giderlerini karşılamak için uygun şartlarda öğrenci kredisi imkanı."
      }
    }
  ],
  // اعلان ویژه بورسیه
  announcementTitle: {
    fa: "🎓 فرصت ویژه: بورسیه تحصیلی 50%",
    en: "🎓 Special Offer: 50% Scholarship Opportunity",
    tr: "🎓 Özel Fırsat: %50 Burs İmkanı"
  },
  announcementText: {
    fa: "با ثبت‌نام در سیستم UniManagaer، درهای موفقیت برای شما باز می‌شود. تنها با چند کلیک، می‌توانید از بورسیه ۵۰ درصدی بهره‌مند شده و هزینه‌های تحصیلی خود را کاهش دهید. این پیشنهاد محدود، فرصتی است برای دانشجویان آینده‌ساز.",
    en: "Register through UniManagaer and unlock the door to success. With just a few clicks, you can benefit from an exclusive 50% scholarship and reduce your tuition fees. This limited-time offer is a chance for tomorrow’s leaders.",
    tr: "UniManagaer üzerinden kayıt olarak başarı kapılarını açın. Sadece birkaç tıkla özel %50 burs fırsatından yararlanıp öğrenim ücretinizi düşürebilirsiniz. Bu sınırlı teklif, geleceğin liderleri için bir fırsattır."
  }
};

const facultiesData = [
  {
    name: {
      fa: "دانشکده اقتصاد، مدیریت و علوم اجتماعی",
      en: "Faculty of Economics, Administration and Social Sciences",
      tr: "İktisadi, İdari ve Sosyal Bilimler Fakültesi"
    },
    description: {
      fa: "ارائه برنامه‌های نوآورانه در زمینه‌های اقتصاد، مدیریت، روان‌شناسی، روابط بین‌الملل و جامعه‌شناسی با تمرکز بر مهارت‌های کاربردی.",
      en: "Innovative programs in economics, management, psychology, international relations, and sociology with a focus on practical skills.",
      tr: "Ekonomi, işletme, psikoloji, uluslararası ilişkiler ve sosyoloji alanlarında yenilikçi ve uygulamalı programlar sunar."
    }
  },
  {
    name: {
      fa: "دانشکده مهندسی",
      en: "Faculty of Engineering",
      tr: "Mühendislik Fakültesi"
    },
    description: {
      fa: "آموزش پیشرفته در رشته‌های مهندسی کامپیوتر، برق، صنایع و نرم‌افزار با تاکید بر فناوری‌های نوین.",
      en: "Advanced education in computer, electrical, industrial, and software engineering with an emphasis on emerging technologies.",
      tr: "Bilgisayar, elektrik, endüstri ve yazılım mühendisliği alanlarında ileri düzey eğitim ve yenilikçi yaklaşımlar."
    }
  },
  {
    name: {
      fa: "دانشکده هنر و طراحی",
      en: "Faculty of Art and Design",
      tr: "Sanat ve Tasarım Fakültesi"
    },
    description: {
      fa: "برنامه‌هایی خلاقانه در طراحی مد، گرافیک، طراحی داخلی و رسانه‌های دیجیتال.",
      en: "Creative programs in fashion design, graphic design, interior design, and digital media.",
      tr: "Moda tasarımı, grafik, iç mimarlık ve dijital medya alanlarında yaratıcı programlar sunar."
    }
  },
  {
    name: {
      fa: "دانشکده علوم سلامت",
      en: "Faculty of Health Sciences",
      tr: "Sağlık Bilimleri Fakültesi"
    },
    description: {
      fa: "برنامه‌های آموزشی در پرستاری، فیزیوتراپی، تغذیه و رژیم درمانی، با رویکرد عملی و انسانی.",
      en: "Educational programs in nursing, physiotherapy, nutrition and dietetics with a practical and human-centered approach.",
      tr: "Hemşirelik, fizyoterapi, beslenme ve diyetetik alanlarında uygulamalı ve insan odaklı programlar."
    }
  },
  {
    name: {
      fa: "دانشکده علوم کاربردی",
      en: "Faculty of Applied Sciences",
      tr: "Uygulamalı Bilimler Fakültesi"
    },
    description: {
      fa: "تربیت متخصصان در حوزه‌های رسانه، ارتباطات، مدیریت گردشگری و فناوری اطلاعات.",
      en: "Training professionals in media, communication, tourism management, and information technologies.",
      tr: "Medya, iletişim, turizm yönetimi ve bilişim teknolojileri alanlarında uzmanlar yetiştirir."
    }
  },
  {
    name: {
      fa: "دانشکده آموزش",
      en: "Faculty of Education",
      tr: "Eğitim Fakültesi"
    },
    description: {
      fa: "برنامه‌هایی برای تربیت معلمان و پژوهشگران در حوزه آموزش مدرن و روان‌شناسی تربیتی.",
      en: "Programs for training teachers and researchers in modern education and educational psychology.",
      tr: "Modern eğitim ve eğitim psikolojisi alanlarında öğretmen ve araştırmacılar yetiştirir."
    }
  },
  {
    name: {
      fa: "دانشکده دندان‌پزشکی",
      en: "Faculty of Dentistry",
      tr: "Diş Hekimliği Fakültesi"
    },
    description: {
      fa: "آموزش نظری و عملی در دندان‌پزشکی با بهره‌گیری از تجهیزات پیشرفته.",
      en: "Theoretical and practical education in dentistry with advanced equipment and facilities.",
      tr: "Gelişmiş donanımlarla diş hekimliği alanında teorik ve pratik eğitim verir."
    }
  }
];

const FacultyCard = ({ name, description }) => (
  <div className="uni-faculty-card">
    <h3 className="uni-faculty-card-title">{name}</h3>
    <p className="uni-faculty-card-text">{description}</p>
  </div>
);

const TuitionTable = ({ lang }) => {
  const tuitionData = [
    {
      faculty: { fa: "علوم سلامت", en: "Health Sciences", tr: "Sağlık Bilimleri" },
      fee: { fa: "$18,000", en: "$18,000", tr: "$18,000" },
      description: {
        fa: "پرستاری، فیزیوتراپی، تغذیه و رژیم درمانی.",
        en: "Nursing, Physiotherapy, Nutrition and Dietetics.",
        tr: "Hemşirelik, Fizyoterapi, Beslenme ve Diyetetik."
      }
    },
    {
      faculty: { fa: "مهندسی", en: "Engineering", tr: "Mühendislik" },
      fee: { fa: "$16,500", en: "$16,500", tr: "$16,500" },
      description: {
        fa: "مهندسی کامپیوتر، برق، نرم‌افزار، صنایع.",
        en: "Computer, Electrical, Software, Industrial Engineering.",
        tr: "Bilgisayar, Elektrik, Yazılım, Endüstri Mühendisliği."
      }
    },
    {
      faculty: { fa: "هنر و طراحی", en: "Art and Design", tr: "Sanat ve Tasarım" },
      fee: { fa: "$20,000", en: "$20,000", tr: "$20,000" },
      description: {
        fa: "طراحی گرافیک، طراحی مد، طراحی داخلی، رسانه‌های دیجیتال.",
        en: "Graphic Design, Fashion Design, Interior Design, Digital Media.",
        tr: "Grafik Tasarımı, Moda Tasarımı, İç Mimarlık, Dijital Medya."
      }
    },
    {
      faculty: { fa: "اقتصاد و علوم اجتماعی", en: "Economics and Social Sciences", tr: "İktisadi ve Sosyal Bilimler" },
      fee: { fa: "$15,000", en: "$15,000", tr: "$15,000" },
      description: {
        fa: "روابط بین‌الملل، جامعه‌شناسی، روان‌شناسی، اقتصاد، علوم سیاسی.",
        en: "International Relations, Sociology, Psychology, Economics, Political Science.",
        tr: "Uluslararası İlişkiler, Sosyoloji, Psikoloji, Ekonomi, Siyaset Bilimi."
      }
    },
    {
      faculty: { fa: "مدیریت", en: "Management", tr: "Yönetim" },
      fee: { fa: "$17,000", en: "$17,000", tr: "$17,000" },
      description: {
        fa: "مدیریت بازرگانی، مدیریت گردشگری، بازاریابی، مالی.",
        en: "Business Administration, Tourism Management, Marketing, Finance.",
        tr: "İşletme, Turizm Yönetimi, Pazarlama, Finans."
      }
    },
    {
      faculty: { fa: "آموزش", en: "Education", tr: "Eğitim" },
      fee: { fa: "$13,000", en: "$13,000", tr: "$13,000" },
      description: {
        fa: "آموزش ابتدایی، آموزش زبان انگلیسی، روان‌شناسی تربیتی.",
        en: "Primary Education, English Language Teaching, Educational Psychology.",
        tr: "İlköğretim, İngilizce Öğretmenliği, Eğitim Psikolojisi."
      }
    },
    {
      faculty: { fa: "حقوق", en: "Law", tr: "Hukuk" },
      fee: { fa: "$19,000", en: "$19,000", tr: "$19,000" },
      description: {
        fa: "برنامه حقوق بین‌الملل و داخلی با رویکرد عدالت اجتماعی.",
        en: "International and national law programs with a focus on social justice.",
        tr: "Ulusal ve uluslararası hukuk programları, sosyal adalet odaklı."
      }
    },
    {
      faculty: { fa: "علوم کاربردی", en: "Applied Sciences", tr: "Uygulamalı Bilimler" },
      fee: { fa: "$16,000", en: "$16,000", tr: "$16,000" },
      description: {
        fa: "رسانه و ارتباطات، مدیریت اطلاعات، فناوری اطلاعات.",
        en: "Media and Communication, Information Management, IT.",
        tr: "Medya ve İletişim, Bilgi Yönetimi, Bilişim Teknolojileri."
      }
    },
    {
      faculty: { fa: "دندان‌پزشکی", en: "Dentistry", tr: "Diş Hekimliği" },
      fee: { fa: "$25,000", en: "$25,000", tr: "$25,000" },
      description: {
        fa: "برنامه پنج‌ساله دندان‌پزشکی با رویکرد عملی و کلینیکی.",
        en: "Five-year dentistry program with clinical and practical training.",
        tr: "Beş yıllık klinik ve pratik ağırlıklı diş hekimliği programı."
      }
    }
  ];

  const headers =
    lang === "fa"
      ? [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerFee[lang],
          translations.tuitionTable.headerDescription[lang]
        ]
      : [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerDescription[lang],
          translations.tuitionTable.headerFee[lang]
        ];

  return (
    <table className="uni-tuition-table">
      <thead>
        <tr>
          <th>{headers[0]}</th>
          <th>{headers[1]}</th>
          <th>{headers[2]}</th>
        </tr>
      </thead>
      <tbody>
        {tuitionData.map((item, index) =>
          lang === "fa" ? (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.fee[lang]}</td>
              <td>{item.description[lang]}</td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.description[lang]}</td>
              <td>{item.fee[lang]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const FAQSection = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);
  return (
    <section className="uni-faq-section">
      <h2 className="uni-faq-title">{translations.faqTitle[lang]}</h2>
      <div className="uni-faq-grid">
        {translations.faqData.map((faq, index) => (
          <div key={index} className="uni-faq-card" onClick={() => toggleFAQ(index)}>
            <h3 className="uni-faq-question">{faq.question[lang]}</h3>
            {activeIndex === index && <p className="uni-faq-answer">{faq.answer[lang]}</p>}
          </div>
        ))}
      </div>
    </section>
  );
};

const NisantasiUniversityMiddle = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className={`uni-container ${lang !== "fa" ? "ltr" : ""}`}>
      {/* دانشکده‌ها */}
      <section className="uni-faculties-section">
        <h2 className="uni-faculties-title">{translations.facultiesTitle[lang]}</h2>
        <div className="uni-faculties-grid">
          {facultiesData && facultiesData.length > 0 ? (
            facultiesData.map((item, index) => (
              <FacultyCard key={index} name={item.name[lang]} description={item.description[lang]} />
            ))
          ) : (
            <p>{translations.facultiesTitle[lang]} data not available.</p>
          )}
        </div>
      </section>

      {/* جدول شهریه */}
      <section className="uni-tuition-section">
        <h2 className="uni-tuition-title">{translations.tuitionTitle[lang]}</h2>
        <TuitionTable lang={lang} />
      </section>

      {/* اخبار و رویدادها */}
      <section className="uni-news-section">
        <h2 className="uni-news-title">{translations.newsTitle[lang]}</h2>
        <div className="uni-news-grid">
          {translations.newsData && translations.newsData.length > 0 ? (
            translations.newsData.map((news, index) => (
              <div key={index} className="uni-news-card">
                <h3 className="uni-news-card-title">{news.title[lang]}</h3>
                <span className="uni-news-card-date">{formatDate(news.date, lang)}</span>
                <p className="uni-news-card-text">{news.description[lang]}</p>
              </div>
            ))
          ) : (
            <p>{translations.newsTitle[lang]} data not available.</p>
          )}
        </div>
      </section>

      {/* مقالات تحقیقاتی و پژوهش‌های دانشجویی */}
      <section className="uni-research-section">
        <h2 className="uni-research-title">{translations.researchTitle[lang]}</h2>
        <div className="uni-research-grid">
          {translations.researchData && translations.researchData.length > 0 ? (
            translations.researchData.map((article, index) => (
              <div key={index} className="uni-research-card">
                <h3 className="uni-research-card-title">{article.title[lang]}</h3>
                <span className="uni-research-card-date">{formatDate(article.date, lang)}</span>
                <p className="uni-research-card-text">{article.description[lang]}</p>
              </div>
            ))
          ) : (
            <p>{translations.researchTitle[lang]} data not available.</p>
          )}
        </div>
      </section>

      {/* همکاری‌های بین‌المللی */}
      <section className="uni-collaboration-section">
        <h2 className="uni-collaboration-title">{translations.collaborationTitle[lang]}</h2>
        <div className="uni-collaboration-grid">
          {translations.collaborationData && translations.collaborationData.length > 0 ? (
            translations.collaborationData.map((item, index) => (
              <div
                key={index}
                className="uni-collaboration-item"
                style={{
                  backgroundImage: `url(${item.background})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
              >
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.name[lang]}
                </a>
                <div className="uni-collaboration-ranking">
                  {lang === "fa"
                    ? `رتبه جهانی: ${item.ranking}`
                    : `Global Ranking: ${item.ranking}`}
                  {item.icon}
                </div>
              </div>
            ))
          ) : (
            <p>{translations.collaborationTitle[lang]} data not available.</p>
          )}
        </div>
      </section>

      {/* کارآفرینی و فرصت‌های شغلی */}
      <section className="uni-entrepreneurship-section">
        <h2 className="uni-entrepreneurship-title">{translations.entrepreneurshipTitle[lang]}</h2>
        <div className="uni-entrepreneurship-grid">
          {translations.entrepreneurshipData && translations.entrepreneurshipData.length > 0 ? (
            translations.entrepreneurshipData.map((item, index) => (
              <div key={index} className="uni-entrepreneurship-card">
                <h3 className="uni-entrepreneurship-card-title">{item.title[lang]}</h3>
                <p className="uni-entrepreneurship-card-text">{item.description[lang]}</p>
              </div>
            ))
          ) : (
            <p>{translations.entrepreneurshipTitle[lang]} data not available.</p>
          )}
        </div>
      </section>

      {/* اعلان ویژه بورسیه */}
      <section className="uni-announcement-section">
        <div
          className="announcement-bg-overlay"
          style={{ backgroundImage: `url(${uniManagerImage})` }}
        ></div>
        <div className="announcement-content">
          <h2 className="uni-announcement-title">{translations.announcementTitle[lang]}</h2>
          <p className="uni-announcement-text">{translations.announcementText[lang]}</p>
        </div>
      </section>

      {/* سوالات متداول */}
      <FAQSection lang={lang} />

      {/* خدمات مالی و بورسیه */}
      <section className="uni-financial-section">
        <h2 className="uni-financial-title">{translations.financialTitle[lang]}</h2>
        <div className="uni-financial-grid">
          {translations.financialAidData && translations.financialAidData.length > 0 ? (
            translations.financialAidData.map((item, index) => (
              <div key={index} className="uni-financial-card">
                <h3 className="uni-financial-card-title">{item.title[lang]}</h3>
                <p className="uni-financial-card-text">{item.description[lang]}</p>
              </div>
            ))
          ) : (
            <p>{translations.financialTitle[lang]} data not available.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default NisantasiUniversityMiddle;
