import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  // زبان پیش‌فرض فارسی (کد: "fa")
  const [language, setLanguage] = useState("fa");

  const handleLanguageChange = (selectedLanguage) => {
    let mappedLang = "en";
    if (selectedLanguage === "Persian") mappedLang = "fa";
    else if (selectedLanguage === "Turkish") mappedLang = "tr";
    setLanguage(mappedLang);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
};
