import React from "react";
import "./styles/Dormitories.css"; // مسیر صحیح فایل CSS
import Lottie from "react-lottie";
import animationData from "../assets/animation/Dormitories.json"; // انیمیشن خوابگاه‌ها
import Footer from "../components/Footer"; // کامپوننت فوتر
import { useLanguage } from "../contexts/LanguageContext";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// ثبت المان‌های مورد نیاز Chart.js
Chart.register(ArcElement, Tooltip, Legend);

// تعریف تصاویر خوابگاه‌ها
const dormitoryImages = {
  bahcesehir: require("../assets/image/Dormitory/Bahçeşehir.webp"),
  medipol: require("../assets/image/Dormitory/Medipol.webp"),
  atlas: require("../assets/image/Dormitory/Atlas.webp"),
  aydin: require("../assets/image/Dormitory/Aydın.webp"),
  nisantasi: require("../assets/image/Dormitory/Nişantaşı.webp"),
};

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const persianToEnglish = (str) => {
  const persianDigits = ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
  let englishStr = str;
  for (let i = 0; i < persianDigits.length; i++) {
    englishStr = englishStr.replace(new RegExp(persianDigits[i], "g"), i);
  }
  return englishStr;
};

// تابع کمکی برای استخراج عدد قیمت از رشته قیمت (برای محتوای انگلیسی و فارسی)
const parsePrice = (priceStr) => {
  const normalizedStr = persianToEnglish(priceStr);
  const match = normalizedStr.match(/(\d+)/);
  return match ? parseInt(match[1], 10) : 0;
};

const Dormitories = () => {
  const { language } = useLanguage();

  // محتوا به سه زبان: انگلیسی، فارسی و ترکی
  const content = {
    en: {
      header: "Student Dormitory Conditions",
      description:
        "We offer trusted dormitory reservation services for students enrolling at top universities in Istanbul. All listed dormitories are officially affiliated with their respective universities and provide full services in line with 2025 standards.",
      dorms: [
        {
          id: 1,
          name: "Bahçeşehir University Dormitory (Yurt Bahçeşehir)",
          location: "Beşiktaş & Galata Campuses, Istanbul",
          facilities:
            "Separate male/female buildings, 24/7 security, study rooms, WiFi, furnished rooms",
          price: "From 4500 TL/month",
          image: dormitoryImages.bahcesehir,
        },
        {
          id: 2,
          name: "Medipol University Dormitory",
          location: "Haliç & Kavacık Campuses, Istanbul",
          facilities:
            "On-campus dorms, cafeteria, gym, laundry, WiFi, private and shared room options",
          price: "From 4000 TL/month",
          image: dormitoryImages.medipol,
        },
        {
          id: 3,
          name: "Atlas University Student Residence",
          location: "Kağıthane Campus, Istanbul",
          facilities:
            "Modern design, in-room kitchenettes, security, high-speed internet, common areas",
          price: "From 3800 TL/month",
          image: dormitoryImages.atlas,
        },
        {
          id: 4,
          name: "Istanbul Aydın University Dormitory",
          location: "Florya Campus, Istanbul",
          facilities:
            "Separate male/female sections, study halls, cleaning service, internet access",
          price: "From 3900 TL/month",
          image: dormitoryImages.aydin,
        },
        {
          id: 5,
          name: "Nişantaşı University Dormitory",
          location: "Maslak Campus, Istanbul",
          facilities:
            "In-campus modern facilities, double and triple rooms, 24/7 support, furnished",
          price: "From 3700 TL/month",
          image: dormitoryImages.nisantasi,
        },
      ],
    },
    fa: {
      header: "شرایط خوابگاه های دانشجویی",
      description:
        "ما خدمات رسمی و قابل‌اعتماد رزرو خوابگاه برای دانشجویان دانشگاه‌های برتر استانبول ارائه می‌دهیم. تمامی خوابگاه‌های لیست‌شده متعلق به خود دانشگاه بوده و مطابق با استانداردهای سال ۲۰۲۵ خدمات کامل ارائه می‌دهند.",
      dorms: [
        {
          id: 1,
          name: "خوابگاه دانشگاه باهچه‌شهیر",
          location: "کمپوس بشیکتاش و گالاتا، استانبول",
          facilities:
            "ساختمان‌های جداگانه دخترانه و پسرانه، امنیت ۲۴ ساعته، سالن مطالعه، اینترنت، اتاق‌های مبله",
          price: "از ۴۵۰۰ لیر در ماه",
          image: dormitoryImages.bahcesehir,
        },
        {
          id: 2,
          name: "خوابگاه دانشگاه مدیپل",
          location: "کمپوس هالیچ و کاواجیک، استانبول",
          facilities:
            "خوابگاه داخل پردیس، سلف سرویس، باشگاه ورزشی، لباسشویی، وای‌فای، اتاق‌های خصوصی و مشترک",
          price: "از ۴۰۰۰ لیر در ماه",
          image: dormitoryImages.medipol,
        },
        {
          id: 3,
          name: "خوابگاه دانشگاه اطلس",
          location: "کمپوس کاگی‌ت‌خانه، استانبول",
          facilities:
            "طراحی مدرن، آشپزخانه داخل اتاق، اینترنت پرسرعت، مناطق عمومی، امنیت کامل",
          price: "از ۳۸۰۰ لیر در ماه",
          image: dormitoryImages.atlas,
        },
        {
          id: 4,
          name: "خوابگاه دانشگاه آیدین استانبول",
          location: "کمپوس فلوریا، استانبول",
          facilities:
            "بخش‌های مجزای دختران و پسران، سالن مطالعه، خدمات نظافت، دسترسی به اینترنت",
          price: "از ۳۹۰۰ لیر در ماه",
          image: dormitoryImages.aydin,
        },
        {
          id: 5,
          name: "خوابگاه دانشگاه نیشانتاشی",
          location: "کمپوس ماسلاک، استانبول",
          facilities:
            "خوابگاه داخل دانشگاه، اتاق‌های دو و سه نفره، پشتیبانی ۲۴ ساعته، کاملاً مبله",
          price: "از ۳۷۰۰ لیر در ماه",
          image: dormitoryImages.nisantasi,
        },
      ],
    },
    tr: {
      header: "Öğrenci Yurt Şartları",
      description:
        "İstanbul’daki önde gelen üniversitelerde eğitim alacak öğrenciler için güvenilir yurt rezervasyon hizmeti sunuyoruz. Listelenen tüm yurtlar üniversitelerin kendi tesisleri olup 2025 standartlarına uygundur.",
      dorms: [
        {
          id: 1,
          name: "Bahçeşehir Üniversitesi Yurdu (Yurt Bahçeşehir)",
          location: "Beşiktaş ve Galata Kampüsleri, İstanbul",
          facilities:
            "Kız/erkek ayrı binalar, 7/24 güvenlik, çalışma salonları, WiFi, mobilyalı odalar",
          price: "Aylık 4500 TL’den başlayan fiyatlarla",
          image: dormitoryImages.bahcesehir,
        },
        {
          id: 2,
          name: "Medipol Üniversitesi Yurdu",
          location: "Haliç ve Kavacık Kampüsleri, İstanbul",
          facilities:
            "Kampüs içi yurtlar, yemekhane, spor salonu, çamaşırhane, internet, özel/ortak odalar",
          price: "Aylık 4000 TL’den başlayan fiyatlarla",
          image: dormitoryImages.medipol,
        },
        {
          id: 3,
          name: "Atlas Üniversitesi Öğrenci Rezidansı",
          location: "Kağıthane Kampüsü, İstanbul",
          facilities:
            "Modern tasarım, odada mini mutfak, yüksek hızlı internet, ortak alanlar, güvenlik",
          price: "Aylık 3800 TL’den başlayan fiyatlarla",
          image: dormitoryImages.atlas,
        },
        {
          id: 4,
          name: "İstanbul Aydın Üniversitesi Yurdu",
          location: "Florya Kampüsü, İstanbul",
          facilities:
            "Kız/erkek bölümleri, çalışma salonları, temizlik hizmeti, internet erişimi",
          price: "Aylık 3900 TL’den başlayan fiyatlarla",
          image: dormitoryImages.aydin,
        },
        {
          id: 5,
          name: "Nişantaşı Üniversitesi Yurdu",
          location: "Maslak Kampüsü, İstanbul",
          facilities:
            "Kampüs içi modern tesisler, iki/üç kişilik odalar, 7/24 destek, mobilyalı",
          price: "Aylık 3700 TL’den başlayan fiyatlarla",
          image: dormitoryImages.nisantasi,
        },
      ],
    },
  };

  // دریافت داده‌های خوابگاه‌ها برای زبان انتخابی
  const dormsData = content[language].dorms;
  
  // استخراج قیمت‌ها بر اساس ستون "شهریه" از جدول
  const priceValues = dormsData.map((dorm) => parsePrice(dorm.price));
  const dormNames = dormsData.map((dorm) => dorm.name);

  // استفاده از رنگ‌های خلاقانه و خنثی برای نمودار پای
  const pieData = {
    labels: dormNames,
    datasets: [
      {
        label: "Price Distribution",
        data: priceValues,
        backgroundColor: [
          "#8E9AAF",
          "#BFC0C0",
          "#D3C0CD",
          "#C3B1E1",
          "#A3C9A8"
        ],
        borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  return (
    <div className="dorm-container">
      {/* انیمیشن اصلی */}
      <div className="dorm-animation-container">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <header className="dorm-header">
        <h2>{content[language].header}</h2>
      </header>
      <section className={`dorm-info ${language === "fa" ? "dorm-persian-text" : ""}`}>
        <p className="dorm-description">{content[language].description}</p>
        {/* نمایش لیست خوابگاه‌ها به صورت جدول */}
        <table className="dorm-table">
          <thead>
            <tr>
              <th>
                {language === "en"
                  ? "Dormitory Name"
                  : language === "tr"
                  ? "Yurt Adı"
                  : "نام خوابگاه"}
              </th>
              <th>
                {language === "en"
                  ? "Location"
                  : language === "tr"
                  ? "Konum"
                  : "موقعیت"}
              </th>
              <th>
                {language === "en"
                  ? "Facilities"
                  : language === "tr"
                  ? "İmkanlar"
                  : "امکانات"}
              </th>
              <th>
                {language === "en"
                  ? "Price"
                  : language === "tr"
                  ? "Fiyat"
                  : "شهریه"}
              </th>
            </tr>
          </thead>
          <tbody>
            {dormsData.map((dorm) => (
              <tr key={dorm.id}>
                <td className="dorm-name">
                  <div className="dorm-name-content">
                    <div
                      className="dorm-image"
                      style={{ backgroundImage: `url(${dorm.image})` }}
                    ></div>
                    <span>{dorm.name}</span>
                  </div>
                </td>
                <td className="dorm-location">{dorm.location}</td>
                <td className="dorm-facilities">{dorm.facilities}</td>
                <td className="dorm-price">{dorm.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* هدر برای پای چارت - نمایش یکسان برای همه زبان‌ها */}
        <h3 style={{ textAlign: "center", marginTop: "40px" }}>
          مقایسه شهریه دانشگاه ها
        </h3>
        {/* نمایش پای چارت */}
        <div className="dorm-chart-container" style={{ height: "400px" }}>
          <Pie data={pieData} options={{ maintainAspectRatio: false }} />
        </div>
      </section>
      <Footer language={language} />
    </div>
  );
};

export default Dormitories;
