// src/components/universities/BahcesehirUniversity.js
import React from "react";
import AtlasUniversityTop from "./atlUni1";
import AtlasUniversityMiddle from "./atlUni2";
import AtlasUniversityBottom from "./atlUni3";

const AtlasUniversity = (props) => (
  <>
    <AtlasUniversityTop lang={props.lang} />
    <AtlasUniversityMiddle lang={props.lang} />
    <AtlasUniversityBottom lang={props.lang} />
  </>
);

AtlasUniversity.defaultProps = {
  lang: "fa",
};

export default AtlasUniversity;
