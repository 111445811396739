import React, { useState } from "react";
import "../../components/styles/Uni.css";
import { FaCheckCircle } from "react-icons/fa"; // آیکون معتبر بودن
import "../../components/styles/Uni2.css";
// استفاده از تصاویر دانشگاه باهچه‌شهیر (بعداً می‌توانید تغییر دهید)
import harvardImage from "../../assets/image/uni-card/harvard.webp";
import oxfordImage from "../../assets/image/uni-card/oxford.webp";
import tokyoImage from "../../assets/image/uni-card/tokyo.webp";
import technicalImage from "../../assets/image/uni-card/Technical.webp";
import uniManagerImage from "../../assets/image/uni-card/UniManager.png";

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const convertPersianDigitsToEnglish = (str) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let result = str;
  persianDigits.forEach((pd, index) => {
    const reg = new RegExp(pd, "g");
    result = result.replace(reg, englishDigits[index]);
  });
  return result;
};

// تابع کمکی برای تبدیل ارقام انگلیسی به فارسی
const convertEnglishDigitsToPersian = (str) => {
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  let result = str;
  englishDigits.forEach((ed, index) => {
    const reg = new RegExp(ed, "g");
    result = result.replace(reg, persianDigits[index]);
  });
  return result;
};

// نگاشت تاریخ‌های جلالی به میلادی برای داده‌های ثابت
const jalaliToGregorianMapping = {
  "1402/06/10": "2023/09/01",
  "1402/05/25": "2023/08/16",
  "1402/04/15": "2023/07/06",
  "1402/03/20": "2023/06/10",
  "1402/02/10": "2023/04/30",
  "1402/01/30": "2023/04/19"
};

// تابع تبدیل تاریخ بر اساس زبان
const formatDate = (dateStr, lang) => {
  if (lang === "fa") {
    // در حالت فارسی، تمامی ارقام به فارسی تبدیل شوند.
    return convertEnglishDigitsToPersian(dateStr);
  }
  // در زبان‌های دیگر ابتدا ارقام فارسی به انگلیسی تبدیل شده تا در نگاشت استفاده شود
  const engDate = convertPersianDigitsToEnglish(dateStr);
  if (jalaliToGregorianMapping[engDate]) {
    return jalaliToGregorianMapping[engDate];
  }
  return engDate;
};

const translations = {
  facultiesTitle: {
    fa: "دانشکده‌های دانشگاه مدیپل",
    en: "Medipol University Faculties",
    tr: "Medipol Üniversitesi Fakülteleri"
  },
  tuitionTitle: {
    fa: "جدول شهریه دانشگاه مدیپل در سال 2025",
    en: "Medipol University Tuition Fees 2025",
    tr: "2025 Yılı Medipol Üniversitesi Öğrenim Ücreti Tablosu"
  },
  tuitionTable: {
    headerFaculty: {
      fa: "رشته",
      en: "Faculty",
      tr: "Bölüm"
    },
    headerFee: {
      fa: "شهریه (سالانه)",
      en: "Tuition Fee (Yearly)",
      tr: "Yıllık Öğrenim Ücreti"
    },
    headerDescription: {
      fa: "توضیحات",
      en: "Description",
      tr: "Açıklama"
    }
  },
  newsTitle: {
    fa: "اخبار و رویدادهای دانشگاه مدیپل",
    en: "Medipol University News and Events",
    tr: "Medipol Üniversitesi Haberler ve Etkinlikler"
  },
  researchTitle: {
    fa: "مقالات تحقیقاتی و پژوهش‌های دانشجویی",
    en: "Research Articles and Student Research",
    tr: "Araştırma Makaleleri ve Öğrenci Araştırmaları"
  },
  collaborationTitle: {
    fa: "همکاری‌های بین‌المللی دانشگاه مدیپل",
    en: "Medipol University International Collaborations",
    tr: "Medipol Üniversitesi Uluslararası İşbirlikleri"
  },
  // داده‌های همکاری بین‌المللی (استفاده از تصاویر دانشگاه باهچه‌شهیر)
  collaborationData: [
    {
      name: {
        fa: "دانشگاه بوگازیچی",
        en: "Bosphorus University",
        tr: "Bosphorus Üniversitesi"
      },
      link: "https://www.bosphorus.edu.tr/",
      ranking: "1",
      icon: <FaCheckCircle />,
      background: harvardImage
    },
    {
      name: {
        fa: "دانشگاه یدیدپه",
        en: "Yeditepe University",
        tr: "Yeditepe Üniversitesi"
      },
      link: "https://www.yeditepe.edu.tr/",
      ranking: "2",
      icon: <FaCheckCircle />,
      background: oxfordImage
    },
    {
      name: {
        fa: "دانشگاه مدیپل",
        en: "Medipol University",
        tr: "Medipol Üniversitesi"
      },
      link: "https://www.medipol.edu.tr/",
      ranking: "3",
      icon: <FaCheckCircle />,
      background: tokyoImage
    },
    {
      name: {
        fa: "دانشگاه فنی استانبول",
        en: "Istanbul Technical University",
        tr: "İstanbul Teknik Üniversitesi"
      },
      link: "https://www.itu.edu.tr/",
      ranking: "4",
      icon: <FaCheckCircle />,
      background: technicalImage
    }
  ],
  entrepreneurshipTitle: {
    fa: "کارآفرینی و فرصت‌های شغلی",
    en: "Entrepreneurship and Job Opportunities",
    tr: "Girişimcilik ve İş Fırsatları"
  },
  announcementTitle: {
    fa: "🎓 فرصت طلایی: بورسیه 50% دانشگاه مدیپل",
    en: "🎓 Golden Chance: 50% Scholarship at Medipol University",
    tr: "🎓 Altın Fırsat: Medipol Üniversitesi’nde %50 Burs İmkanı"
  },
  announcementText: {
    fa: "با ثبت‌نام در سامانه UniManagaer، شانس دریافت بورسیه ۵۰ درصدی و کاهش چشمگیر هزینه‌های تحصیل را از دست ندهید! این فرصت محدود، راه را برای موفقیت هموار می‌کند.",
    en: "Register on the UniManagaer platform to become eligible for an exclusive 50% scholarship that dramatically reduces your tuition fees. Don’t miss this limited-time opportunity for success!",
    tr: "UniManagaer platformuna kaydolarak, öğrenim ücretlerinizde çarpıcı indirim sağlayacak özel %50 burs fırsatından yararlanın. Bu sınırlı süreli fırsatı kaçırmayın!"
  },
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  faqData: [
    {
      question: {
        fa: "شرایط پذیرش دانشگاه مدیپل چیست؟",
        en: "What are the admission requirements for Medipol University?",
        tr: "Medipol Üniversitesi'ne kabul şartları nelerdir?"
      },
      answer: {
        fa: "برای پذیرش در دانشگاه مدیپل، ارائه مدارک تحصیلی معتبر، نمرات مطلوب و در برخی موارد مصاحبه الزامی است.",
        en: "Admission at Medipol University requires valid academic certificates, competitive grades, and in some cases, an interview.",
        tr: "Medipol Üniversitesi'ne kabul için geçerli akademik belgeler, yeterli notlar ve bazı durumlarda mülakat gerekmektedir."
      }
    },
    {
      question: {
        fa: "شهریه تحصیلی دانشگاه مدیپل در سال 2025 چقدر است؟",
        en: "What is the tuition fee for Medipol University in 2025?",
        tr: "2025 yılında Medipol Üniversitesi'nin öğrenim ücreti ne kadardır?"
      },
      answer: {
        fa: "شهریه دانشگاه مدیپل برای دانشجویان بین‌المللی بسته به رشته از ۴۰۰۰ تا ۱۰۰۰۰ دلار متغیر است.",
        en: "Tuition fees at Medipol University for international students in 2025 range between $4,000 and $10,000, depending on the program.",
        tr: "Medipol Üniversitesi'nde 2025 yılında uluslararası öğrenciler için öğrenim ücreti programa bağlı olarak 4.000 ila 10.000 dolar arasında değişmektedir."
      }
    },
    {
      question: {
        fa: "چه رشته‌هایی در دانشگاه مدیپل ارائه می‌شود؟",
        en: "What programs or faculties are offered at Medipol University?",
        tr: "Medipol Üniversitesi'nde hangi bölümler sunulmaktadır?"
      },
      answer: {
        fa: "دانشگاه مدیپل در رشته‌های مختلفی از جمله پزشکی، مهندسی، مدیریت، حقوق، هنر و علوم اجتماعی فعالیت می‌کند.",
        en: "Medipol University offers a wide range of programs including Medicine, Engineering, Management, Law, Arts, and Social Sciences.",
        tr: "Medipol Üniversitesi, Tıp, Mühendislik, Yönetim, Hukuk, Sanat ve Sosyal Bilimler gibi çeşitli bölümler sunulmaktadır."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه مدیپل امکانات بین‌المللی ارائه می‌دهد؟",
        en: "Does Medipol University offer international opportunities?",
        tr: "Medipol Üniversitesi uluslararası olanaklar sunuyor mu?"
      },
      answer: {
        fa: "بله، دانشگاه مدیپل از طریق قراردادهای تبادل دانشجو و استاد با دانشگاه‌های معتبر بین‌المللی، امکانات فراوانی را فراهم کرده است.",
        en: "Yes, Medipol University provides extensive international opportunities through student and faculty exchange agreements with renowned universities.",
        tr: "Evet, Medipol Üniversitesi, ünlü üniversitelerle öğrenci ve öğretim üyesi değişim anlaşmaları yoluyla geniş uluslararası olanaklar sunmaktadır."
      }
    },
    {
      question: {
        fa: "فرآیند پذیرش در دانشگاه مدیپل چگونه است؟",
        en: "What is the application process at Medipol University?",
        tr: "Medipol Üniversitesi'ne kabul süreci nasıl işliyor?"
      },
      answer: {
        fa: "فرآیند پذیرش شامل ارائه مدارک تحصیلی، بررسی سوابق و در برخی موارد مصاحبه است.",
        en: "The application process involves submitting academic documents, reviewing your background, and in some cases, attending an interview.",
        tr: "Kabul süreci, akademik belgelerin sunulması, geçmişinizin incelenmesi ve bazı durumlarda mülakata katılmayı içerir."
      }
    },
    {
      question: {
        fa: "امکانات رفاهی و خوابگاه‌های دانشگاه مدیپل چگونه است؟",
        en: "What are the accommodation and campus facilities like at Medipol University?",
        tr: "Medipol Üniversitesi'nin konaklama ve kampüs olanakları nasıldır?"
      },
      answer: {
        fa: "دانشگاه مدیپل امکانات رفاهی مدرن، خوابگاه‌های مجهز و خدمات جامعی برای دانشجویان فراهم کرده است.",
        en: "Medipol University provides modern amenities, well-equipped dormitories, and comprehensive student services.",
        tr: "Medipol Üniversitesi, modern olanaklar, donanımlı yurtlar ve öğrencilere yönelik kapsamlı hizmetler sunmaktadır."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌های دانشگاه مدیپل",
    en: "Medipol University Financial Services and Scholarships",
    tr: "Medipol Üniversitesi Mali Hizmetler ve Burslar"
  },
  newsData: [
    {
      title: {
        fa: "افتتاح مرکز نوآوری مدیپل",
        en: "Medipol Innovation Center Inaugurated",
        tr: "Medipol İnovasyon Merkezi Açıldı"
      },
      date: "۱۴۰۲/۰۶/۱۰",
      description: {
        fa: "اساتید و دانشجویان در این رویداد، نوآوری و فناوری‌های نوین را جشن گرفتند.",
        en: "Faculty and students celebrated the inauguration of the innovative Medipol Innovation Center.",
        tr: "Öğretim üyeleri ve öğrenciler, Medipol İnovasyon Merkezi açılışını kutladı."
      }
    },
    {
      title: {
        fa: "کارگاه استارتاپ و نوآوری",
        en: "Startup and Innovation Workshop",
        tr: "Startup ve İnovasyon Atölyesi"
      },
      date: "۱۴۰۲/05/25",
      description: {
        fa: "دانشجویان در کارگاهی تخصصی، پروژه‌های نوآورانه خود را ارائه کردند.",
        en: "Students presented their innovative projects during a specialized startup workshop.",
        tr: "Öğrenciler, özel bir startup atölyesinde yenilikçi projelerini sundular."
      }
    },
    {
      title: {
        fa: "سخنرانی بین‌المللی در زمینه پزشکی",
        en: "International Lecture on Modern Medicine",
        tr: "Modern Tıp Üzerine Uluslararası Konferans"
      },
      date: "۱۴۰۲/04/15",
      description: {
        fa: "اساتید برجسته از سراسر جهان درباره پیشرفت‌های نوین پزشکی سخنرانی کردند.",
        en: "Renowned international experts delivered lectures on the latest advances in medicine.",
        tr: "Dünyanın önde gelen isimleri, modern tıptaki son gelişmeler hakkında konferans verdiler."
      }
    }
  ],
  researchData: [
    {
      title: {
        fa: "پژوهش در حوزه فناوری سلامت",
        en: "Research in Health Technology",
        tr: "Sağlık Teknolojileri Alanında Araştırma"
      },
      date: "۱۴۰۲/03/20",
      description: {
        fa: "نتایج یک مطالعه پیشرفته در زمینه کاربرد فناوری در بهبود خدمات بهداشتی.",
        en: "Results from an advanced study on applying technology to enhance healthcare services.",
        tr: "Teknolojinin sağlık hizmetlerini iyileştirmedeki rolünü inceleyen ileri düzey bir çalışma."
      }
    },
    {
      title: {
        fa: "تحقیقات نوین در علوم پزشکی",
        en: "Innovative Research in Medical Sciences",
        tr: "Tıp Bilimlerinde Yenilikçi Araştırmalar"
      },
      date: "۱۴۰۲/02/10",
      description: {
        fa: "بررسی تکنیک‌های نوین در تشخیص و درمان بیماری‌های پیچیده.",
        en: "Exploration of novel techniques for diagnosing and treating complex diseases.",
        tr: "Karmaşık hastalıkların teşhis ve tedavisinde yeni tekniklerin araştırılması."
      }
    }
  ],
  entrepreneurshipData: [
    {
      title: {
        fa: "دوره کارآفرینی و استارتاپ",
        en: "Entrepreneurship and Startup Course",
        tr: "Girişimcilik ve Startup Eğitimi"
      },
      description: {
        fa: "آموزش‌های تخصصی برای راه‌اندازی کسب و کار نوآورانه در محیط دانشگاهی.",
        en: "Specialized training for launching innovative startups within an academic environment.",
        tr: "Üniversite ortamında yenilikçi girişimler kurmak için özel eğitim."
      }
    },
    {
      title: {
        fa: "مشاوره شغلی و کارآفرینی",
        en: "Career and Entrepreneurship Counseling",
        tr: "Kariyer ve Girişimcilik Danışmanlığı"
      },
      description: {
        fa: "ارائه راهنمایی‌های حرفه‌ای جهت ورود به بازار کار و ایجاد کسب و کار.",
        en: "Professional guidance for entering the job market and creating your own business.",
        tr: "İş piyasasına giriş ve kendi işinizi kurma konusunda profesyonel rehberlik."
      }
    },
    {
      title: {
        fa: "نمایشگاه استخدامی و کارآفرینی",
        en: "Job and Entrepreneurship Fair",
        tr: "İstihdam ve Girişimcilik Fuarı"
      },
      description: {
        fa: "فرصت‌های شغلی و کسب تجربه در رویدادهای تخصصی دانشگاه.",
        en: "Job opportunities and practical experience at specialized university fairs.",
        tr: "Üniversiteye özel etkinliklerde iş ve girişimcilik fırsatları."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌های دانشگاه مدیپل",
    en: "Medipol University Financial Services and Scholarships",
    tr: "Medipol Üniversitesi Mali Hizmetler ve Burslar"
  },
  financialAidData: [
    {
      title: {
        fa: "بورسیه تحصیلی کامل",
        en: "Full Scholarship",
        tr: "Tam Burs"
      },
      description: {
        fa: "پوشش کامل شهریه و هزینه‌های زندگی برای دانشجویان برتر.",
        en: "Full coverage of tuition fees and living expenses for outstanding students.",
        tr: "Başarılı öğrenciler için öğrenim ücreti ve yaşam masraflarının tam karşılanması."
      }
    },
    {
      title: {
        fa: "بورسیه جزئی",
        en: "Partial Scholarship",
        tr: "Kısmi Burs"
      },
      description: {
        fa: "تخفیف قابل توجه در شهریه به همراه کمک هزینه‌های جزئی.",
        en: "Substantial tuition discount along with partial financial aid.",
        tr: "Önemli öğrenim ücreti indirimi ve kısmi maddi destek."
      }
    },
    {
      title: {
        fa: "وام دانشجویی",
        en: "Student Loan",
        tr: "Öğrenci Kredisi"
      },
      description: {
        fa: "امکان دریافت وام با شرایط مساعد جهت تأمین هزینه‌های تحصیلی.",
        en: "Student loan options available under favorable conditions for tuition fees.",
        tr: "Öğrenim ücretlerini karşılamak için uygun şartlarda kredi imkanı."
      }
    }
  ]
};

const facultiesData = [
  {
    name: { 
      fa: "دانشکده پزشکی", 
      en: "Faculty of Medicine", 
      tr: "Tıp Fakültesi" 
    },
    description: {
      fa: "آموزش و پژوهش‌های پیشرفته در حوزه پزشکی و علوم بالینی با بهره‌گیری از فناوری‌های نوین.",
      en: "Advanced education and research in medicine and clinical sciences using cutting-edge technologies.",
      tr: "En yeni teknolojilerin kullanıldığı, tıp ve klinik bilimlerde ileri düzey eğitim ve araştırma."
    }
  },
  {
    name: { 
      fa: "دانشکده دندانپزشکی", 
      en: "Faculty of Dentistry", 
      tr: "Diş Hekimliği Fakültesi" 
    },
    description: {
      fa: "برنامه‌های تخصصی دندانپزشکی با تأکید بر روش‌های مدرن تشخیص و درمان.",
      en: "Specialized dental programs emphasizing modern diagnostic and treatment methods.",
      tr: "Modern tanı ve tedavi yöntemlerine vurgu yapan diş hekimliği programları."
    }
  },
  {
    name: { 
      fa: "دانشکده داروسازی", 
      en: "Faculty of Pharmacy", 
      tr: "Eczacılık Fakültesi" 
    },
    description: {
      fa: "آموزش داروسازی و پژوهش در زمینه توسعه داروهای نوین و فناوری‌های دارویی.",
      en: "Pharmacy education and research in the development of new drugs and pharmaceutical technologies.",
      tr: "Yeni ilaçların geliştirilmesi ve farmasötik teknolojiler üzerine eğitim ve araştırma."
    }
  },
  {
    name: { 
      fa: "دانشکده پرستاری", 
      en: "Faculty of Nursing", 
      tr: "Hemşirelik Fakültesi" 
    },
    description: {
      fa: "برنامه‌های پرستاری مدرن با رویکرد کاربردی و بالینی، مجهز به امکانات نوین آموزشی.",
      en: "Modern nursing programs with a practical and clinical approach, equipped with state-of-the-art facilities.",
      tr: "Modern hemşirelik programları, pratik ve klinik yaklaşımla, son teknoloji olanaklarla donatılmıştır."
    }
  },
  {
    name: { 
      fa: "دانشکده علوم بهداشتی", 
      en: "Faculty of Health Sciences", 
      tr: "Sağlık Bilimleri Fakültesi" 
    },
    description: {
      fa: "آموزش در زمینه علوم بهداشتی، تغذیه، فیزیوتراپی و سایر رشته‌های مرتبط با سلامت عمومی.",
      en: "Education in health sciences, nutrition, physiotherapy, and other fields related to public health.",
      tr: "Halk sağlığı, beslenme, fizyoterapi ve diğer sağlık bilimleri alanlarında eğitim."
    }
  },
  {
    name: { 
      fa: "دانشکده مهندسی", 
      en: "Faculty of Engineering", 
      tr: "Mühendislik Fakültesi" 
    },
    description: {
      fa: "برنامه‌های مهندسی در حوزه‌های مختلف از جمله مهندسی برق، مکانیک، کامپیوتر و عمران با رویکرد نوآورانه.",
      en: "Engineering programs in various fields such as electrical, mechanical, computer, and civil engineering with an innovative approach.",
      tr: "Elektrik, mekanik, bilgisayar ve inşaat mühendisliği gibi çeşitli alanlarda yenilikçi yaklaşımlarla mühendislik programları."
    }
  },
  {
    name: { 
      fa: "دانشکده حقوق", 
      en: "Faculty of Law", 
      tr: "Hukuk Fakültesi" 
    },
    description: {
      fa: "آموزش حقوق با استانداردهای بین‌المللی و بهره‌گیری از اساتید مجرب و متخصص.",
      en: "Legal education with international standards and expert faculty.",
      tr: "Uluslararası standartlarda hukuk eğitimi ve deneyimli akademisyenler tarafından sunulmaktadır."
    }
  },
  {
    name: { 
      fa: "دانشکده مدیریت", 
      en: "Faculty of Management", 
      tr: "Yönetim Fakültesi" 
    },
    description: {
      fa: "برنامه‌های مدیریتی با رویکرد نوآورانه و عملی، آماده‌سازی دانشجویان برای بازارهای جهانی.",
      en: "Management programs with an innovative and practical approach, preparing students for global markets.",
      tr: "Yenilikçi ve pratik yönetim programları, öğrencileri küresel pazarlara hazırlamaktadır."
    }
  },
  {
    name: { 
      fa: "دانشکده معماری", 
      en: "Faculty of Architecture", 
      tr: "Mimarlık Fakültesi" 
    },
    description: {
      fa: "برنامه‌های معماری با تمرکز بر طراحی خلاقانه، نوآوری و توسعه پایدار.",
      en: "Architecture programs focusing on creative design, innovation, and sustainable development.",
      tr: "Yaratıcı tasarım, yenilik ve sürdürülebilir kalkınmaya odaklanan mimarlık programları."
    }
  },
  {
    name: { 
      fa: "دانشکده ارتباطات", 
      en: "Faculty of Communication", 
      tr: "İletişim Fakültesi" 
    },
    description: {
      fa: "آموزش در زمینه ارتباطات، رسانه، تبلیغات و روزنامه‌نگاری با استفاده از فناوری‌های نوین.",
      en: "Education in communications, media, advertising, and journalism using modern technologies.",
      tr: "Modern teknolojiler kullanılarak iletişim, medya, reklamcılık ve gazetecilik eğitimi."
    }
  },
  {
    name: { 
      fa: "دانشکده علوم کامپیوتر و اطلاعات", 
      en: "Faculty of Computer and Information Sciences", 
      tr: "Bilgisayar ve Bilişim Fakültesi" 
    },
    description: {
      fa: "برنامه‌های علوم کامپیوتر و فناوری اطلاعات با تمرکز بر توسعه نرم‌افزار و نوآوری‌های دیجیتال.",
      en: "Computer and information sciences programs focusing on software development and digital innovations.",
      tr: "Yazılım geliştirme ve dijital yeniliklere odaklanan bilgisayar ve bilişim bilimleri programları."
    }
  },
  {
    name: { 
      fa: "دانشکده علوم اجتماعی", 
      en: "Faculty of Social Sciences", 
      tr: "Sosyal Bilimler Fakültesi" 
    },
    description: {
      fa: "برنامه‌های علوم اجتماعی شامل روانشناسی، جامعه‌شناسی و سایر رشته‌های مرتبط با مطالعه رفتار و فرهنگ.",
      en: "Social sciences programs including psychology, sociology, and other related fields focusing on human behavior and culture.",
      tr: "Psikoloji, sosyoloji ve insan davranışı ile kültür üzerine odaklanan diğer sosyal bilim programları."
    }
  },
  {
    name: { 
      fa: "دانشکده هنر و طراحی", 
      en: "Faculty of Art and Design", 
      tr: "Sanat ve Tasarım Fakültesi" 
    },
    description: {
      fa: "برنامه‌های هنری و طراحی با تأکید بر خلاقیت، نوآوری و فناوری‌های دیجیتال.",
      en: "Art and design programs emphasizing creativity, innovation, and digital technologies.",
      tr: "Yaratıcılık, yenilik ve dijital teknolojilere vurgu yapan sanat ve tasarım programları."
    }
  }
];

const FacultyCard = ({ name, description }) => (
  <div className="uni-faculty-card">
    <h3 className="uni-faculty-card-title">{name}</h3>
    <p className="uni-faculty-card-text">{description}</p>
  </div>
);

const TuitionTable = ({ lang }) => {
  const tuitionData = [
    {
      faculty: { 
        fa: "پزشکی ", 
        en: "Medicine ", 
        tr: "Tıp " 
      },
      fee: { 
        fa: "$32,000", 
        en: "$32,000", 
        tr: "$32,000" 
      },
      description: {
        fa: "آموزش پزشکی با تأکید بر پژوهش‌های بالینی و فناوری‌های نوین درمانی.",
        en: "Medical education with emphasis on clinical research and modern treatment technologies.",
        tr: "Klinik araştırmalar ve modern tedavi teknolojilerine vurgu yapan tıp eğitimi."
      }
    },
    {
      faculty: { 
        fa: "دندانپزشکی ", 
        en: "Dentistry ", 
        tr: "Diş Hekimliği " 
      },
      fee: { 
        fa: "$27,000", 
        en: "$27,000", 
        tr: "$27,000" 
      },
      description: {
        fa: "آموزش دندانپزشکی با استفاده از تجهیزات مدرن و فناوری‌های پیشرفته.",
        en: "Dentistry courses with modern equipment and advanced technologies.",
        tr: "Modern ekipman ve ileri teknolojilerle diş hekimliği eğitimi."
      }
    },
    {
      faculty: { 
        fa: "داروسازی ", 
        en: "Pharmacy ", 
        tr: "Eczacılık " 
      },
      fee: { 
        fa: "$18,000", 
        en: "$18,000", 
        tr: "$18,000" 
      },
      description: {
        fa: "آموزش داروسازی با تمرکز بر پژوهش و توسعه داروهای نوین.",
        en: "Pharmacy education focusing on research and development of new drugs.",
        tr: "Yeni ilaçların araştırılması ve geliştirilmesine odaklanan eczacılık eğitimi."
      }
    },
    {
      faculty: { 
        fa: "پرستاری ", 
        en: "Nursing ", 
        tr: "Hemşirelik " 
      },
      fee: { 
        fa: "$15,000", 
        en: "$15,000", 
        tr: "$15,000" 
      },
      description: {
        fa: "برنامه‌های پرستاری با رویکرد کاربردی و خدمات بالینی پیشرفته.",
        en: "Nursing programs with a practical approach and advanced clinical services.",
        tr: "Uygulamalı yaklaşımla verilen hemşirelik programları ve ileri klinik hizmetler."
      }
    },
    {
      faculty: { 
        fa: "مهندسی برق ", 
        en: "Electrical Engineering ", 
        tr: "Elektrik Mühendisliği " 
      },
      fee: { 
        fa: "$12,000", 
        en: "$12,000", 
        tr: "$12,000" 
      },
      description: {
        fa: "آموزش مهندسی برق با تمرکز بر تکنولوژی‌های نوین و کاربردی.",
        en: "Electrical engineering focusing on modern and practical technologies.",
        tr: "Modern ve pratik teknolojilere odaklanan elektrik mühendisliği eğitimi."
      }
    },
    {
      faculty: { 
        fa: "مهندسی مکانیک ", 
        en: "Mechanical Engineering ", 
        tr: "Makine Mühendisliği " 
      },
      fee: { 
        fa: "$11,000", 
        en: "$11,000", 
        tr: "$11,000" 
      },
      description: {
        fa: "برنامه‌های مهندسی مکانیک با تأکید بر طراحی و تحلیل سیستم‌های پیچیده.",
        en: "Mechanical engineering programs emphasizing design and analysis of complex systems.",
        tr: "Karmaşık sistemlerin tasarımı ve analizi üzerine makine mühendisliği programları."
      }
    },
    {
      faculty: { 
        fa: "مهندسی عمران ", 
        en: "Civil Engineering ", 
        tr: "İnşaat Mühendisliği " 
      },
      fee: { 
        fa: "$9,000", 
        en: "$9,000", 
        tr: "$9,000" 
      },
      description: {
        fa: "آموزش مهندسی عمران با تمرکز بر طراحی و ساخت سازه‌های پایدار.",
        en: "Civil engineering with focus on sustainable structure design and construction.",
        tr: "Sürdürülebilir yapı tasarımı ve inşası üzerine inşaat mühendisliği eğitimi."
      }
    },
    {
      faculty: { 
        fa: "مهندسی کامپیوتر ", 
        en: "Computer Engineering ", 
        tr: "Bilgisayar Mühendisliği " 
      },
      fee: { 
        fa: "$10,000", 
        en: "$10,000", 
        tr: "$10,000" 
      },
      description: {
        fa: "آموزش مهندسی کامپیوتر با تأکید بر برنامه‌نویسی و هوش مصنوعی.",
        en: "Computer engineering with an emphasis on programming and artificial intelligence.",
        tr: "Programlama ve yapay zekaya vurgu yapan bilgisayar mühendisliği eğitimi."
      }
    },
    {
      faculty: { 
        fa: "حقوق ", 
        en: "Law ", 
        tr: "Hukuk " 
      },
      fee: { 
        fa: "$8,500", 
        en: "$8,500", 
        tr: "$8,500" 
      },
      description: {
        fa: "آموزش حقوق با بهره‌گیری از استانداردهای بین‌المللی و متخصصین حوزه.",
        en: "Legal education with international standards and industry experts.",
        tr: "Uluslararası standartlara sahip hukuk eğitimi ve alanında uzman akademisyenler."
      }
    },
    {
      faculty: { 
        fa: "مدیریت ", 
        en: "Management ", 
        tr: "Yönetim " 
      },
      fee: { 
        fa: "$7,500", 
        en: "$7,500", 
        tr: "$7,500" 
      },
      description: {
        fa: "برنامه‌های مدیریتی با رویکرد نوآورانه و کاربردی، آماده‌سازی مدیران آینده.",
        en: "Management programs with an innovative and practical approach, preparing future leaders.",
        tr: "Yenilikçi ve pratik yönetim programları, geleceğin liderlerini yetiştirmeye odaklanır."
      }
    },
    {
      faculty: { 
        fa: "معماری ", 
        en: "Architecture ", 
        tr: "Mimarlık " 
      },
      fee: { 
        fa: "$8,500", 
        en: "$8,500", 
        tr: "$8,500" 
      },
      description: {
        fa: "آموزش معماری با تأکید بر طراحی خلاقانه و توسعه پایدار.",
        en: "Architecture education focusing on creative design and sustainable development.",
        tr: "Yaratıcı tasarım ve sürdürülebilir kalkınmaya odaklanan mimarlık eğitimi."
      }
    },
    {
      faculty: { 
        fa: "ارتباطات ", 
        en: "Communication ", 
        tr: "İletişim " 
      },
      fee: { 
        fa: "$6,500", 
        en: "$6,500", 
        tr: "$6,500" 
      },
      description: {
        fa: "برنامه‌های ارتباطات با رویکرد نوین در رسانه‌ها و تبلیغات.",
        en: "Communication programs focusing on modern media and advertising.",
        tr: "Modern medya ve reklamcılık üzerine iletişim programları."
      }
    },
    {
      faculty: { 
        fa: "علوم کامپیوتر و اطلاعات ", 
        en: "Computer and Information Sciences ", 
        tr: "Bilgisayar ve Bilişim " 
      },
      fee: { 
        fa: "$10,000", 
        en: "$10,000", 
        tr: "$10,000" 
      },
      description: {
        fa: "برنامه‌های علوم کامپیوتر و فناوری اطلاعات با تأکید بر توسعه نرم‌افزار و نوآوری‌های دیجیتال.",
        en: "Programs in computer and information sciences focusing on software development and digital innovations.",
        tr: "Yazılım geliştirme ve dijital yeniliklere odaklanan bilgisayar ve bilişim eğitim programları."
      }
    },
    {
      faculty: { 
        fa: "علوم اجتماعی ", 
        en: "Social Sciences ", 
        tr: "Sosyal Bilimler " 
      },
      fee: { 
        fa: "$7,000", 
        en: "$7,000", 
        tr: "$7,000" 
      },
      description: {
        fa: "برنامه‌های علوم اجتماعی با تمرکز بر مطالعه رفتار، فرهنگ و جامعه.",
        en: "Social sciences programs focusing on human behavior, culture, and society.",
        tr: "İnsan davranışı, kültür ve toplum üzerine odaklanan sosyal bilimler programları."
      }
    },
    {
      faculty: { 
        fa: "هنر و طراحی ", 
        en: "Art and Design ", 
        tr: "Sanat ve Tasarım " 
      },
      fee: { 
        fa: "$8,000", 
        en: "$8,000", 
        tr: "$8,000" 
      },
      description: {
        fa: "برنامه‌های هنر و طراحی با تأکید بر خلاقیت، نوآوری و فناوری‌های دیجیتال.",
        en: "Art and design programs emphasizing creativity, innovation, and digital technologies.",
        tr: "Yaratıcılık, yenilik ve dijital teknolojilere vurgu yapan sanat ve tasarım programları."
      }
    }
  ];
  
  // ترجمه‌های مورد استفاده در جدول شهریه
  const translations = {
    tuitionTable: {
      headerFaculty: {
        fa: "رشته",
        en: "Faculty",
        tr: "Bölüm"
      },
      headerFee: {
        fa: "شهریه (سالانه)",
        en: "Tuition Fee (Yearly)",
        tr: "Yıllık Öğrenim Ücreti"
      },
      headerDescription: {
        fa: "توضیحات",
        en: "Description",
        tr: "Açıklama"
      }
    }
  };
  const headers =
    lang === "fa"
      ? [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerFee[lang],
          translations.tuitionTable.headerDescription[lang]
        ]
      : [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerDescription[lang],
          translations.tuitionTable.headerFee[lang]
        ];

  return (
    <table className="uni-tuition-table">
      <thead>
        <tr>
          <th>{headers[0]}</th>
          <th>{headers[1]}</th>
          <th>{headers[2]}</th>
        </tr>
      </thead>
      <tbody>
        {tuitionData.map((item, index) =>
          lang === "fa" ? (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.fee[lang]}</td>
              <td>{item.description[lang]}</td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.description[lang]}</td>
              <td>{item.fee[lang]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const FAQSection = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);
  return (
    <section className="uni-faq-section">
      <h2 className="uni-faq-title">{translations.faqTitle[lang]}</h2>
      <div className="uni-faq-grid">
        {translations.faqData.map((faq, index) => (
          <div
            key={index}
            className="uni-faq-card"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="uni-faq-question">{faq.question[lang]}</h3>
            {activeIndex === index && (
              <p className="uni-faq-answer">{faq.answer[lang]}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

const MedipolUniversityMiddle = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className={`uni-container ${lang !== "fa" ? "ltr" : ""}`}>
      {/* دانشکده‌ها */}
      <section className="uni-faculties-section">
        <h2 className="uni-faculties-title">{translations.facultiesTitle[lang]}</h2>
        <div className="uni-faculties-grid">
          {facultiesData.map((item, index) => (
            <FacultyCard 
              key={index} 
              name={item.name[lang]} 
              description={item.description[lang]} 
            />
          ))}
        </div>
      </section>

      {/* جدول شهریه */}
      <section className="uni-tuition-section">
        <h2 className="uni-tuition-title">{translations.tuitionTitle[lang]}</h2>
        <TuitionTable lang={lang} />
      </section>

      {/* اخبار و رویدادها */}
      <section className="uni-news-section">
        <h2 className="uni-news-title">{translations.newsTitle[lang]}</h2>
        <div className="uni-news-grid">
          {translations.newsData.map((news, index) => (
            <div key={index} className="uni-news-card">
              <h3 className="uni-news-card-title">{news.title[lang]}</h3>
              <span className="uni-news-card-date">
                {formatDate(news.date, lang)}
              </span>
              <p className="uni-news-card-text">{news.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* مقالات تحقیقاتی */}
      <section className="uni-research-section">
        <h2 className="uni-research-title">{translations.researchTitle[lang]}</h2>
        <div className="uni-research-grid">
          {translations.researchData.map((article, index) => (
            <div key={index} className="uni-research-card">
              <h3 className="uni-research-card-title">{article.title[lang]}</h3>
              <span className="uni-research-card-date">{formatDate(article.date, lang)}</span>
              <p className="uni-research-card-text">{article.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* همکاری‌های بین‌المللی */}
      <section className="uni-collaboration-section">
        <h2 className="uni-collaboration-title">{translations.collaborationTitle[lang]}</h2>
        <div className="uni-collaboration-grid">
          {translations.collaborationData.map((item, index) => (
            <div 
              key={index} 
              className="uni-collaboration-item"
              style={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.name[lang]}
              </a>
              <div className="uni-collaboration-ranking">
                {lang === "fa"
                  ? `رتبه جهانی: ${item.ranking}`
                  : `Global Ranking: ${item.ranking}`}
                {item.icon}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* کارآفرینی و فرصت‌های شغلی */}
      <section className="uni-entrepreneurship-section">
        <h2 className="uni-entrepreneurship-title">{translations.entrepreneurshipTitle[lang]}</h2>
        <div className="uni-entrepreneurship-grid">
          {translations.entrepreneurshipData.map((item, index) => (
            <div key={index} className="uni-entrepreneurship-card">
              <h3 className="uni-entrepreneurship-card-title">{item.title[lang]}</h3>
              <p className="uni-entrepreneurship-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* اعلان ویژه بورسیه */}
      <section className="uni-announcement-section">
        <div
          className="announcement-bg-overlay"
          style={{ backgroundImage: `url(${uniManagerImage})` }}
        ></div>
        <div className="announcement-content">
          <h2 className="uni-announcement-title">
            {translations.announcementTitle[lang]}
          </h2>
          <p className="uni-announcement-text">
            {translations.announcementText[lang]}
          </p>
        </div>
      </section>

      {/* سوالات متداول */}
      <FAQSection lang={lang} />

      {/* خدمات مالی و بورسیه */}
      <section className="uni-financial-section">
        <h2 className="uni-financial-title">{translations.financialTitle[lang]}</h2>
        <div className="uni-financial-grid">
          {translations.financialAidData.map((item, index) => (
            <div key={index} className="uni-financial-card">
              <h3 className="uni-financial-card-title">{item.title[lang]}</h3>
              <p className="uni-financial-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default MedipolUniversityMiddle;
