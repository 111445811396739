import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiHomeLine, RiPhoneLine, RiArrowDownSLine } from "react-icons/ri";
import { useLanguage } from "../contexts/LanguageContext";
import "./styles/ExteraToolbar.css";

const ExteraToolbar = () => {
  const { language, setLanguage } = useLanguage();
  const location = useLocation();
  // اگر زبان 'fa' باشد، نوار ابزار حالت راست‌به‌چپ را داشته باشد
  const isRTL = language === "fa";

  const [showDropdown, setShowDropdown] = useState(false);

  // نگاشت زبان بر اساس مقادیر ذخیره شده در context
  const languageAbbr = {
    en: "EN",
    fa: "FA",
    tr: "TR",
  };

  // مقدار پیش‌فرض اگر کلید موجود نباشد
  const selectedLanguage = languageAbbr[language] || "EN";

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setShowDropdown(false);
  };

  const languageButtons = (
    <>
      <button onClick={() => handleLanguageChange("English")} className="lang-btn">
        <span className="lang-abbr">EN</span>
      </button>
      <button onClick={() => handleLanguageChange("Persian")} className="lang-btn">
        <span className="lang-abbr">FA</span>
      </button>
      <button onClick={() => handleLanguageChange("Turkish")} className="lang-btn">
        <span className="lang-abbr">TR</span>
      </button>
    </>
  );

  return (
    <div className="extera-toolbar-container">
      {isRTL ? (
        <>
          <div className="extera-toolbar-left">
            <div className="lang-group desktop-only">
              {languageButtons}
            </div>
            <div className="dropdown-container mobile-only">
              <button onClick={() => setShowDropdown(!showDropdown)} className="dropdown-toggle">
                {selectedLanguage}
                <RiArrowDownSLine className="dropdown-icon" />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  {languageButtons}
                </div>
              )}
            </div>
          </div>
          <div className="extera-toolbar-center">
            <span className="uni-manager-title">Uni Manager</span>
          </div>
          <div className="extera-toolbar-right">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              <RiHomeLine className="icon" />
            </Link>
            <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>
              <RiPhoneLine className="icon" />
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="extera-toolbar-left">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              <RiHomeLine className="icon" />
            </Link>
            <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>
              <RiPhoneLine className="icon" />
            </Link>
          </div>
          <div className="extera-toolbar-center">
            <span className="uni-manager-title">Uni Manager</span>
          </div>
          <div className="extera-toolbar-right">
            <div className="lang-group desktop-only">
              {languageButtons}
            </div>
            <div className="dropdown-container mobile-only">
              <button onClick={() => setShowDropdown(!showDropdown)} className="dropdown-toggle">
                {selectedLanguage}
                <RiArrowDownSLine className="dropdown-icon" />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                  {languageButtons}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExteraToolbar;
