import React, { useState } from "react";
import "../../components/styles/Uni.css";
import { FaCheckCircle } from "react-icons/fa"; // آیکون معتبر بودن
import "../../components/styles/Uni2.css";
import harvardImage from "../../assets/image/uni-card/harvard.webp";
import oxfordImage from "../../assets/image/uni-card/oxford.webp";
import tokyoImage from "../../assets/image/uni-card/tokyo.webp";
import technicalImage from "../../assets/image/uni-card/Technical.webp";
import uniManagerImage from "../../assets/image/uni-card/UniManager.png";

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const convertPersianDigitsToEnglish = (str) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let result = str;
  persianDigits.forEach((pd, index) => {
    const reg = new RegExp(pd, "g");
    result = result.replace(reg, englishDigits[index]);
  });
  return result;
};

// نگاشت تاریخ‌های جلالی به میلادی برای داده‌های ثابت
const jalaliToGregorianMapping = {
  "1402/06/10": "2023/09/01",
  "1402/05/25": "2023/08/16",
  "1402/04/15": "2023/07/06",
  "1402/03/20": "2023/06/10",
  "1402/02/10": "2023/04/30",
  "1402/01/30": "2023/04/19"
};

// تابع تبدیل تاریخ بر اساس زبان
const formatDate = (dateStr, lang) => {
  if (lang === "fa") return dateStr;
  const engDate = convertPersianDigitsToEnglish(dateStr);
  if (jalaliToGregorianMapping[engDate]) {
    return jalaliToGregorianMapping[engDate];
  }
  return engDate;
};

const translations = {
  facultiesTitle: {
    fa: "دانشکده‌های دانشگاه اطلس",
    en: "Atlas University Faculties",
    tr: "Atlas Üniversitesi Fakülteleri"
  },
  tuitionTitle: {
    fa: "جدول شهریه دانشگاه اطلس در سال 2025",
    en: "Atlas University Tuition Fees 2025",
    tr: "2025 Yılı Atlas Üniversitesi Öğrenim Ücreti Tablosu"
  },
  tuitionTable: {
    headerFaculty: {
      fa: "رشته",
      en: "Faculty",
      tr: "Bölüm"
    },
    headerFee: {
      fa: "شهریه (سالانه)",
      en: "Tuition Fee (Yearly)",
      tr: "Yıllık Öğrenim Ücreti"
    },
    headerDescription: {
      fa: "توضیحات",
      en: "Description",
      tr: "Açıklama"
    }
  },
  newsTitle: {
    fa: "اخبار و رویدادها",
    en: "News and Events",
    tr: "Haberler ve Etkinlikler"
  },
  researchTitle: {
    fa: "مقالات تحقیقاتی و تحقیقات دانشجویی",
    en: "Research Articles and Student Research",
    tr: "Araştırma Makaleleri ve Öğrenci Araştırmaları"
  },
  collaborationTitle: {
    fa: "همکاری‌های بین‌المللی",
    en: "International Collaborations",
    tr: "Uluslararası İşbirlikleri"
  },
  collaborationData: [
    {
      name: {
        fa: "دانشگاه هاروارد",
        en: "Harvard University",
        tr: "Harvard Üniversitesi"
      },
      link: "https://www.harvard.edu/",
      ranking: "1",
      icon: <FaCheckCircle />,
      background: harvardImage
    },
    {
      name: {
        fa: "دانشگاه آکسفورد",
        en: "Oxford University",
        tr: "Oxford Üniversitesi"
      },
      link: "https://www.ox.ac.uk/",
      ranking: "2",
      icon: <FaCheckCircle />,
      background: oxfordImage
    },
    {
      name: {
        fa: "دانشگاه توکیو",
        en: "University of Tokyo",
        tr: "Tokyo Üniversitesi"
      },
      link: "https://www.u-tokyo.ac.jp/en",
      ranking: "3",
      icon: <FaCheckCircle />,
      background: tokyoImage
    },
    {
      name: {
        fa: "دانشگاه فنی مونیخ",
        en: "Technical University of Munich",
        tr: "Münih Teknik Üniversitesi"
      },
      link: "https://www.tum.de/",
      ranking: "4",
      icon: <FaCheckCircle />,
      background: technicalImage
    }
  ],
  entrepreneurshipTitle: {
    fa: "کارآفرینی و فرصت‌های شغلی",
    en: "Entrepreneurship and Job Opportunities",
    tr: "Girişimcilik ve İş Fırsatları"
  },
  announcementTitle: {
    fa: "🎓 فرصت ویژه: بورسیه تحصیلی 50%",
    en: "🎓 Special Offer: 50% Scholarship Opportunity",
    tr: "🎓 Özel Fırsat: %50 Burs İmkanı"
  },
  announcementText: {
    fa: "با ثبت‌نام در سیستم UniManagaer، درهای موفقیت برای شما باز می‌شود. تنها با چند کلیک، می‌توانید از بورسیه ۵۰ درصدی بهره‌مند شوید و هزینه‌های تحصیلی خود را کاهش دهید. این پیشنهاد محدود، فرصتی است برای دانشجویان آینده‌ساز.",
    en: "Register through UniManagaer and open the door to success. With just a few clicks, you can benefit from an exclusive 40% scholarship and reduce your tuition fees. This limited-time offer is a chance for tomorrow’s leaders.",
    tr: "UniManagaer üzerinden kayıt olarak başarı kapılarını aralayın. Sadece birkaç tıkla özel %40 burs fırsatından yararlanarak öğrenim ücretlerinizi düşürebilirsiniz. Bu sınırlı teklif, geleceğin liderleri için bir fırsattır."
  },
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  faqData: [
    {
      question: {
        fa: "شرایط پذیرش دانشگاه اطلس چیست؟",
        en: "What are the admission requirements for Atlas University?",
        tr: "Atlas Üniversitesi'ne kabul şartları nelerdir?"
      },
      answer: {
        fa: "برای پذیرش در دانشگاه اطلس، ارائه مدارک تحصیلی معتبر، نمرات مطلوب و در برخی موارد مصاحبه لازم است.",
        en: "To be admitted to Atlas University, you must submit valid academic certificates, achieve competitive grades, and in some cases, attend an interview.",
        tr: "Atlas Üniversitesi'ne kabul için geçerli akademik belgeler, yeterli notlar ve bazı durumlarda mülakata katılım gerekmektedir."
      }
    },
    {
      question: {
        fa: "شهریه دانشگاه اطلس در سال 2025 چقدر است؟",
        en: "What is the tuition fee for Atlas University in 2025?",
        tr: "2025 yılında Atlas Üniversitesi'nin öğrenim ücreti ne kadardır?"
      },
      answer: {
        fa: "شهریه دانشگاه اطلس در سال 2025 بسته به رشته بین ۴۰۰۰ تا ۱۰۰۰۰ دلار متغیر است.",
        en: "The tuition fee at Atlas University in 2025 varies between $4,000 and $10,000 depending on the program.",
        tr: "2025 yılında Atlas Üniversitesi'nde öğrenim ücreti, programa bağlı olarak 4.000 ila 10.000 dolar arasında değişmektedir."
      }
    },
    {
      question: {
        fa: "دانشگاه اطلس چه رشته‌هایی ارائه می‌دهد؟",
        en: "What programs or faculties are offered at Atlas University?",
        tr: "Atlas Üniversitesi'nde hangi bölümler sunulmaktadır?"
      },
      answer: {
        fa: "دانشگاه اطلس در رشته‌های مهندسی، پزشکی، حقوق، علوم اجتماعی، مدیریت، معماری، علوم کامپیوتر و هنر فعالیت می‌کند.",
        en: "Atlas University offers programs in Engineering, Medicine, Law, Social Sciences, Management, Architecture, Computer Science, and Arts.",
        tr: "Atlas Üniversitesi, Mühendislik, Tıp, Hukuk, Sosyal Bilimler, Yönetim, Mimarlık, Bilgisayar Bilimleri ve Sanat bölümlerinde eğitim vermektedir."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه اطلس برای دانشجویان بین‌المللی بورسیه دارد؟",
        en: "Does Atlas University offer scholarships for international students?",
        tr: "Atlas Üniversitesi uluslararası öğrenciler için burs imkanı sağlıyor mu?"
      },
      answer: {
        fa: "بله، دانشگاه اطلس بورسیه‌هایی از جمله تخفیف شهریه و کمک هزینه‌های مالی برای دانشجویان بین‌المللی ارائه می‌دهد.",
        en: "Yes, Atlas University offers scholarships, including tuition discounts and financial aid for international students.",
        tr: "Evet, Atlas Üniversitesi uluslararası öğrenciler için öğrenim ücreti indirimi ve maddi destek sağlayan burslar sunmaktadır."
      }
    },
    {
      question: {
        fa: "فرآیند پذیرش در دانشگاه اطلس چگونه است؟",
        en: "What is the admission process for Atlas University?",
        tr: "Atlas Üniversitesi'ne kabul süreci nasıl işlemektedir?"
      },
      answer: {
        fa: "فرآیند پذیرش شامل ارائه مدارک تحصیلی، ارزیابی نمرات و در صورت نیاز، مصاحبه می‌باشد.",
        en: "The admission process involves submitting academic documents, grade evaluation, and an interview if required.",
        tr: "Kabul süreci, akademik belgelerin sunulması, not değerlendirmesi ve gerekirse mülakatı içerir."
      }
    },
    {
      question: {
        fa: "امکانات رفاهی و خوابگاهی دانشگاه اطلس چگونه است؟",
        en: "What are the accommodation and campus facilities like at Atlas University?",
        tr: "Atlas Üniversitesi'nin konaklama ve kampüs olanakları nasıldır?"
      },
      answer: {
        fa: "دانشگاه اطلس امکانات رفاهی مدرن، خوابگاه‌های مجهز و خدمات متنوعی برای دانشجویان فراهم کرده است.",
        en: "Atlas University provides modern amenities, well-equipped dormitories, and diverse services for its students.",
        tr: "Atlas Üniversitesi, modern olanaklar, donanımlı yurtlar ve öğrencilere yönelik çeşitli hizmetler sunmaktadır."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  newsData: [
    {
      title: {
        fa: "افتتاح سالن همایش‌های بین‌المللی",
        en: "Inauguration of the International Conference Hall",
        tr: "Uluslararası Konferans Salonu Açılışı"
      },
      date: "۱۴۰۲/۰۶/۱۰",
      description: {
        fa: "دانشگاه اطلس سالن همایش‌های مدرن خود را به صورت رسمی افتتاح کرد.",
        en: "Atlas University officially inaugurated its state-of-the-art conference hall.",
        tr: "Atlas Üniversitesi, son teknoloji konferans salonunu resmen açtı."
      }
    },
    {
      title: {
        fa: "برگزاری نمایشگاه علمی دانشجویی",
        en: "Student Science Exhibition Held",
        tr: "Öğrenci Bilim Sergisi Düzenlendi"
      },
      date: "۱۴۰۲/۰۵/۲۵",
      description: {
        fa: "دانشجویان در نمایشگاهی خلاقانه پروژه‌های تحقیقاتی خود را به نمایش گذاشتند.",
        en: "Students showcased their innovative research projects at a science exhibition.",
        tr: "Öğrenciler, yaratıcı araştırma projelerini sergiledikleri bir bilim fuarında yer aldı."
      }
    }
  ],
  researchData: [
    {
      title: {
        fa: "تحقیقات پیشرفته در حوزه رباتیک",
        en: "Advanced Research in Robotics",
        tr: "Robotik Alanında İleri Araştırmalar"
      },
      date: "۱۴۰۲/۰۳/۲۰",
      description: {
        fa: "نتایج یک مطالعه جامع در زمینه توسعه ربات‌های هوشمند.",
        en: "Results of an extensive study on the development of intelligent robots.",
        tr: "Akıllı robotların geliştirilmesi üzerine kapsamlı bir çalışmanın sonuçları."
      }
    },
    {
      title: {
        fa: "مطالعه نوین در علوم داده",
        en: "Innovative Study in Data Science",
        tr: "Veri Bilimi Alanında Yenilikçi Çalışma"
      },
      date: "۱۴۰۲/۰۲/۱۰",
      description: {
        fa: "بررسی روش‌های نوین تحلیل داده و کاربرد آن در صنعت.",
        en: "Exploration of modern data analysis techniques and their industrial applications.",
        tr: "Modern veri analiz yöntemlerinin incelenmesi ve sanayideki uygulamalarının araştırılması."
      }
    },
    {
      title: {
        fa: "تحلیل عملکرد سیستم‌های هوشمند",
        en: "Performance Analysis of Intelligent Systems",
        tr: "Akıllı Sistemlerin Performans Analizi"
      },
      date: "۱۴۰۲/۰۱/۳۰",
      description: {
        fa: "ارزیابی سیستم‌های هوشمند و ارائه راهکارهای بهینه‌سازی.",
        en: "Evaluation of intelligent systems and providing optimization solutions.",
        tr: "Akıllı sistemlerin değerlendirilmesi ve iyileştirme çözümlerinin sunulması."
      }
    }
  ],
  entrepreneurshipData: [
    {
      title: {
        fa: "کارگاه نوآوری و استارتاپ",
        en: "Innovation & Startup Workshop",
        tr: "İnovasyon ve Startup Atölyesi"
      },
      description: {
        fa: "برگزاری دوره‌های تخصصی جهت پرورش ایده‌های نوآورانه و راه‌اندازی کسب‌وکار.",
        en: "Specialized workshops to nurture innovative ideas and launch startups.",
        tr: "Yaratıcı fikirleri geliştirmek ve girişimcilik için özel atölye çalışmaları düzenleniyor."
      }
    },
    {
      title: {
        fa: "مشاوره شغلی و کارآفرینی",
        en: "Career and Entrepreneurship Counseling",
        tr: "Kariyer ve Girişimcilik Danışmanlığı"
      },
      description: {
        fa: "ارائه راهنمایی‌های شغلی و کارآفرینی به دانشجویان جهت ورود موفق به بازار کار.",
        en: "Providing career and entrepreneurship counseling to help students successfully enter the job market.",
        tr: "Öğrencilere kariyer ve girişimcilik danışmanlığı hizmetleri sunuluyor."
      }
    },
    {
      title: {
        fa: "نمایشگاه فرصت‌های شغلی",
        en: "Job Opportunity Fair",
        tr: "İstihdam Fuarı"
      },
      description: {
        fa: "برگزاری رویدادی جهت معرفی فرصت‌های شغلی و کارآفرینی در صنایع مختلف.",
        en: "An event showcasing diverse job and entrepreneurial opportunities across industries.",
        tr: "Farklı sektörlerdeki iş ve girişimcilik fırsatlarının tanıtıldığı bir etkinlik."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  financialAidData: [
    {
      title: {
        fa: "بورسیه کامل تحصیلی",
        en: "Full Scholarship",
        tr: "Tam Burs"
      },
      description: {
        fa: "پوشش کامل شهریه و هزینه‌های زندگی برای دانشجویان ممتاز.",
        en: "Full coverage of tuition and living expenses for outstanding students.",
        tr: "Başarılı öğrenciler için öğrenim ve yaşam giderlerinin tam karşılanması."
      }
    },
    {
      title: {
        fa: "بورسیه جزئی",
        en: "Partial Scholarship",
        tr: "Kısmi Burs"
      },
      description: {
        fa: "تخفیف قابل توجه در شهریه همراه با کمک هزینه‌های جزئی.",
        en: "Significant tuition discount along with partial financial aid.",
        tr: "Önemli öğrenim ücreti indirimi ve kısmi maddi destek."
      }
    },
    {
      title: {
        fa: "وام دانشجویی با شرایط ویژه",
        en: "Student Loan with Special Conditions",
        tr: "Özel Şartlarda Öğrenci Kredisi"
      },
      description: {
        fa: "امکان دریافت وام با شرایط مساعد جهت تأمین هزینه‌های تحصیلی.",
        en: "Availability of loans under favorable conditions to cover tuition fees.",
        tr: "Öğrenim giderlerini karşılamak için uygun şartlarda kredi imkanı."
      }
    }
  ]
};

const facultiesData = [
  { 
    name: { 
      fa: "دانشکده مهندسی", 
      en: "Faculty of Engineering", 
      tr: "Mühendislik Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های تحصیلی نوین در رشته‌های مهندسی برق، مکانیک و صنایع الکترونیک.", 
      en: "Modern academic programs in Electrical, Mechanical, and Electronics Engineering.", 
      tr: "Elektrik, Makine ve Elektronik Mühendisliği alanlarında çağdaş eğitim programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده پزشکی", 
      en: "Faculty of Medicine", 
      tr: "Tıp Fakültesi" 
    },
    description: { 
      fa: "آموزش و پژوهش‌های پیشرفته در علوم پزشکی و بالینی.", 
      en: "Advanced education and research in medical and clinical sciences.", 
      tr: "Tıp ve klinik bilimlerde ileri düzey eğitim ve araştırmalar." 
    }
  },
  { 
    name: { 
      fa: "دانشکده حقوق", 
      en: "Faculty of Law", 
      tr: "Hukuk Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های حقوقی با استانداردهای بین‌المللی و تاکید بر حقوق بشر.", 
      en: "International law programs with an emphasis on human rights.", 
      tr: "Uluslararası standartlarda, insan haklarına vurgu yapan hukuk programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده علوم اجتماعی", 
      en: "Faculty of Social Sciences", 
      tr: "Sosyal Bilimler Fakültesi" 
    },
    description: { 
      fa: "تحلیل و آموزش در حوزه‌های علوم انسانی و اجتماعی با نگرش جهانی.", 
      en: "Global-level education in humanities and social sciences.", 
      tr: "İnsan ve sosyal bilimlerde küresel perspektifte eğitim." 
    }
  },
  { 
    name: { 
      fa: "دانشکده مدیریت", 
      en: "Faculty of Management", 
      tr: "Yönetim Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های مدیریتی با رویکرد نوآورانه و عملی.", 
      en: "Innovative and practical management programs.", 
      tr: "Yenilikçi ve uygulamaya yönelik yönetim programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده معماری", 
      en: "Faculty of Architecture", 
      tr: "Mimarlık Fakültesi" 
    },
    description: { 
      fa: "طراحی و برنامه‌ریزی مدرن در حوزه معماری و ساختار.", 
      en: "Modern design and planning in architecture and construction.", 
      tr: "Mimarlık ve yapı tasarımında modern ve yaratıcı yaklaşımlar." 
    }
  },
  { 
    name: { 
      fa: "دانشکده علوم کامپیوتر", 
      en: "Faculty of Computer Science", 
      tr: "Bilgisayar Bilimleri Fakültesi" 
    },
    description: { 
      fa: "آموزش و پژوهش در زمینه توسعه نرم‌افزار، هوش مصنوعی و داده‌کاوی.", 
      en: "Education and research in software development, AI, and data analytics.", 
      tr: "Yazılım geliştirme, yapay zeka ve veri analitiği alanlarında eğitim ve araştırma." 
    }
  },
  { 
    name: { 
      fa: "دانشکده هنر", 
      en: "Faculty of Arts", 
      tr: "Güzel Sanatlar Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های هنری در زمینه‌های طراحی، عکاسی و رسانه‌های دیجیتال.", 
      en: "Art programs in design, photography, and digital media.", 
      tr: "Tasarım, fotoğrafçılık ve dijital medya alanlarında sanat programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده ارتباطات", 
      en: "Faculty of Communications", 
      tr: "İletişim Fakültesi" 
    },
    description: { 
      fa: "آموزش‌های ارتباطات، رسانه و دیجیتال مارکتینگ با روش‌های نوین.", 
      en: "Programs in communications, media, and digital marketing using modern techniques.", 
      tr: "İletişim, medya ve dijital pazarlama alanlarında çağdaş eğitim programları." 
    }
  }
];

const FacultyCard = ({ name, description }) => (
  <div className="uni-faculty-card">
    <h3 className="uni-faculty-card-title">{name}</h3>
    <p className="uni-faculty-card-text">{description}</p>
  </div>
);

const TuitionTable = ({ lang }) => {
  const tuitionData = [
    {
      faculty: { 
        fa: "پزشکی", 
        en: "Medicine", 
        tr: "Tıp" 
      },
      fee: { fa: "$30,000", en: "$30,000", tr: "$30,000" },
      description: {
        fa: "آموزش پزشکی با تأکید بر پژوهش‌های بالینی و فناوری‌های نوین.",
        en: "Medical education with an emphasis on clinical research and modern technologies.",
        tr: "Klinik araştırmalar ve modern teknolojilere vurgu yapan tıp eğitimi."
      }
    },
    {
      faculty: { 
        fa: "دندانپزشکی", 
        en: "Dentistry", 
        tr: "Diş Hekimliği" 
      },
      fee: { fa: "$26,000", en: "$26,000", tr: "$26,000" },
      description: {
        fa: "برنامه‌های دندانپزشکی با استفاده از فناوری‌های پیشرفته.",
        en: "Dentistry programs utilizing advanced technologies.",
        tr: "İleri teknoloji kullanılan diş hekimliği programları."
      }
    },
    {
      faculty: { 
        fa: "داروسازی", 
        en: "Pharmacy", 
        tr: "Eczacılık" 
      },
      fee: { fa: "$16,000", en: "$16,000", tr: "$16,000" },
      description: {
        fa: "آموزش داروسازی با تمرکز بر علم داروها و نوآوری در صنعت داروسازی.",
        en: "Pharmacy education focusing on pharmaceutical sciences and industry innovation.",
        tr: "İlaç bilimleri ve endüstride yeniliğe odaklanan eczacılık eğitimi."
      }
    },
    {
      faculty: { 
        fa: "فیزیوتراپی", 
        en: "Physiotherapy", 
        tr: "Fizyoterapi" 
      },
      fee: { fa: "$7,000", en: "$7,000", tr: "$7,000" },
      description: {
        fa: "دوره‌های فیزیوتراپی با رویکرد نوین درمان‌های حرکتی.",
        en: "Physiotherapy courses with a modern approach to movement therapies.",
        tr: "Hareket terapilerine modern yaklaşım sunan fizyoterapi dersleri."
      }
    },
    {
      faculty: { 
        fa: "پرستاری", 
        en: "Nursing", 
        tr: "Hemşirelik" 
      },
      fee: { fa: "$6,500", en: "$6,500", tr: "$6,500" },
      description: {
        fa: "آموزش پرستاری با تمرکز بر مراقبت‌های بهداشتی پیشرفته.",
        en: "Nursing education focused on advanced healthcare.",
        tr: "Gelişmiş sağlık hizmetlerine odaklanan hemşirelik eğitimi."
      }
    },
    {
      faculty: { 
        fa: "تغذیه", 
        en: "Nutrition", 
        tr: "Beslenme" 
      },
      fee: { fa: "$6,500", en: "$6,500", tr: "$6,500" },
      description: {
        fa: "دوره‌های آموزشی در زمینه تغذیه و سلامت عمومی با رویکرد نوین.",
        en: "Courses in nutrition and public health with a modern approach.",
        tr: "Modern yaklaşımla beslenme ve halk sağlığı dersleri."
      }
    },
    {
      faculty: { 
        fa: "هوش مصنوعی", 
        en: "Artificial Intelligence", 
        tr: "Yapay Zeka" 
      },
      fee: { fa: "$13,000", en: "$13,000", tr: "$13,000" },
      description: {
        fa: "تحقیقات و آموزش در زمینه هوش مصنوعی و یادگیری ماشین پیشرفته.",
        en: "Advanced education and research in artificial intelligence and machine learning.",
        tr: "İleri düzey yapay zeka ve makine öğrenimi eğitimi ve araştırmaları."
      }
    },
    {
      faculty: { 
        fa: "معماری", 
        en: "Architecture", 
        tr: "Mimarlık" 
      },
      fee: { fa: "$9,500", en: "$9,500", tr: "$9,500" },
      description: {
        fa: "برنامه‌های طراحی معماری با تمرکز بر نوآوری و پایداری.",
        en: "Architectural design programs with an emphasis on innovation and sustainability.",
        tr: "Yenilik ve sürdürülebilirliğe vurgu yapan mimarlık tasarım programları."
      }
    },
    {
      faculty: { 
        fa: "فیلم و تلویزیون", 
        en: "Film and Television", 
        tr: "Film ve Televizyon" 
      },
      fee: { fa: "$9,500", en: "$9,500", tr: "$9,500" },
      description: {
        fa: "آموزش در زمینه فیلم‌سازی و تلویزیون با استفاده از تکنولوژی‌های روز.",
        en: "Courses in filmmaking and television utilizing the latest technologies.",
        tr: "Güncel teknolojilerle film yapımı ve televizyon eğitimi."
      }
    },
    {
      faculty: { 
        fa: "مهندسی عمران", 
        en: "Civil Engineering", 
        tr: "İnşaat Mühendisliği" 
      },
      fee: { fa: "$10,000", en: "$10,000", tr: "$10,000" },
      description: {
        fa: "آموزش در زمینه طراحی و ساخت سازه‌های مدرن و پایدار.",
        en: "Education in designing and constructing modern, sustainable structures.",
        tr: "Modern ve sürdürülebilir yapıların tasarımı ve inşası eğitimi."
      }
    },
    {
      faculty: { 
        fa: "مهندسی کامپیوتر", 
        en: "Computer Engineering", 
        tr: "Bilgisayar Mühendisliği" 
      },
      fee: { fa: "$10,000", en: "$10,000", tr: "$10,000" },
      description: {
        fa: "برنامه‌های برنامه‌نویسی، توسعه نرم‌افزار و تحلیل داده با رویکرد عملی.",
        en: "Courses in programming, software development, and data analysis with a practical approach.",
        tr: "Uygulamalı yaklaşımla programlama, yazılım geliştirme ve veri analizi dersleri."
      }
    },
    {
      faculty: { 
        fa: "روابط عمومی", 
        en: "Public Relations", 
        tr: "Halkla İlişkiler" 
      },
      fee: { fa: "$9,500", en: "$9,500", tr: "$9,500" },
      description: {
        fa: "آموزش در زمینه مدیریت ارتباطات و رسانه‌های دیجیتال.",
        en: "Education in communication management and digital media.",
        tr: "İletişim yönetimi ve dijital medya alanlarında eğitim."
      }
    },
    {
      faculty: { 
        fa: "معماری داخلی", 
        en: "Interior Architecture", 
        tr: "İç Mimarlık" 
      },
      fee: { fa: "$9,500", en: "$9,500", tr: "$9,500" },
      description: {
        fa: "دوره‌های طراحی داخلی با رویکرد خلاقانه و مدرن.",
        en: "Interior design courses with a creative and modern approach.",
        tr: "Yaratıcı ve modern yaklaşımla iç tasarım dersleri."
      }
    }
  ];

  const headers =
    lang === "fa"
      ? [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerFee[lang],
          translations.tuitionTable.headerDescription[lang]
        ]
      : [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerDescription[lang],
          translations.tuitionTable.headerFee[lang]
        ];

  return (
    <table className="uni-tuition-table">
      <thead>
        <tr>
          <th>{headers[0]}</th>
          <th>{headers[1]}</th>
          <th>{headers[2]}</th>
        </tr>
      </thead>
      <tbody>
        {tuitionData.map((item, index) =>
          lang === "fa" ? (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.fee[lang]}</td>
              <td>{item.description[lang]}</td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.description[lang]}</td>
              <td>{item.fee[lang]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const FAQSection = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);
  return (
    <section className="uni-faq-section">
      <h2 className="uni-faq-title">{translations.faqTitle[lang]}</h2>
      <div className="uni-faq-grid">
        {translations.faqData.map((faq, index) => (
          <div
            key={index}
            className="uni-faq-card"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="uni-faq-question">{faq.question[lang]}</h3>
            {activeIndex === index && (
              <p className="uni-faq-answer">{faq.answer[lang]}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

const AtlasUniversityMiddle = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className={`uni-container ${lang !== "fa" ? "ltr" : ""}`}>
      {/* دانشکده‌ها */}
      <section className="uni-faculties-section">
        <h2 className="uni-faculties-title">{translations.facultiesTitle[lang]}</h2>
        <div className="uni-faculties-grid">
          {facultiesData.map((item, index) => (
            <FacultyCard 
              key={index} 
              name={item.name[lang]} 
              description={item.description[lang]} 
            />
          ))}
        </div>
      </section>

      {/* جدول شهریه */}
      <section className="uni-tuition-section">
        <h2 className="uni-tuition-title">{translations.tuitionTitle[lang]}</h2>
        <TuitionTable lang={lang} />
      </section>


      {/* اخبار و رویدادها */}
      <section className="uni-news-section">
        <h2 className="uni-news-title">{translations.newsTitle[lang]}</h2>
        <div className="uni-news-grid">
          {translations.newsData.map((news, index) => (
            <div key={index} className="uni-news-card">
              <h3 className="uni-news-card-title">{news.title[lang]}</h3>
              <span className="uni-news-card-date">
                {formatDate(news.date, lang)}
              </span>
              <p className="uni-news-card-text">{news.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* مقالات تحقیقاتی */}
      <section className="uni-research-section">
        <h2 className="uni-research-title">{translations.researchTitle[lang]}</h2>
        <div className="uni-research-grid">
          {translations.researchData.map((article, index) => (
            <div key={index} className="uni-research-card">
              <h3 className="uni-research-card-title">{article.title[lang]}</h3>
              <span className="uni-research-card-date">{formatDate(article.date, lang)}</span>
              <p className="uni-research-card-text">{article.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* همکاری‌های بین‌المللی */}
      <section className="uni-collaboration-section">
        <h2 className="uni-collaboration-title">{translations.collaborationTitle[lang]}</h2>
        <div className="uni-collaboration-grid">
          {translations.collaborationData.map((item, index) => (
            <div 
              key={index} 
              className="uni-collaboration-item"
              style={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.name[lang]}
              </a>
              <div className="uni-collaboration-ranking">
                {lang === "fa"
                  ? `رتبه جهانی: ${item.ranking}`
                  : `Global Ranking: ${item.ranking}`}
                {item.icon}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* کارآفرینی و فرصت‌های شغلی */}
      <section className="uni-entrepreneurship-section">
        <h2 className="uni-entrepreneurship-title">{translations.entrepreneurshipTitle[lang]}</h2>
        <div className="uni-entrepreneurship-grid">
          {translations.entrepreneurshipData.map((item, index) => (
            <div key={index} className="uni-entrepreneurship-card">
              <h3 className="uni-entrepreneurship-card-title">{item.title[lang]}</h3>
              <p className="uni-entrepreneurship-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* اعلان ویژه بورسیه */}
      <section className="uni-announcement-section">
        <div
          className="announcement-bg-overlay"
          style={{ backgroundImage: `url(${uniManagerImage})` }}
        ></div>
        <div className="announcement-content">
          <h2 className="uni-announcement-title">
            {translations.announcementTitle[lang]}
          </h2>
          <p className="uni-announcement-text">
            {translations.announcementText[lang]}
          </p>
        </div>
      </section>

      {/* سوالات متداول */}
      <FAQSection lang={lang} />

      {/* خدمات مالی و بورسیه */}
      <section className="uni-financial-section">
        <h2 className="uni-financial-title">{translations.financialTitle[lang]}</h2>
        <div className="uni-financial-grid">
          {translations.financialAidData.map((item, index) => (
            <div key={index} className="uni-financial-card">
              <h3 className="uni-financial-card-title">{item.title[lang]}</h3>
              <p className="uni-financial-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AtlasUniversityMiddle;
