import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ExteraToolbar from "../../components/ExteraToolbar";
import medipolPoster from "../../assets/image/university/medipol/Medipol.webp";
import medipolPoster1 from "../../assets/image/university/medipol/medipol1.webp";
import medipolPoster2 from "../../assets/image/university/medipol/medipol2.webp";
import medipolPoster3 from "../../assets/image/university/medipol/medipol3.webp";
import medipolPoster4 from "../../assets/image/university/medipol/medipol4.webp";
import medipolPoster5 from "../../assets/image/university/medipol/medipol5.webp";
import medipolPoster6 from "../../assets/image/university/medipol/medipol6.webp";
import { 
  FaCity,
  FaGlobe,
  FaStar,
  FaLanguage,
  FaUserGraduate,
  FaUniversity,
  FaCheckCircle,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaSubway,
  FaUsers,
  FaBuilding 
} from "react-icons/fa";
import "../../components/styles/Uni1.css";

// شی ترجمه برای متون دانشگاه مدیپل
const translations = {
  titleUniGlance: {
    fa: "دانشگاه مدیپل در یک نگاه",
    en: "Medipol University at a Glance",
    tr: "Medipol Üniversitesi Bir Bakışta"
  },
  infoData: {
    city: { fa: "شهر", en: "City", tr: "Şehir" },
    cityValue: { fa: "استانبول", en: "Istanbul", tr: "İstanbul" },
    faculties: { fa: "تعداد دانشکده", en: "Number of Faculties", tr: "Fakülte Sayısı" },
    facultiesValue: { fa: "۸ دانشکده", en: "8 Faculties", tr: "8 Fakülte" },
    type: { fa: "نوع دانشگاه", en: "Type of University", tr: "Üniversite Türü" },
    typeValue: { fa: "خصوصی و بین‌المللی", en: "Private & International", tr: "Özel & Uluslararası" },
    ranking: { fa: "رتبه داخلی", en: "Domestic Ranking", tr: "Ulusal Sıra" },
    rankingValue: { fa: "۱ در بین دانشگاه‌های خصوصی", en: "1st among Private Universities", tr: "Özel Üniversiteler arasında 1." },
    accredited: { fa: "تأیید", en: "Accredited", tr: "Onaylı" },
    accreditedValue: { fa: "مورد تأیید وزارت علوم ایران", en: "Accredited by the Iranian Ministry of Science", tr: "İran Bilim Bakanlığı Onaylı" },
    founded: { fa: "سال تأسیس", en: "Year Founded", tr: "Kuruluş Yılı" },
    foundedValue: { fa: "۲۰۰۹ میلادی", en: "2009", tr: "2009" },
    language: { fa: "زبان تحصیل", en: "Language of Instruction", tr: "Öğretim Dili" },
    languageValue: { fa: "ترکی / انگلیسی", en: "Turkish / English", tr: "Türkçe / İngilizce" },
    admission: { fa: "شرایط پذیرش", en: "Admission Criteria", tr: "Kabul Şartları" },
    admissionValue: { fa: "بدون آزمون ورودی (برای اکثر رشته‌ها)", en: "No Entrance Exam (for most programs)", tr: "Çoğu program için giriş sınavı yok" },
    students: { fa: "تعداد دانشجو", en: "Number of Students", tr: "Öğrenci Sayısı" },
    studentsValue: { fa: "۳۵۰۰۰+", en: "35,000+", tr: "35,000+" },
    collaborations: { fa: "همکاری‌های بین‌المللی", en: "International Collaborations", tr: "Uluslararası İşbirlikleri" },
    collaborationsValue: { fa: "۲۵۰+", en: "250+", tr: "250+" }
  },
  galleryTitle: {
    fa: "گالری تصاویر دانشگاه مدیپل",
    en: "Gallery of Medipol University Images",
    tr: "Medipol Üniversitesi Resim Galerisi"
  },
  imageAlt: {
    fa: "تصویر",
    en: "Image",
    tr: "Resim"
  },
  details: {
    international: {
      title: {
        fa: "فرصت‌های بین‌المللی",
        en: "International Opportunities",
        tr: "Uluslararası Fırsatlar"
      },
      text: {
        fa: "با همکاری‌های گسترده با دانشگاه‌های معتبر بین‌المللی، محیطی پویا و جهانی ایجاد شده است.",
        en: "Extensive collaborations with renowned international universities create a dynamic global environment.",
        tr: "Ünlü uluslararası üniversitelerle yapılan kapsamlı işbirlikleri, dinamik küresel bir ortam oluşturuyor."
      }
    },
    location: {
      title: {
        fa: "موقعیت جغرافیایی ممتاز",
        en: "Prime Geographical Location",
        tr: "Üstün Coğrafi Konum"
      },
      text: {
        fa: "دانشگاه مدیپل در منطقه آتاشهیر استانبول واقع شده و از دسترسی آسان به امکانات شهری برخوردار است.",
        en: "Medipol University is located in the Ataşehir district of Istanbul with easy access to city amenities.",
        tr: "Medipol Üniversitesi, İstanbul'un Ataşehir ilçesinde, şehir olanaklarına kolay erişimle konumlanmıştır."
      }
    },
    metro: {
      title: {
        fa: "دسترسی آسان به حمل و نقل عمومی",
        en: "Easy Access to Public Transport",
        tr: "Toplu Taşıma Kolaylığı"
      },
      text: {
        fa: "موقعیت دانشگاه نزدیک به ایستگاه‌های مترو، اتوبوس و سایر وسایل حمل و نقل عمومی است.",
        en: "The university is situated near metro, bus, and other public transport stations.",
        tr: "Üniversite, metro, otobüs ve diğer toplu taşıma istasyonlarına yakın konumdadır."
      }
    },
    iranians: {
      title: {
        fa: "محیط دوستانه برای ایرانیان",
        en: "Friendly Environment for Iranians",
        tr: "İranlılar için Dostane Ortam"
      },
      text: {
        fa: "جامعه دانشجویی فعال با حضور ایرانیان از سراسر جهان و برگزاری رویدادهای فرهنگی منحصربه‌فرد.",
        en: "An active student community with Iranians from around the world and unique cultural events.",
        tr: "Dünya çapından İranlıların yer aldığı aktif öğrenci topluluğu ve benzersiz kültürel etkinlikler."
      }
    },
    facilities: {
      title: {
        fa: "امکانات مدرن و پیشرفته",
        en: "Modern and Advanced Facilities",
        tr: "Modern ve Gelişmiş Olanaklar"
      },
      text: {
        fa: "کتابخانه‌های مجهز، خوابگاه‌های مدرن و مراکز پژوهشی پیشرفته، از ویژگی‌های برجسته دانشگاه مدیپل هستند.",
        en: "Equipped libraries, modern dormitories, and advanced research centers are hallmarks of Medipol University.",
        tr: "Donanımlı kütüphaneler, modern yurtlar ve gelişmiş araştırma merkezleri, Medipol Üniversitesi’nin öne çıkan özelliklerindendir."
      }
    }
  },
  admissionSection: {
    title: {
      fa: "شرایط پذیرش و بورسیه دانشگاه مدیپل",
      en: "Admission Requirements & Scholarships - Medipol University",
      tr: "Medipol Üniversitesi Kabul Şartları ve Burslar"
    },
    items: [
      {
        title: {
          fa: "شرایط پذیرش دانشگاه مدیپل",
          en: "Admission Requirements for Medipol University",
          tr: "Medipol Üniversitesi Kabul Şartları"
        },
        text: {
          fa: "بسیاری از رشته‌ها بدون آزمون ورودی هستند؛ برخی رشته‌های تخصصی ممکن است آزمون و مصاحبه داشته باشند.",
          en: "Most programs do not require an entrance exam; some specialized programs may require an exam and interview.",
          tr: "Çoğu program için giriş sınavı gerekmemekle birlikte, bazı uzmanlık programları sınav ve mülakat isteyebilmektedir."
        }
      },
      {
        title: {
          fa: "شرایط پذیرش ارشد و دکتری",
          en: "Admission Requirements for Master's and PhD",
          tr: "Yüksek Lisans ve Doktora Kabul Şartları"
        },
        text: {
          fa: "برای پذیرش در مقاطع ارشد و دکتری، ارائه مدرک لیسانس معتبر، نمرات مطلوب و در برخی موارد مصاحبه الزامی است.",
          en: "For admission to master's and PhD programs, a valid bachelor's degree, good grades, and in some cases an interview are required.",
          tr: "Yüksek lisans ve doktora için geçerli bir lisans diploması, iyi notlar ve bazı durumlarda mülakat gereklidir."
        }
      },
      {
        title: {
          fa: "مدارک ثبت‌نام",
          en: "Enrollment Documents",
          tr: "Kayıt Belgeleri"
        },
        text: {
          fa: "مدارک مورد نیاز شامل مدرک تحصیلی، ریزنمرات، توصیه‌نامه، گذرنامه معتبر و مدارک زبان (انگلیسی یا ترکی) است.",
          en: "Required documents include educational certificates, transcripts, recommendation letters, valid passport, and language certificates (English or Turkish).",
          tr: "Gerekli belgeler arasında eğitim belgesi, not dökümleri, tavsiye mektupları, geçerli pasaport ve dil belgeleri (İngilizce veya Türkçe) bulunmaktadır."
        }
      },
      {
        title: {
          fa: "بورسیه دانشگاه مدیپل",
          en: "Medipol University Scholarships",
          tr: "Medipol Üniversitesi Bursları"
        },
        text: {
          fa: "بورسیه‌هایی شامل تخفیف شهریه، کمک هزینه‌های مالی و امکانات خوابگاهی ارائه می‌شود.",
          en: "Scholarships include tuition discounts, financial aid, and dormitory facilities.",
          tr: "Burslar, öğrenim ücreti indirimi, maddi destek ve yurt imkanlarını kapsamaktadır."
        }
      },
      {
        title: {
          fa: "شهریه در سال 2025",
          en: "Tuition Fees in 2025",
          tr: "2025 Yılı Öğrenim Ücreti"
        },
        text: {
          fa: "شهریه دانشگاه مدیپل برای دانشجویان بین‌المللی در سال 2025 بسته به رشته بین ۴۰۰۰ تا ۱۰۰۰۰ دلار متغیر است.",
          en: "Tuition fees for international students in 2025 vary between $4000 and $10000 depending on the program.",
          tr: "2025 yılında uluslararası öğrenciler için öğrenim ücreti, programa bağlı olarak 4000 ila 10000 dolar arasında değişmektedir."
        }
      }
    ]
  }
};

// کامپوننت‌های فرعی
const InfoCard = ({ title, value, icon }) => (
  <div className="uni-info-card">
    <div className="uni-info-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

const AdmissionCard = ({ title, text }) => (
  <div className="uni-admission-card">
    <h3 className="uni-admission-card-title">{title}</h3>
    <p className="uni-admission-card-text">{text}</p>
  </div>
);

const MedipolUniversityTop = (props) => {
  // دریافت زبان از پراپ (پیش‌فرض فارسی)
  const lang = props.lang || "fa";
  const containerClass = lang === "fa" ? "uni-container" : "uni-container ltr";
  const detailBlockClass = lang === "fa" ? "uni-detail-icon-block rtl" : "uni-detail-icon-block ltr";

  // آرایه اطلاعات کلی دانشگاه با استفاده از ترجمه
  const infoData = [
    { title: translations.infoData.city[lang], value: translations.infoData.cityValue[lang], icon: <FaCity /> },
    { title: translations.infoData.faculties[lang], value: translations.infoData.facultiesValue[lang], icon: <FaUniversity /> },
    { title: translations.infoData.type[lang], value: translations.infoData.typeValue[lang], icon: <FaGlobe /> },
    { title: translations.infoData.ranking[lang], value: translations.infoData.rankingValue[lang], icon: <FaStar /> },
    { title: translations.infoData.accredited[lang], value: translations.infoData.accreditedValue[lang], icon: <FaCheckCircle /> },
    { title: translations.infoData.founded[lang], value: translations.infoData.foundedValue[lang], icon: <FaCalendarAlt /> },
    { title: translations.infoData.language[lang], value: translations.infoData.languageValue[lang], icon: <FaLanguage /> },
    { title: translations.infoData.admission[lang], value: translations.infoData.admissionValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.students[lang], value: translations.infoData.studentsValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.collaborations[lang], value: translations.infoData.collaborationsValue[lang], icon: <FaGlobe /> }
  ];

  // تنظیمات اسلایدر
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={containerClass}>
      <ExteraToolbar />
      <div className="uni-hero-minimal">
        <img
          src={medipolPoster}
          alt="دانشگاه مدیپل"
          className="uni-hero-image-minimal"
        />
      </div>

      {/* بخش اطلاعات کلی دانشگاه */}
      <section className="uni-info-section">
        <h2 className="uni-info-title">{translations.titleUniGlance[lang]}</h2>
        <div className="uni-info-grid">
          {infoData.map((item, index) => (
            <InfoCard key={index} {...item} />
          ))}
        </div>
      </section>

      {/* بخش گالری تصاویر */}
      <section className="uni-gallery-section">
        <h2 className="uni-gallery-title">{translations.galleryTitle[lang]}</h2>
        <Slider {...sliderSettings}>
          <div className="uni-gallery-card">
            <img src={medipolPoster1} alt={`${translations.imageAlt[lang]} 1`} />
          </div>
          <div className="uni-gallery-card">
            <img src={medipolPoster2} alt={`${translations.imageAlt[lang]} 2`} />
          </div>
          <div className="uni-gallery-card">
            <img src={medipolPoster3} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={medipolPoster4} alt={`${translations.imageAlt[lang]} 4`} />
          </div>
          <div className="uni-gallery-card">
            <img src={medipolPoster5} alt={`${translations.imageAlt[lang]} 5`} />
          </div>
          <div className="uni-gallery-card">
            <img src={medipolPoster6} alt={`${translations.imageAlt[lang]} 6`} />
          </div>
        </Slider>
      </section>

      {/* بخش اطلاعات تکمیلی */}
      <section className="uni-details-section">
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaGlobe /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.international.title[lang]}</h3>
            <p>{translations.details.international.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaMapMarkerAlt /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.location.title[lang]}</h3>
            <p>{translations.details.location.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaSubway /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.metro.title[lang]}</h3>
            <p>{translations.details.metro.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaUsers /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.iranians.title[lang]}</h3>
            <p>{translations.details.iranians.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaBuilding /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.facilities.title[lang]}</h3>
            <p>{translations.details.facilities.text[lang]}</p>
          </div>
        </div>
      </section>

      {/* بخش شرایط پذیرش */}
      <section className="uni-admission-section">
        <h2 className="uni-admission-title">{translations.admissionSection.title[lang]}</h2>
        <div className="uni-admission-grid">
          {translations.admissionSection.items.map((item, index) => (
            <AdmissionCard
              key={index}
              title={item.title[lang]}
              text={item.text[lang]}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default MedipolUniversityTop;
