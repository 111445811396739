import React, { useState, useEffect } from "react";
import "./styles/StudentResidency.css"; // مسیر صحیح فایل CSS
import Lottie from "react-lottie";
import animationData from "../assets/animation/StudentResidency.json";
import Footer from "../components/Footer";
import { useLanguage } from "../contexts/LanguageContext"; // ایمپورت context زبان

const StudentResidency = () => {
  const { language } = useLanguage(); // دریافت زبان از context
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // بررسی تغییر اندازه صفحه
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // نگاشت مقدار زبان دریافتی به کلیدهای موجود در content
  const currentLanguage =
    language === "fa" ? "Persian" : language === "tr" ? "Turkish" : "English";

  const content = {
    English: {
      header: "Student Residency in Turkey with Uni Manager",
      description:
        "Uni Manager, with its outstanding experience in migration and educational services, is your trusted partner on the journey to obtain student residency in Turkey. We manage the entire residency process for you, from initial consultation and condition analysis to document preparation, legal coordination, and final residency approval.",
      actionsHeader: "Our Services for Securing Student Residency",
      actionsList: [
        "Thorough analysis of individual circumstances and expert consultation for selecting the best residency path",
        "Complete guidance in preparing, translating, certifying, and organizing required documents",
        "Full coordination with migration offices, universities, embassies, and consulates",
        "24/7 support throughout the application and residency process",
        "Conducting workshops and seminars on Turkish residency laws, student rights, and the benefits of living in Turkey"
      ],
      benefitsHeader: "Why Choose Uni Manager for Your Student Residency?",
      benefitsList: [
        "Time and cost savings through expert experience and specialized knowledge",
        "Up-to-date legal and migration advice based on Turkish residency laws",
        "Guarantee of document accuracy and completeness to avoid rejections",
        "Step-by-step support from the decision-making process to final residency approval"
      ],
      finalNote:
        "The professional team at Uni Manager is here to ensure a smooth, precise, and hassle-free experience in obtaining student residency. With us, your academic and life journey in Turkey will begin on a strong, legal foundation."
    },
    Persian: {
      header: "Uni Manager اقامت دانشجویی در ترکیه با", // مقدار پیش‌فرض برای دسکتاپ
      description:
        "Uni Manager با سابقه‌ای درخشان در ارائه خدمات مهاجرتی و تحصیلی، همراه مطمئن شما در مسیر دریافت اقامت دانشجویی در ترکیه است. ما با رویکردی تخصصی، صفر تا صد فرآیند اخذ اقامت را برای شما مدیریت می‌کنیم؛ از مشاوره اولیه و بررسی شرایط، تا تهیه مدارک، هماهنگی‌های حقوقی و پیگیری تا دریافت نهایی کارت اقامت.",
      actionsHeader: "مراحل و خدمات ما برای اخذ اقامت دانشجویی",
      actionsList: [
        "تحلیل دقیق شرایط فردی و ارائه مشاوره تخصصی جهت انتخاب مسیر مناسب اقامتی",
        "راهنمایی کامل در تهیه، ترجمه رسمی، تاییدیه و تنظیم دقیق مدارک",
        "برقراری ارتباط و هماهنگی کامل با اداره مهاجرت، دانشگاه، سفارت و کنسولگری‌ها",
        "پشتیبانی دائمی و آنلاین در تمامی مراحل از ثبت درخواست تا صدور کارت اقامت",
        "برگزاری کارگاه‌های تخصصی آشنایی با قوانین اقامت، حقوق دانشجویی و مزایای زندگی در ترکیه"
      ],
      benefitsHeader: "چرا اقامت دانشجویی با Uni Manager؟",
      benefitsList: [
        "صرفه‌جویی در زمان و هزینه با بهره‌گیری از تجربه و دانش تخصصی",
        "دریافت مشاوره حقوقی و مهاجرتی مطابق با آخرین قوانین اقامتی ترکیه",
        "اطمینان از صحت و تکمیل بودن مدارک ارسالی و پیشگیری از رد درخواست",
        "پشتیبانی قدم‌به‌قدم از لحظه تصمیم‌گیری تا دریافت نهایی اقامت با راهکارهای هوشمند"
      ],
      finalNote:
        "تیم حرفه‌ای Uni Manager در کنار شماست تا تجربه‌ای آرام، دقیق و بدون دغدغه از اخذ اقامت دانشجویی را رقم بزند. با ما، آینده تحصیلی و زندگی شما در ترکیه، از همان ابتدا بر پایه‌ای محکم و قانونی بنا می‌شود."
    },
    Turkish: {
      header: "Türkiye'de Öğrenci İkameti Uni Manager ile",
      description:
        "Uni Manager, göçmenlik ve eğitim hizmetlerinde sahip olduğu uzun yıllara dayanan deneyimiyle, Türkiye'de öğrenci ikameti almanız için güvenilir bir ortak olarak yanınızda. Biz, ikamet başvurusu sürecinin tüm aşamalarını sizin için yönetiyoruz; ilk danışmanlık ve durum analizi, belge hazırlığı, hukuki koordinasyon ve nihai ikamet onayı sürecini içerir.",
      actionsHeader: "Öğrenci İkameti İçin Sağladığımız Hizmetler",
      actionsList: [
        "Bireysel koşullarınızı analiz ederek en uygun ikamet yolunu seçmeniz için uzman danışmanlık hizmeti",
        "Gerekli belgelerin hazırlanması, tercümesi, onaylanması ve düzenlenmesi konusunda kapsamlı rehberlik",
        "Göçmenlik ofisleri, üniversiteler, elçilikler ve konsolosluklarla tam koordinasyon",
        "Başvuru ve ikamet sürecinde 7/24 destek",
        "Türkiye'deki ikamet yasaları, öğrenci hakları ve Türkiye'de yaşamın avantajları hakkında seminerler düzenlemek"
      ],
      benefitsHeader: "Neden Uni Manager ile Öğrenci İkameti?",
      benefitsList: [
        "Uzman deneyimi ve özel bilgiyle zaman ve maliyet tasarrufu sağlama",
        "Türkiye'nin güncel ikamet yasalarına dayalı yasal ve göçmenlik tavsiyeleri",
        "Belge doğruluğu ve eksiksizliği garantisi, başvuruların reddedilmesini engelleme",
        "Karar aşamasından itibaren nihai ikamet onayına kadar adım adım destek"
      ],
      finalNote:
        "Uni Manager profesyonel ekibi, öğrenci ikameti almanızda sorunsuz، doğru و zahmetsiz یک deneyim yaşamanızı sağlamak için yanınızda. Bizimle, Türkiye'deki akademik ve yaşam yolculuğunuz güçlü، yasal bir temele dayanarak başlayacak."
    }
  };

  // در حالت موبایل و زبان فارسی مقدار هدر به صورت JSX با خط‌شکنی تنظیم می‌شود
  const headerText =
    currentLanguage === "Persian" && isMobile ? (
      <>
        اقامت دانشجویی در ترکیه با
        <br />
        Uni Manager

      </>
    ) : (
      content[currentLanguage].header
    );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="student-residency-container">
      {/* انیمیشن */}
      <div className="student-animation-container">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      {/* هدر صفحه */}
      <header className="student-residency-header">
        <h2>{headerText}</h2>
      </header>

      {/* بخش توضیحات */}
      <section className={`student-residency-info ${currentLanguage === "Persian" ? "student-persian-text" : ""}`}>
        <p>{content[currentLanguage].description}</p>

        <h3>{content[currentLanguage].actionsHeader}</h3>
        <ul>
          {content[currentLanguage].actionsList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{content[currentLanguage].benefitsHeader}</h3>
        <ul>
          {content[currentLanguage].benefitsList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <p>{content[currentLanguage].finalNote}</p>
      </section>

      {/* فوتر صفحه */}
      <Footer language={currentLanguage} />
    </div>
  );
};

export default StudentResidency;
