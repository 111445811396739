// src/components/universities/BahcesehirUniversity.js
import React from "react";
import BahcesehirUniversityTop from "./baUni1";
import BahcesehirUniversityMiddle from "./baUni2";
import BahcesehirUniversityBottom from "./baUni3";

const BahcesehirUniversity = (props) => (
  <>
    <BahcesehirUniversityTop lang={props.lang} />
    <BahcesehirUniversityMiddle lang={props.lang} />
    <BahcesehirUniversityBottom lang={props.lang} />
  </>
);

BahcesehirUniversity.defaultProps = {
  lang: "fa",
};

export default BahcesehirUniversity;
