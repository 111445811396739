import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

// Context
import { LanguageProvider, useLanguage } from "./contexts/LanguageContext";

// کامپوننت‌های اصلی
import Home from "./components/Home";
import Universities from "./components/Universities";
import Dormitories from "./components/Dormitories";
import StudentResidency from "./components/StudentResidency";
import HouseRental from "./components/HouseRental";
import About from "./components/About";
import FAQ from "./components/FAQ";

// دانشگاه‌ها
import MedipolUniversity from "./components/universities/MedipolUniversity";
import AydinUniversity from "./components/universities/AydinUniversity";
import BahcesehirUniversity from "./components/universities/BahcesehirUniversity";
import AtlasUniversity from "./components/universities/AtlasUniversity";
import NisantasiUniversity from "./components/universities/NisantasiUniversity";

// تماس با ما
import Contact from "./components/Contact";

// ابزارها
import ExteraToolbar from "./components/ExteraToolbar";

const AppRoutes = () => {
  const { language } = useLanguage();
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/" && <ExteraToolbar />}
      <Routes>
        <Route path="/" element={<Home lang={language} />} />
        <Route path="/universities" element={<Universities lang={language} />} />
        <Route path="/dormitories" element={<Dormitories lang={language} />} />
        <Route path="/student-residency" element={<StudentResidency lang={language} />} />
        <Route path="/house-rental" element={<HouseRental lang={language} />} />
        <Route path="/about" element={<About lang={language} />} />
        <Route path="/faq" element={<FAQ lang={language} />} />
        <Route path="/medipol-university" element={<MedipolUniversity lang={language} />} />
        <Route path="/aydin-university" element={<AydinUniversity lang={language} />} />
        <Route path="/bahcesehir-university" element={<BahcesehirUniversity lang={language} />} />
        <Route path="/atlas-university" element={<AtlasUniversity lang={language} />} />
        <Route path="/nisantasi-university" element={<NisantasiUniversity lang={language} />} />
        <Route path="/contact" element={<Contact lang={language} />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="App">
          <AppRoutes />
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
