import React from "react";
import "./styles/HouseRental.css"; // مطمئن شوید مسیر صحیح است
import Lottie from "react-lottie";
import animationData from "../assets/animation/HouseRental.json"; // فایل انیمیشن مربوط به اجاره خانه
import Footer from "../components/Footer"; // ایمپورت فوتر
import ExteraToolbar from "../components/ExteraToolbar"; // استفاده از تولبار زبان
import { useLanguage } from "../contexts/LanguageContext"; // ایمپورت کانتکست زبان

const HouseRental = () => {
  // دریافت زبان از کانتکست
  const { language } = useLanguage();

  // نگاشت کد زبان به کلید متن‌های مربوطه در content
  const languageMapping = {
    fa: "Persian",
    en: "English",
    tr: "Turkish",
  };

  // در صورتی که زبان تنظیم نشده باشد، به صورت پیش‌فرض انگلیسی در نظر گرفته می‌شود.
  const currentLanguage = languageMapping[language] || "English";

  const content = {
    English: {
      header: "House Rental in Turkey with Uni Manager",
      description:
        "At Uni Manager, we offer a seamless and professional house rental service tailored to your needs. With years of expertise in the Turkish real estate market, we support you through every step — from expert consultation and property selection to contract finalization and post-rental care.",
      actionsHeader: "Our Complete Rental Services",
      actionsList: [
        "Personalized consultation to find the best property based on your budget and lifestyle",
        "Careful assessment of available listings with focus on location, safety, and amenities",
        "Legal support and contract supervision in cooperation with registered legal advisors",
        "24/7 assistance throughout the entire rental process until key delivery",
        "Post-rental services including maintenance, issue tracking, and periodic inspections"
      ],
      benefitsHeader: "Why Choose Uni Manager?",
      benefitsList: [
        "Direct access to premium, verified listings across major Turkish cities",
        "Minimized legal and financial risks through expert contract evaluation",
        "Save time and effort through our streamlined and trusted process",
        "Enjoy a stress-free experience from selection to settlement"
      ],
      finalNote:
        "Let Uni Manager make your house rental in Turkey a smooth and secure journey. With our trusted team by your side, you’ll find a place that truly feels like home."
    },
    Persian: {
      header: "Uni Manager اجاره خانه در ترکیه با ",
      description:
        "Uni Manager با تکیه بر سال‌ها تجربه و شناخت دقیق بازار املاک ترکیه، خدماتی تخصصی، امن و بی‌دردسر در زمینه اجاره ملک به شما ارائه می‌دهد. از مشاوره حرفه‌ای و انتخاب ملک تا عقد قرارداد و پشتیبانی پس از اسکان، تمامی مراحل را با دقت و تعهد همراهی می‌کنیم.",
      actionsHeader: "خدمات ما در فرآیند اجاره ملک",
      actionsList: [
        "مشاوره اختصاصی برای انتخاب بهترین ملک بر اساس موقعیت، بودجه و نیازهای فردی",
        "ارزیابی دقیق گزینه‌های موجود با درنظر گرفتن امکانات، امنیت و دسترسی",
        "تنظیم و بررسی حقوقی قرارداد با همکاری مشاوران حقوقی رسمی",
        "همراهی و پشتیبانی ۲۴ ساعته تا تحویل کلید و استقرار کامل",
        "ارائه خدمات پس از اجاره شامل تعمیرات، پیگیری موارد نگهداری و نظارت‌های دوره‌ای"
      ],
      benefitsHeader: "مزایای انتخاب Uni Manager برای اجاره ملک",
      benefitsList: [
        "دسترسی سریع به فایل‌های ویژه و معتبر در مناطق مختلف ترکیه",
        "کاهش ریسک‌های حقوقی و مالی از طریق بررسی تخصصی قراردادها",
        "صرفه‌جویی در زمان و هزینه با هماهنگی یکپارچه و تیم مجرب",
        "تجربه‌ای مطمئن و بدون استرس از انتخاب تا سکونت نهایی"
      ],
      finalNote:
        "با Uni Manager، اجاره خانه در ترکیه دیگر یک دغدغه نخواهد بود. ما کنار شما هستیم تا با اطمینان، خانه‌ای امن و متناسب با سبک زندگی‌تان را بیابید."
    },
    Turkish: {
      header: "Türkiye'de Ev Kiralama Hizmeti – Uni Manager ile",
      description:
        "Uni Manager, Türkiye emlak piyasasındaki kapsamlı deneyimiyle, ihtiyaçlarınıza özel, güvenilir ve profesyonel bir kiralama süreci sunar. Uzman danışmanlıktan ev seçimine, sözleşme yönetiminden taşınma sonrası hizmetlere kadar tüm adımlarda yanınızdayız.",
      actionsHeader: "Kiralama Sürecinde Verdiğimiz Hizmetler",
      actionsList: [
        "Bütçe ve yaşam tarzınıza uygun en doğru evi belirlemek için kişiye özel danışmanlık",
        "Konum, güvenlik ve olanaklar açısından detaylı ev analizleri",
        "Sözleşme hazırlığı ve hukuki kontrollerde resmi danışmanlarla koordineli çalışma",
        "Anahtar teslimine kadar 7/24 destek ve sürecin aktif takibi",
        "Kira sonrası bakım hizmetleri, sorun çözüm desteği ve düzenli kontroller"
      ],
      benefitsHeader: "Neden Uni Manager'ı Tercih Etmelisiniz?",
      benefitsList: [
        "Türkiye’nin önemli bölgelerinde güvenilir ve kaliteli portföylere doğrudan erişim",
        "Sözleşme analizleriyle minimum yasal ve mali risk",
        "Profesyonel ekip sayesinde zamandan ve masraftan tasarruf",
        "Ev seçiminden yerleşime kadar sorunsuz bir süreç"
      ],
      finalNote:
        "Türkiye’de ev kiralamayı Uni Manager ile güvenli ve kolay bir deneyime dönüştürün. Sizin için en uygun evi bulma yolculuğunuzda yanınızdayız."
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="house-rental-container">
      {/* استفاده از تولبار زبان */}
      <ExteraToolbar />

      {/* انیمیشن */}
      <div className="animation-container">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      {/* هدر صفحه (فقط متن بدون کادر) */}
      <header className="rental-header">
        <h2>{content[currentLanguage].header}</h2>
      </header>

      {/* بخش توضیحات */}
      <section className={`rental-info ${currentLanguage === "Persian" ? "persian-text" : ""}`}>
        <p className="description">{content[currentLanguage].description}</p>

        <h3>{content[currentLanguage].actionsHeader}</h3>
        <ul>
          {content[currentLanguage].actionsList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{content[currentLanguage].benefitsHeader}</h3>
        <ul>
          {content[currentLanguage].benefitsList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <p className="final-note">{content[currentLanguage].finalNote}</p>
      </section>

      {/* فوتر صفحه */}
      <Footer language={language} />
    </div>
  );
};

export default HouseRental;
