import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ExteraToolbar from "../../components/ExteraToolbar";
import posterImage from "../../assets/image/university/atlas/atlas.webp";
import posterImage1 from "../../assets/image/university/atlas/atlas1.webp";
import posterImage2 from "../../assets/image/university/atlas/atlas2.webp";
import posterImage3 from "../../assets/image/university/atlas/atlas3.webp";
import posterImage4 from "../../assets/image/university/atlas/atlas4.webp";
import posterImage5 from "../../assets/image/university/atlas/atlas5.webp";
import posterImage6 from "../../assets/image/university/atlas/atlas6.webp";
import posterImage7 from "../../assets/image/university/atlas/atlas7.webp";
import { 
  FaCity,
  FaGlobe,
  FaStar,
  FaLanguage,
  FaUserGraduate,
  FaUniversity,
  FaCheckCircle,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaSubway,
  FaUsers,
  FaBuilding 
} from "react-icons/fa";
import "../../components/styles/Uni1.css";

// ترجمه متون مربوط به دانشگاه اطلس
const translations = {
  titleUniGlance: {
    fa: "دانشگاه اطلس در یک نگاه",
    en: "Atlas University at a Glance",
    tr: "Atlas Üniversitesi Bir Bakışta"
  },
  infoData: {
    city: { fa: "شهر", en: "City", tr: "Şehir" },
    cityValue: { fa: "استانبول", en: "Istanbul", tr: "İstanbul" },
    faculties: { fa: "تعداد دانشکده", en: "Number of Faculties", tr: "Fakülte Sayısı" },
    facultiesValue: { fa: "۷ دانشکده", en: "7 Faculties", tr: "7 Fakülte" },
    type: { fa: "نوع دانشگاه", en: "Type of University", tr: "Üniversite Türü" },
    typeValue: { fa: "عمومی و ملی", en: "Public & National", tr: "Kamu & Milli" },
    ranking: { fa: "رتبه داخلی", en: "Domestic Ranking", tr: "Ulusal Sıra" },
    rankingValue: { fa: "۲ در میان دانشگاه‌های دولتی", en: "2nd among Public Universities", tr: "Kamu Üniversiteleri arasında 2." },
    accredited: { fa: "تأیید", en: "Accredited", tr: "Onaylı" },
    accreditedValue: { fa: "مورد تأیید وزارت آموزش و پژوهش ترکیه", en: "Accredited by the Turkish Ministry of Education and Research", tr: "Türkiye Milli Eğitim Bakanlığı Onaylı" },
    founded: { fa: "سال تأسیس", en: "Year Founded", tr: "Kuruluş Yılı" },
    foundedValue: { fa: "۱۹۸۰ میلادی", en: "1980", tr: "1980" },
    language: { fa: "زبان تحصیل", en: "Language of Instruction", tr: "Öğretim Dili" },
    languageValue: { fa: "ترکی / انگلیسی", en: "Turkish / English", tr: "Türkçe / İngilizce" },
    admission: { fa: "شرایط پذیرش", en: "Admission Criteria", tr: "Kabul Şartları" },
    admissionValue: { fa: "با آزمون ورودی", en: "Entrance Exam Required", tr: "Giriş Sınavı Gerekli" },
    students: { fa: "تعداد دانشجو", en: "Number of Students", tr: "Öğrenci Sayısı" },
    studentsValue: { fa: "۳۰٬۰۰۰+", en: "30,000+", tr: "30.000+" },
    collaborations: { fa: "همکاری‌های بین‌المللی", en: "International Collaborations", tr: "Uluslararası İşbirlikleri" },
    collaborationsValue: { fa: "۱۲۰+", en: "120+", tr: "120+" }
  },
  galleryTitle: {
    fa: "گالری تصاویر دانشگاه اطلس",
    en: "Gallery of Atlas University Images",
    tr: "Atlas Üniversitesi Resim Galerisi"
  },
  imageAlt: {
    fa: "تصویر",
    en: "Image",
    tr: "Resim"
  },
  details: {
    international: {
      title: {
        fa: "فرصت‌های بین‌المللی",
        en: "International Opportunities",
        tr: "Uluslararası Fırsatlar"
      },
      text: {
        fa: "با بیش از ۱۲۰ قرارداد تبادل دانشجو و استاد با دانشگاه‌های معتبر، فرصت‌های بین‌المللی گسترده‌ای فراهم شده است.",
        en: "With over 120 exchange agreements with renowned universities, extensive international opportunities are provided.",
        tr: "120'dan fazla tanınmış üniversite ile değişim anlaşmaları sayesinde geniş uluslararası fırsatlar sunulmaktadır."
      }
    },
    location: {
      title: {
        fa: "موقعیت جغرافیایی عالی",
        en: "Excellent Geographical Location",
        tr: "Mükemmel Coğrafi Konum"
      },
      text: {
        fa: "واقع در قلب استانبول، با دسترسی آسان به مراکز تجاری و فرهنگی، تجربه‌ای منحصر به فرد ارائه می‌دهد.",
        en: "Located in the heart of Istanbul, offering a unique experience with easy access to commercial and cultural centers.",
        tr: "İstanbul'un kalbinde yer alarak, ticari ve kültürel merkezlere kolay erişim ile benzersiz bir deneyim sunar."
      }
    },
    metro: {
      title: {
        fa: "دسترسی آسان به حمل و نقل عمومی",
        en: "Easy Access to Public Transport",
        tr: "Toplu Taşıma Araçlarına Kolay Erişim"
      },
      text: {
        fa: "دانشگاه در نزدیکی ایستگاه‌های مترو، اتوبوس و تراموا قرار دارد.",
        en: "The university is located near metro, bus, and tram stations.",
        tr: "Üniversite, metro, otobüs ve tramvay duraklarına yakın konumda yer almaktadır."
      }
    },
    iranians: {
      title: {
        fa: "محیط پذیرای دانشجویان بین‌المللی",
        en: "Welcoming Environment for International Students",
        tr: "Uluslararası Öğrencilere Dost Ortam"
      },
      text: {
        fa: "دانشگاه اطلس محیطی دوستانه و پذیرنده برای دانشجویان از سراسر جهان فراهم می‌کند.",
        en: "Atlas University provides a friendly and welcoming environment for students from around the globe.",
        tr: "Atlas Üniversitesi, dünyanın dört bir yanından gelen öğrencilere dost ve kabul edici bir ortam sunar."
      }
    },
    facilities: {
      title: {
        fa: "امکانات مدرن و پیشرفته",
        en: "Modern and Advanced Facilities",
        tr: "Modern ve Gelişmiş Olanaklar"
      },
      text: {
        fa: "کتابخانه‌های پیشرفته، خوابگاه‌های مجهز و امکانات ورزشی و فرهنگی به روز.",
        en: "State-of-the-art libraries, well-equipped dormitories, and up-to-date sports and cultural facilities.",
        tr: "Son teknoloji kütüphaneler, donanımlı yurtlar ve güncel spor ile kültür olanakları."
      }
    }
  },
  admissionSection: {
    title: {
      fa: "شرایط پذیرش و بورسیه",
      en: "Admission Requirements & Scholarships",
      tr: "Kabul Şartları ve Burslar"
    },
    items: [
      {
        title: {
          fa: "شرایط پذیرش دانشگاه اطلس",
          en: "Admission Requirements for Atlas University",
          tr: "Atlas Üniversitesi Kabul Şartları"
        },
        text: {
          fa: "بیشتر رشته‌ها از طریق آزمون ورودی انتخاب می‌شوند. برخی برنامه‌های ویژه ممکن است مصاحبه داشته باشند.",
          en: "Most programs require an entrance exam. Some specialized programs may also include an interview.",
          tr: "Çoğu program giriş sınavı ile seçilmektedir. Bazı özel programlar mülakat içerebilir."
        }
      },
      {
        title: {
          fa: "شرایط پذیرش مقاطع ارشد و دکتری",
          en: "Admission Requirements for Master's and PhD",
          tr: "Yüksek Lisans ve Doktora Kabul Şartları"
        },
        text: {
          fa: "برای مقاطع ارشد و دکتری، ارائه مدرک لیسانس معتبر و نتایج مطلوب آزمون‌های مرتبط الزامی است.",
          en: "For master's and PhD programs, a valid bachelor's degree and satisfactory exam scores are required.",
          tr: "Yüksek lisans ve doktora programları için geçerli bir lisans diploması ve yeterli sınav notları gereklidir."
        }
      },
      {
        title: {
          fa: "مدارک مورد نیاز ثبت‌نام",
          en: "Required Enrollment Documents",
          tr: "Kayıt İçin Gerekli Belgeler"
        },
        text: {
          fa: "مدارک شامل مدرک تحصیلی، ریزنمرات، توصیه‌نامه، پاسپورت و مدارک زبان (انگلیسی یا ترکی) می‌باشد.",
          en: "Documents include educational certificate, transcripts, recommendation letter, passport, and language proficiency certificates (English or Turkish).",
          tr: "Belgeler arasında eğitim belgesi, not dökümü, tavsiye mektubu, pasaport ve dil yeterlilik belgeleri (İngilizce veya Türkçe) yer almaktadır."
        }
      },
      {
        title: {
          fa: "بورسیه‌های دانشگاه اطلس",
          en: "Atlas University Scholarships",
          tr: "Atlas Üniversitesi Bursları"
        },
        text: {
          fa: "بورسیه‌ها شامل تخفیف شهریه، کمک هزینه مسکن و حمایت مالی برای دانشجویان بین‌المللی می‌باشد.",
          en: "Scholarships include tuition discounts, housing allowances, and financial support for international students.",
          tr: "Burslar, uluslararası öğrenciler için öğrenim ücreti indirimi, konaklama yardımı ve mali destek içermektedir."
        }
      },
      {
        title: {
          fa: "شهریه در سال 2025",
          en: "Tuition Fees in 2025",
          tr: "2025 Yılı Öğrenim Ücreti"
        },
        text: {
          fa: "شهریه برای دانشجویان بین‌المللی در سال 2025 بسته به رشته بین ۳۰۰۰ تا ۸۰۰۰ دلار متغیر است.",
          en: "Tuition fees for international students in 2025 vary between $3000 and $8000 depending on the program.",
          tr: "2025 yılında uluslararası öğrenciler için öğrenim ücreti, programa bağlı olarak 3000 ila 8000 dolar arasında değişmektedir."
        }
      }
    ]
  }
};

// کامپوننت‌های فرعی
const InfoCard = ({ title, value, icon }) => (
  <div className="uni-info-card">
    <div className="uni-info-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

const AdmissionCard = ({ title, text }) => (
  <div className="uni-admission-card">
    <h3 className="uni-admission-card-title">{title}</h3>
    <p className="uni-admission-card-text">{text}</p>
  </div>
);

const AtlasUniversityTop = (props) => {
  const lang = props.lang || "fa";
  const containerClass = lang === "fa" ? "uni-container" : "uni-container ltr";
  const detailBlockClass = lang === "fa" ? "uni-detail-icon-block rtl" : "uni-detail-icon-block ltr";

  // آرایه اطلاعات کلی دانشگاه با استفاده از ترجمه
  const infoData = [
    { title: translations.infoData.city[lang], value: translations.infoData.cityValue[lang], icon: <FaCity /> },
    { title: translations.infoData.faculties[lang], value: translations.infoData.facultiesValue[lang], icon: <FaUniversity /> },
    { title: translations.infoData.type[lang], value: translations.infoData.typeValue[lang], icon: <FaGlobe /> },
    { title: translations.infoData.ranking[lang], value: translations.infoData.rankingValue[lang], icon: <FaStar /> },
    { title: translations.infoData.accredited[lang], value: translations.infoData.accreditedValue[lang], icon: <FaCheckCircle /> },
    { title: translations.infoData.founded[lang], value: translations.infoData.foundedValue[lang], icon: <FaCalendarAlt /> },
    { title: translations.infoData.language[lang], value: translations.infoData.languageValue[lang], icon: <FaLanguage /> },
    { title: translations.infoData.admission[lang], value: translations.infoData.admissionValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.students[lang], value: translations.infoData.studentsValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.collaborations[lang], value: translations.infoData.collaborationsValue[lang], icon: <FaGlobe /> }
  ];

  // تنظیمات اسلایدر
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={containerClass}>
      <ExteraToolbar />
      <div className="uni-hero-minimal">
        <img
          src={posterImage}
          alt="دانشگاه اطلس"
          className="uni-hero-image-minimal"
        />
      </div>

      {/* بخش اطلاعات کلی دانشگاه */}
      <section className="uni-info-section">
        <h2 className="uni-info-title">{translations.titleUniGlance[lang]}</h2>
        <div className="uni-info-grid">
          {infoData.map((item, index) => (
            <InfoCard key={index} {...item} />
          ))}
        </div>
      </section>

      {/* بخش گالری تصاویر */}
      <section className="uni-gallery-section">
        <h2 className="uni-gallery-title">{translations.galleryTitle[lang]}</h2>
        <Slider {...sliderSettings}>
          <div className="uni-gallery-card">
            <img src={posterImage1} alt={`${translations.imageAlt[lang]} 1`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage2} alt={`${translations.imageAlt[lang]} 2`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage3} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage4} alt={`${translations.imageAlt[lang]} 4`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage5} alt={`${translations.imageAlt[lang]} 5`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage6} alt={`${translations.imageAlt[lang]} 6`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage7} alt={`${translations.imageAlt[lang]} 7`} />
          </div>
        </Slider>
      </section>

      {/* بخش اطلاعات تکمیلی */}
      <section className="uni-details-section">
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaGlobe /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.international.title[lang]}</h3>
            <p>{translations.details.international.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaMapMarkerAlt /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.location.title[lang]}</h3>
            <p>{translations.details.location.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaSubway /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.metro.title[lang]}</h3>
            <p>{translations.details.metro.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaUsers /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.iranians.title[lang]}</h3>
            <p>{translations.details.iranians.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaBuilding /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.facilities.title[lang]}</h3>
            <p>{translations.details.facilities.text[lang]}</p>
          </div>
        </div>
      </section>

      {/* بخش شرایط پذیرش */}
      <section className="uni-admission-section">
        <h2 className="uni-admission-title">{translations.admissionSection.title[lang]}</h2>
        <div className="uni-admission-grid">
          {translations.admissionSection.items.map((item, index) => (
            <AdmissionCard
              key={index}
              title={item.title[lang]}
              text={item.text[lang]}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default AtlasUniversityTop;
