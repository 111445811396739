// src/components/Universities.js
import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/animation/university.json";

// استایل اصلی صفحه یونیورسیتیز
import "../components/styles/Universities.css";

// ایمپورت تصاویر مورد نیاز
import medipolImage from "../assets/image/university/main-uni/1.webp";
import BahcesehirImage from "../assets/image/university/main-uni/2.png";
import AtlasImage from "../assets/image/university/main-uni/AtlasUniversity.png";
import AydinImage from "../assets/image/university/main-uni/AydinImage.jpg";
import NishantashiImage from "../assets/image/university/main-uni/NishantashiImage.webp";

// ایمپورت کامپوننت تولبار و فوتر
import ExteraToolbar from "../components/ExteraToolbar";
import Footer from "../components/Footer";

// استفاده از context زبان
import { useLanguage } from "../contexts/LanguageContext";

// متغیر جهانی برای ترجمه دکمه "جزئیات بیشتر"
const detailsText = {
  en: "More Details",
  fa: "مشاهده جزئیات",
  tr: "Daha Fazla Bilgi",
};

// داده‌های دانشگاه‌های غیر دولتی استانبول
const universities = [
  {
    name: { en: "Medipol University", fa: "دانشگاه مدیپل", tr: "Medipol Üniversitesi" },
    image: medipolImage,
    location: { en: "Istanbul, Turkey", fa: "استانبول، ترکیه", tr: "İstanbul, Türkiye" },
    ranking: { en: "Global Rank: #451", fa: "رتبه جهانی: ۴۵۱", tr: "Dünya Sıralaması: #451" },
    tuition: "$19,500 / Year",
    description: {
      en: "A leading university recognized globally for excellence in research.",
      fa: "یک دانشگاه برجسته با شهرت جهانی در زمینه تحقیقات برتر.",
      tr: "Dünya çapında araştırma mükemmeliyeti ile tanınan lider bir üniversite.",
    },
    detailsPage: "/medipol-university",
  },
  {
    name: { en: "Bahçeşehir University", fa: "دانشگاه باغچه‌شهیر", tr: "Bahçeşehir Üniversitesi" },
    image: BahcesehirImage,
    location: { en: "Istanbul, Turkey", fa: "استانبول، ترکیه", tr: "İstanbul, Türkiye" },
    ranking: { en: "Global Rank: #601", fa: "رتبه جهانی: ۶۰۱", tr: "Dünya Sıralaması: #601" },
    tuition: "$15,000 / Year",
    description: {
      en: "Famous for its international programs and urban campus lifestyle.",
      fa: "مشهور به برنامه‌های بین‌المللی و سبک زندگی پردیس شهری.",
      tr: "Uluslararası programları ve şehir kampüsü yaşam tarzı ile ünlüdür.",
    },
    detailsPage: "/bahcesehir-university",
  },
  {
    name: { en: "Istanbul Atlas University", fa: "دانشگاه اطلس استانبول", tr: "İstanbul Atlas Üniversitesi" },
    image: AtlasImage,
    location: { en: "Istanbul, Turkey", fa: "استانبول، ترکیه", tr: "İstanbul, Türkiye" },
    ranking: { en: "Global Rank: #1001", fa: "رتبه جهانی: ۱۰۰۱", tr: "Dünya Sıralaması: #1001" },
    tuition: "$6,000 - $20,000 / Year",
    description: {
      en: "A modern university emphasizing medical and engineering excellence.",
      fa: "یک دانشگاه مدرن با تأکید بر برتری در آموزش پزشکی و مهندسی.",
      tr: "Tıp ve mühendislik mükemmeliyetine vurgu yapan modern bir üniversite.",
    },
    detailsPage: "/atlas-university",
  },
  {
    name: { en: "Istanbul Aydın University", fa: "دانشگاه آیدین استانبول", tr: "İstanbul Aydın Üniversitesi" },
    image: AydinImage,
    location: { en: "Istanbul, Turkey", fa: "استانبول، ترکیه", tr: "İstanbul, Türkiye" },
    ranking: { en: "Global Rank: #751", fa: "رتبه جهانی: ۷۵۱", tr: "Dünya Sıralaması: #751" },
    tuition: "$9,000 / Year",
    description: {
      en: "A modern institution focusing on applied sciences and international cooperation.",
      fa: "یک موسسه مدرن با تمرکز بر علوم کاربردی و همکاری‌های بین‌المللی.",
      tr: "Uygulamalı bilimler ve uluslararası işbirliklerine odaklanan modern bir kurum.",
    },
    detailsPage: "/aydin-university",
  },
  {
    name: { en: "Istanbul Nişantaşı University", fa: "دانشگاه نیشانتاشی استانبول", tr: "İstanbul Nişantaşı Üniversitesi" },
    image: NishantashiImage,
    location: { en: "Istanbul, Turkey", fa: "استانبول، ترکیه", tr: "İstanbul, Türkiye" },
    ranking: { en: "Global Rank: 2001+", fa: "رتبه جهانی: بالای ۲۰۰۰", tr: "Dünya Sıralaması: 2000+" },
    tuition: "$3,250 - $25,000 / Year",
    description: {
      en: "A vibrant university offering diverse programs with a focus on innovation and international ties.",
      fa: "یک دانشگاه پویا با برنامه‌های متنوع که بر نوآوری و ارتباطات بین‌المللی تأکید دارد.",
      tr: "Yenilikçilik ve uluslararası bağlantılara odaklanan, çeşitli programlar sunan dinamik bir üniversite.",
    },
    detailsPage: "/nisantasi-university",
  }
]

const Universities = () => {
  const { language } = useLanguage(); // language will be "fa", "tr", or "en"

  // تنظیمات انیمیشن
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {/* تولبار از طریق context */}
      <ExteraToolbar />

      {/* فاصله از Toolbar */}
      <div className="ozcup-universities-container">
        {/* انیمیشن محلی */}
        <div className="ozcup-animation-container">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>

        {/* هدر دانشگاه‌ها */}
        <h1 className="ozcup-main-header">
          {language === "en"
            ? "Top Private Universities in Istanbul"
            : language === "fa"
            ? "دانشگاه‌های غیر دولتی برتر استانبول"
            : "İstanbul'daki En İyi Vakıf Üniversiteleri"}
        </h1>

        {/* نمایش دانشگاه‌ها */}
        <div className="ozcup-universities-grid">
          {universities.map((university, index) => (
            <div key={index} className="ozcup-university-card">
              <img
                src={university.image}
                alt={university.name[language]}
                className="ozcup-university-image"
              />
              <div
                className="ozcup-university-info"
                style={{
                  fontFamily:
                    language === "fa"
                      ? "IRANSans"
                      : language === "tr"
                      ? "Roboto"
                      : "Arial",
                }}
              >
                <h3>{university.name[language]}</h3>
                <p>
                  <strong>{university.location[language]}</strong>
                </p>
                <p>{university.ranking[language]}</p>
                <p>
                  <strong>
                    {language === "en"
                      ? "Tuition: "
                      : language === "fa"
                      ? "شهریه: "
                      : "Ücret: "}
                    {university.tuition}
                  </strong>
                </p>
                <p>{university.description[language]}</p>
                <Link
                  to={university.detailsPage}
                  className="ozcup-details-button"
                >
                  {detailsText[language]}
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* فوتر */}
        <Footer language={language} />
      </div>
    </>
  );
};

export default Universities;
