// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { useLanguage } from "../contexts/LanguageContext";
import "../components/styles/Footer.css";

const Footer = () => {
  const { language } = useLanguage();

  const text = {
    English: {
      aboutTitle: "Uni Manager",
      aboutText:
        "Connecting students with top universities and providing comprehensive support for a smooth academic journey.",
      linksTitle: "Links",
      links: [
        { label: "About Us", to: "/about" },
        { label: "Universities", to: "/universities" },
        { label: "FAQ", to: "/faq" },
      ],
      followTitle: "Follow Us",
      copyright: `© ${new Date().getFullYear()} Uni Manager. All rights reserved.`,
    },
    fa: {
      aboutTitle: "یونی منیجر",
      aboutText:
        "اتصال دانشجویان به دانشگاه‌های برتر و ارائه پشتیبانی جامع برای یک مسیر تحصیلی بدون مشکل.",
      linksTitle: "لینک‌ها",
      links: [
        { label: "درباره ما", to: "/about" },
        { label: "دانشگاه‌ها", to: "/universities" },
        { label: "سوالات متداول", to: "/faq" },
      ],
      followTitle: "ما را دنبال کنید",
      copyright: `© ${new Date().getFullYear()} یونی منیجر. تمامی حقوق محفوظ است.`,
    },
    tr: {
      aboutTitle: "Uni Manager",
      aboutText:
        "Öğrencileri en iyi üniversitelerle buluşturup, sorunsuz bir akademik yolculuk için kapsamlı destek sağlıyoruz.",
      linksTitle: "Bağlantılar",
      links: [
        { label: "Hakkımızda", to: "/about" },
        { label: "Üniversiteler", to: "/universities" },
        { label: "SSS", to: "/faq" },
      ],
      followTitle: "Bizi Takip Edin",
      copyright: `© ${new Date().getFullYear()} Uni Manager. Tüm hakları saklıdır.`,
    },
  };

  const currentText = text[language] || text["English"];

  return (
    <footer className="uni-footer">
      <div className="uni-footer-container">
        <div className="uni-footer-card">
          <h3>{currentText.aboutTitle}</h3>
          <p>{currentText.aboutText}</p>
        </div>
        <div className="uni-footer-card">
          <h3>{currentText.linksTitle}</h3>
          <ul>
            {currentText.links.map((link, index) => (
              <li key={index}>
                <Link to={link.to}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="uni-footer-card">
          <h3>{currentText.followTitle}</h3>
          <div className="uni-social-icons">
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <FaTwitter />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="uni-footer-bottom">
        <span
          style={
            language === "fa"
              ? { direction: "rtl", unicodeBidi: "embed" }
              : {}
          }
        >
          {currentText.copyright}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
