// src/components/universities/medipolUni3.js
import React, { useState } from "react";
import "../../components/styles/Uni.css";
import "../../components/styles/Uni3.css";
import Footer from "../Footer"; // Import the Footer component

// شی ترجمه برای بخش پایینی دانشگاه مدیپل
const translations = {
  rankingTitle: {
    fa: "رتبه‌بندی دانشگاه‌های مدیپل و مقایسه",
    en: "Medipol University Rankings and Comparison",
    tr: "Medipol Üniversitesi Sıralamaları ve Karşılaştırma"
  },
  tableHeaders: {
    university: { fa: "دانشگاه", en: "University", tr: "Üniversite" },
    ranking: { fa: "رتبه", en: "Ranking", tr: "Sıra" },
    score: { fa: "امتیاز", en: "Score", tr: "Puan" }
  },
  calculatorTitle: {
    fa: "ماشین حساب شهریه دانشگاه مدیپل",
    en: "Medipol University Tuition Fee Calculator",
    tr: "Medipol Üniversitesi Öğrenim Ücreti Hesaplayıcı"
  },
  selectFieldLabel: {
    fa: "رشته تحصیلی خود را انتخاب کنید:",
    en: "Select your field of study:",
    tr: "Çalışma alanınızı seçin:"
  },
  options: {
    medical: { fa: "پزشکی", en: "Medical", tr: "Tıp" },
    engineering: { fa: "مهندسی", en: "Engineering", tr: "Mühendislik" },
    business: { fa: "مدیریت و تجارت", en: "Business", tr: "İşletme" },
    other: { fa: "سایر", en: "Other", tr: "Diğer" }
  },
  calculateButton: {
    fa: "محاسبه شهریه",
    en: "Calculate Tuition",
    tr: "Öğrenim Ücreti Hesapla"
  },
  resultTitle: {
    fa: "نتیجه محاسبه شهریه",
    en: "Tuition Calculation Result",
    tr: "Öğrenim Ücreti Hesaplama Sonucu"
  },
  tuitionInUSD: {
    fa: "شهریه به دلار:",
    en: "Tuition in USD:",
    tr: "USD cinsinden öğrenim ücreti:"
  },
  inIRR: {
    fa: "به ریال:",
    en: "In IRR:",
    tr: "IRR olarak:"
  },
  inTRY: {
    fa: "به لیر:",
    en: "In TRY:",
    tr: "TRY olarak:"
  },
  inAED: {
    fa: "به دینار دبی:",
    en: "In AED:",
    tr: "AED olarak:"
  },
  mapTitle: {
    fa: "نقشه دانشگاه مدیپل",
    en: "Medipol University Map",
    tr: "Medipol Üniversitesi Haritası"
  }
};

// کامپوننت ماشین حساب شهریه دانشگاه مدیپل
const TuitionCalculator = (props) => {
  const lang = props.lang || "fa";
  const [field, setField] = useState("");
  const [result, setResult] = useState(null);
  const [converted, setConverted] = useState(null);

  // نرخ تبدیل ارز (فرضی)
  const conversionRates = {
    IRR: 100000, // 1 دلار = 100,000 تومان
    TRY: 2700,   // 1 دلار = 2,700 لیر
    AED: 28000   // 1 دلار = 28,000 درهم
  };

  const calculateFee = (e) => {
    e.preventDefault();
    let feeUSD = 0;
    if (field === "medical") feeUSD = 30000;
    else if (field === "engineering") feeUSD = 10000;
    else if (field === "business") feeUSD = 9500;
    else feeUSD = 6500;
    setResult(feeUSD);
    setConverted({
      IRR: feeUSD * conversionRates.IRR,
      TRY: feeUSD * conversionRates.TRY,
      AED: feeUSD * conversionRates.AED
    });
  };

  return (
    <section className="uni-calculator-section">
      <h2 className="uni-calculator-title">{translations.calculatorTitle[lang]}</h2>
      <form className="uni-calculator-form" onSubmit={calculateFee}>
        <label htmlFor="field-select">{translations.selectFieldLabel[lang]}</label>
        <select id="field-select" value={field} onChange={(e) => setField(e.target.value)}>
          <option value="">{`-- ${translations.options.other[lang]} --`}</option>
          <option value="medical">{translations.options.medical[lang]}</option>
          <option value="engineering">{translations.options.engineering[lang]}</option>
          <option value="business">{translations.options.business[lang]}</option>
          <option value="other">{translations.options.other[lang]}</option>
        </select>
        <button type="submit">{translations.calculateButton[lang]}</button>
      </form>
      {result && converted && (
        <div className="conversion-card">
          <h3>{translations.resultTitle[lang]}</h3>
          <p>{translations.tuitionInUSD[lang]} ${result.toLocaleString()}</p>
          <hr />
          <p>
            {translations.inIRR[lang]} {converted.IRR.toLocaleString()} 
            (1 USD = {conversionRates.IRR.toLocaleString()} IRR)
          </p>
          <p>
            {translations.inTRY[lang]} {converted.TRY.toLocaleString()} 
            (1 USD = {conversionRates.TRY} TRY)
          </p>
          <p>
            {translations.inAED[lang]} {converted.AED.toLocaleString()} 
            (1 USD = {conversionRates.AED} AED)
          </p>
        </div>
      )}
    </section>
  );
};

// داده‌های رتبه‌بندی دانشگاه مدیپل و برخی دانشگاه‌های همکار (مثال فرضی)
const rankingData = [
  {
    university: { 
      fa: "دانشگاه مدیپل", 
      en: "Medipol University", 
      tr: "Medipol Üniversitesi" 
    },
    ranking: { 
      fa: "۱ در بین دانشگاه‌های خصوصی استانبول", 
      en: "1st among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 1." 
    },
    score: { 
      fa: "9.0/10", 
      en: "9.0/10", 
      tr: "9.0/10" 
    }
  },
  {
    university: { 
      fa: "دانشگاه مستک", 
      en: "Istanbul Medipol (MSTK)", 
      tr: "İstanbul Medipol (MSTK)" 
    },
    ranking: { 
      fa: "۲ در بین دانشگاه‌های خصوصی استانبول", 
      en: "2nd among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 2." 
    },
    score: { 
      fa: "8.7/10", 
      en: "8.7/10", 
      tr: "8.7/10" 
    }
  },
  {
    university: { 
      fa: "دانشگاه یدیدپه", 
      en: "Yeditepe University", 
      tr: "Yeditepe Üniversitesi" 
    },
    ranking: { 
      fa: "۳ در بین دانشگاه‌های خصوصی استانبول", 
      en: "3rd among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 3." 
    },
    score: { 
      fa: "8.3/10", 
      en: "8.3/10", 
      tr: "8.3/10" 
    }
  }
];

// کامپوننت اصلی بخش پایین صفحه دانشگاه مدیپل
const MedipolUniversityBottom = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className="uni-container">
      {/* بخش رتبه‌بندی */}
      <section className="uni-ranking-section">
        <h2 className="uni-ranking-title">{translations.rankingTitle[lang]}</h2>
        <table className="uni-ranking-table">
          <thead>
            <tr>
              <th>{translations.tableHeaders.university[lang]}</th>
              <th>{translations.tableHeaders.ranking[lang]}</th>
              <th>{translations.tableHeaders.score[lang]}</th>
            </tr>
          </thead>
          <tbody>
            {rankingData.map((item, index) => (
              <tr key={index}>
                <td>{item.university[lang]}</td>
                <td>{item.ranking[lang]}</td>
                <td>{item.score[lang]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* ماشین حساب شهریه */}
      <TuitionCalculator lang={lang} />

      {/* نقشه دانشگاه */}
      <section className="uni-map-section">
        <h2 className="uni-map-title">{translations.mapTitle[lang]}</h2>
        <div className="uni-map-container">
          <iframe
            title="Medipol University Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.4586717167284!2d28.984345515301042!3d41.05878437929605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabe3471f09b97%3A0x73e92a98ed545f56!2zTWVkaXBvbCBVbml2ZXJzaXR5!5e0!3m2!1sen!2sfa!4v1612145160193!5m2!1sen!2sfa"
            width="100%"
            height="400"
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </section>
            {/* افزودن فوتر */}
            <Footer language={lang} />
    </div>
  );
};

export default MedipolUniversityBottom;
