import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ExteraToolbar from "../../components/ExteraToolbar";
import posterImage from "../../assets/image/university/nisantasi/nis.webp";
import posterImage1 from "../../assets/image/university/nisantasi/nis1.webp";
import posterImage2 from "../../assets/image/university/nisantasi/nis2.webp";
import posterImage3 from "../../assets/image/university/nisantasi/nis3.webp";
import posterImage4 from "../../assets/image/university/nisantasi/nis4.webp";
import posterImage5 from "../../assets/image/university/nisantasi/nis5.webp";

import { 
  FaCity,
  FaGlobe,
  FaStar,
  FaLanguage,
  FaUserGraduate,
  FaUniversity,
  FaCheckCircle,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaSubway,
  FaUsers,
  FaBuilding 
} from "react-icons/fa";
import "../../components/styles/Uni1.css";

// ترجمه متون مربوط به دانشگاه نیشانتاشی
const translations = {
  titleUniGlance: {
    fa: "دانشگاه نیشانتاشی در یک نگاه",
    en: "Nişantaşı University at a Glance",
    tr: "Nişantaşı Üniversitesi Bir Bakışta"
  },
  infoData: {
    city: { fa: "شهر", en: "City", tr: "Şehir" },
    cityValue: { fa: "استانبول", en: "Istanbul", tr: "İstanbul" },
    faculties: { fa: "تعداد دانشکده", en: "Number of Faculties", tr: "Fakülte Sayısı" },
    facultiesValue: { fa: "۵ دانشکده", en: "5 Faculties", tr: "5 Fakülte" },
    type: { fa: "نوع دانشگاه", en: "Type of University", tr: "Üniversite Türü" },
    typeValue: { fa: "خصوصی", en: "Private", tr: "Özel" },
    ranking: { fa: "رتبه داخلی", en: "Domestic Ranking", tr: "Ulusal Sıra" },
    rankingValue: { fa: "۱ در میان دانشگاه‌های خصوصی", en: "1st among Private Universities", tr: "Özel Üniversiteler arasında 1." },
    accredited: { fa: "تأیید", en: "Accredited", tr: "Onaylı" },
    accreditedValue: { fa: "مورد تأیید وزارت آموزش و پژوهش ترکیه", en: "Accredited by the Turkish Ministry of Education and Research", tr: "Türkiye Milli Eğitim Bakanlığı Onaylı" },
    founded: { fa: "سال تأسیس", en: "Year Founded", tr: "Kuruluş Yılı" },
    foundedValue: { fa: "۲۰۰۵ میلادی", en: "2005", tr: "2005" },
    language: { fa: "زبان تحصیل", en: "Language of Instruction", tr: "Öğretim Dili" },
    languageValue: { fa: "انگلیسی / ترکی", en: "English / Turkish", tr: "İngilizce / Türkçe" },
    admission: { fa: "شرایط پذیرش", en: "Admission Criteria", tr: "Kabul Şartları" },
    admissionValue: { fa: "با مصاحبه و آزمون ورودی", en: "Interview & Entrance Exam", tr: "Mülakat ve Giriş Sınavı" },
    students: { fa: "تعداد دانشجو", en: "Number of Students", tr: "Öğrenci Sayısı" },
    studentsValue: { fa: "۱۵٬۰۰۰+", en: "15,000+", tr: "15.000+" },
    collaborations: { fa: "همکاری‌های بین‌المللی", en: "International Collaborations", tr: "Uluslararası İşbirlikleri" },
    collaborationsValue: { fa: "۸۰+", en: "80+", tr: "80+" }
  },
  galleryTitle: {
    fa: "گالری تصاویر دانشگاه نیشانتاشی",
    en: "Gallery of Nişantaşı University Images",
    tr: "Nişantaşı Üniversitesi Resim Galerisi"
  },
  imageAlt: {
    fa: "تصویر",
    en: "Image",
    tr: "Resim"
  },
  details: {
    international: {
      title: {
        fa: "فرصت‌های بین‌المللی",
        en: "International Opportunities",
        tr: "Uluslararası Fırsatlar"
      },
      text: {
        fa: "با بیش از ۸۰ قرارداد تبادل دانشجو، فرصت‌های بین‌المللی متنوعی ارائه می‌شود.",
        en: "With over 80 exchange agreements, diverse international opportunities are available.",
        tr: "80'in üzerinde değişim anlaşması ile çeşitli uluslararası fırsatlar sunulmaktadır."
      }
    },
    location: {
      title: {
        fa: "موقعیت استراتژیک در نیشانتاشی",
        en: "Strategic Location in Nişantaşı",
        tr: "Nişantaşı'nda Stratejik Konum"
      },
      text: {
        fa: "در قلب نیشانتاشی، با دسترسی آسان به مراکز تجاری و فرهنگی، تجربه‌ای منحصر به فرد فراهم می‌کند.",
        en: "Located in the heart of Nişantaşı, offering a unique experience with easy access to commercial and cultural centers.",
        tr: "Nişantaşı'nın kalbinde yer alarak, ticari ve kültürel merkezlere kolay erişim sunar."
      }
    },
    metro: {
      title: {
        fa: "دسترسی آسان به حمل و نقل عمومی",
        en: "Easy Access to Public Transport",
        tr: "Toplu Taşıma Araçlarına Kolay Erişim"
      },
      text: {
        fa: "دانشگاه در نزدیکی ایستگاه‌های مترو، اتوبوس و تراموا قرار دارد.",
        en: "The university is located near metro, bus, and tram stations.",
        tr: "Üniversite, metro, otobüs ve tramvay duraklarına yakın konumda."
      }
    },
    iranians: {
      title: {
        fa: "محیط پذیرای دانشجویان بین‌المللی",
        en: "Welcoming Environment for International Students",
        tr: "Uluslararası Öğrencilere Dost Ortam"
      },
      text: {
        fa: "دانشگاه نیشانتاشی محیطی دوستانه و پذیرنده برای دانشجویان از سراسر جهان است.",
        en: "Nişantaşı University provides a friendly and welcoming environment for students worldwide.",
        tr: "Nişantaşı Üniversitesi, dünyanın dört bir yanından gelen öğrencilere dostane bir ortam sunar."
      }
    },
    facilities: {
      title: {
        fa: "امکانات مدرن و لوکس",
        en: "Modern and Luxurious Facilities",
        tr: "Modern ve Lüks Olanaklar"
      },
      text: {
        fa: "کتابخانه‌های به‌روز، خوابگاه‌های مجهز و امکانات ورزشی و فرهنگی مدرن.",
        en: "State-of-the-art libraries, well-equipped dormitories, and modern sports & cultural facilities.",
        tr: "Son teknoloji kütüphaneler, donanımlı yurtlar ve modern spor ile kültür olanakları."
      }
    }
  },
  admissionSection: {
    title: {
      fa: "شرایط پذیرش و بورسیه",
      en: "Admission Requirements & Scholarships",
      tr: "Kabul Şartları ve Burslar"
    },
    items: [
      {
        title: {
          fa: "شرایط پذیرش دانشگاه نیشانتاشی",
          en: "Admission Requirements for Nişantaşı University",
          tr: "Nişantaşı Üniversitesi Kabul Şartları"
        },
        text: {
          fa: "برخی برنامه‌ها از طریق آزمون و مصاحبه انتخاب می‌شوند؛ شرایط ویژه هر رشته متفاوت است.",
          en: "Some programs are selected through both exam and interview; specific criteria vary by department.",
          tr: "Bazı programlar sınav ve mülakat ile seçilmektedir; her bölüm için şartlar farklılık gösterebilir."
        }
      },
      {
        title: {
          fa: "شرایط پذیرش مقاطع تحصیلات تکمیلی",
          en: "Admission for Graduate Programs",
          tr: "Yüksek Lisans Kabul Şartları"
        },
        text: {
          fa: "برای مقاطع تکمیلی، ارائه مدرک لیسانس معتبر و نتایج مطلوب آزمون‌ها الزامی است.",
          en: "For graduate programs, a valid bachelor's degree and satisfactory exam scores are required.",
          tr: "Yüksek lisans programları için geçerli bir lisans diploması ve yeterli sınav notları gereklidir."
        }
      },
      {
        title: {
          fa: "مدارک مورد نیاز ثبت‌نام",
          en: "Required Enrollment Documents",
          tr: "Kayıt İçin Gerekli Belgeler"
        },
        text: {
          fa: "مدارک شامل مدرک تحصیلی، ریزنمرات، توصیه‌نامه، پاسپورت و مدارک زبان (انگلیسی یا ترکی) می‌باشد.",
          en: "Documents include educational certificate, transcripts, recommendation letter, passport, and language certificates (English or Turkish).",
          tr: "Belgeler; eğitim belgesi, not dökümü, tavsiye mektubu, pasaport ve dil belgelerini (İngilizce veya Türkçe) içerir."
        }
      },
      {
        title: {
          fa: "بورسیه‌های دانشگاه نیشانتاشی",
          en: "Nişantaşı University Scholarships",
          tr: "Nişantaşı Üniversitesi Bursları"
        },
        text: {
          fa: "بورسیه‌ها شامل تخفیف شهریه و حمایت‌های مالی ویژه برای دانشجویان بین‌المللی می‌باشد.",
          en: "Scholarships include tuition discounts and special financial support for international students.",
          tr: "Burslar, uluslararası öğrenciler için öğrenim ücreti indirimi ve özel mali destek içerir."
        }
      },
      {
        title: {
          fa: "شهریه در سال 2025",
          en: "Tuition Fees in 2025",
          tr: "2025 Yılı Öğrenim Ücreti"
        },
        text: {
          fa: "شهریه برای دانشجویان بین‌المللی در سال 2025 بسته به رشته از ۴۰۰۰ تا ۹۰۰۰ دلار متغیر است.",
          en: "Tuition fees for international students in 2025 range from $4000 to $9000 depending on the program.",
          tr: "2025 yılında uluslararası öğrenciler için öğrenim ücreti, programa bağlı olarak 4000 ila 9000 dolar arasında değişmektedir."
        }
      }
    ]
  }
};

// کامپوننت‌های فرعی
const InfoCard = ({ title, value, icon }) => (
  <div className="uni-info-card">
    <div className="uni-info-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

const AdmissionCard = ({ title, text }) => (
  <div className="uni-admission-card">
    <h3 className="uni-admission-card-title">{title}</h3>
    <p className="uni-admission-card-text">{text}</p>
  </div>
);

const NisantasiUniversityTop = (props) => {
  const lang = props.lang || "fa";
  const containerClass = lang === "fa" ? "uni-container" : "uni-container ltr";
  const detailBlockClass = lang === "fa" ? "uni-detail-icon-block rtl" : "uni-detail-icon-block ltr";

  // آرایه اطلاعات کلی دانشگاه با استفاده از ترجمه
  const infoData = [
    { title: translations.infoData.city[lang], value: translations.infoData.cityValue[lang], icon: <FaCity /> },
    { title: translations.infoData.faculties[lang], value: translations.infoData.facultiesValue[lang], icon: <FaUniversity /> },
    { title: translations.infoData.type[lang], value: translations.infoData.typeValue[lang], icon: <FaGlobe /> },
    { title: translations.infoData.ranking[lang], value: translations.infoData.rankingValue[lang], icon: <FaStar /> },
    { title: translations.infoData.accredited[lang], value: translations.infoData.accreditedValue[lang], icon: <FaCheckCircle /> },
    { title: translations.infoData.founded[lang], value: translations.infoData.foundedValue[lang], icon: <FaCalendarAlt /> },
    { title: translations.infoData.language[lang], value: translations.infoData.languageValue[lang], icon: <FaLanguage /> },
    { title: translations.infoData.admission[lang], value: translations.infoData.admissionValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.students[lang], value: translations.infoData.studentsValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.collaborations[lang], value: translations.infoData.collaborationsValue[lang], icon: <FaGlobe /> }
  ];

  // تنظیمات اسلایدر
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={containerClass}>
      <ExteraToolbar />
      <div className="uni-hero-minimal">
        <img
          src={posterImage}
          alt="دانشگاه نیشانتاشی"
          className="uni-hero-image-minimal"
        />
      </div>

      {/* بخش اطلاعات کلی دانشگاه */}
      <section className="uni-info-section">
        <h2 className="uni-info-title">{translations.titleUniGlance[lang]}</h2>
        <div className="uni-info-grid">
          {infoData.map((item, index) => (
            <InfoCard key={index} {...item} />
          ))}
        </div>
      </section>

      {/* بخش گالری تصاویر */}
      <section className="uni-gallery-section">
        <h2 className="uni-gallery-title">{translations.galleryTitle[lang]}</h2>
        <Slider {...sliderSettings}>
          <div className="uni-gallery-card">
            <img src={posterImage1} alt={`${translations.imageAlt[lang]} 1`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage2} alt={`${translations.imageAlt[lang]} 2`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage3} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage4} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage5} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
        </Slider>
      </section>

      {/* بخش اطلاعات تکمیلی */}
      <section className="uni-details-section">
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaGlobe /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.international.title[lang]}</h3>
            <p>{translations.details.international.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaMapMarkerAlt /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.location.title[lang]}</h3>
            <p>{translations.details.location.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaSubway /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.metro.title[lang]}</h3>
            <p>{translations.details.metro.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaUsers /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.iranians.title[lang]}</h3>
            <p>{translations.details.iranians.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaBuilding /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.facilities.title[lang]}</h3>
            <p>{translations.details.facilities.text[lang]}</p>
          </div>
        </div>
      </section>

      {/* بخش شرایط پذیرش */}
      <section className="uni-admission-section">
        <h2 className="uni-admission-title">{translations.admissionSection.title[lang]}</h2>
        <div className="uni-admission-grid">
          {translations.admissionSection.items.map((item, index) => (
            <AdmissionCard
              key={index}
              title={item.title[lang]}
              text={item.text[lang]}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default NisantasiUniversityTop;
