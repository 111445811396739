import React, { useState } from "react";
import "../../components/styles/Uni.css";
import { FaCheckCircle } from "react-icons/fa";
import "../../components/styles/Uni2.css";
import uniManagerImage from "../../assets/image/uni-card/UniManager.png";

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const convertPersianDigitsToEnglish = (str) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let result = str;
  persianDigits.forEach((pd, index) => {
    const reg = new RegExp(pd, "g");
    result = result.replace(reg, englishDigits[index]);
  });
  return result;
};

// نگاشت تاریخ‌های جلالی به میلادی برای داده‌های ثابت
const jalaliToGregorianMapping = {
  "۱۴۰۲/۱۰/۱۰": "2023/12/01",
  "۱۴۰۲/۱۰/۲۰": "2023/12/11",
  "۱۴۰۲/۱۰/۳۰": "2023/12/21",
  "۱۴۰۲/۱۱/۱۰": "2024/01/01"
};


// تابع تبدیل تاریخ بر اساس زبان
const formatDate = (dateStr, lang) => {
  if (lang === "fa") return dateStr;
  const engDate = convertPersianDigitsToEnglish(dateStr);
  if (jalaliToGregorianMapping[engDate]) {
    return jalaliToGregorianMapping[engDate];
  }
  return engDate;
};

const translations = {
  facultiesTitle: {
    fa: "دانشکده‌های دانشگاه آیدین",
    en: "Aydin University Faculties",
    tr: "Aydın Üniversitesi Fakülteleri"
  },
  tuitionTitle: {
    fa: "جدول شهریه دانشگاه آیدین در سال 2025",
    en: "Aydin University Tuition Fees 2025",
    tr: "2025 Yılı Aydın Üniversitesi Öğrenim Ücreti Tablosu"
  },
  tuitionTable: {
    headerFaculty: {
      fa: "رشته",
      en: "Faculty",
      tr: "Bölüm"
    },
    headerFee: {
      fa: "شهریه (سالانه)",
      en: "Tuition Fee (Yearly)",
      tr: "Yıllık Öğrenim Ücreti"
    },
    headerDescription: {
      fa: "توضیحات",
      en: "Description",
      tr: "Açıklama"
    }
  },
  newsTitle: {
    fa: "اخبار و رویدادها",
    en: "News and Events",
    tr: "Haberler ve Etkinlikler"
  },
  researchTitle: {
    fa: "مقالات تحقیقاتی و تحقیقات دانشجویی",
    en: "Research Articles and Student Research",
    tr: "Araştırma Makaleleri ve Öğrenci Araştırmaları"
  },
  collaborationTitle: {
    fa: "همکاری‌های بین‌المللی",
    en: "International Collaborations",
    tr: "Uluslararası İşbirlikleri"
  },
  // برای همکاری‌های بین‌المللی، به عنوان نمونه از اطلاعات معتبر استفاده شده است
  collaborationData: [
    {
      name: {
        fa: "دانشگاه هاروارد",
        en: "Harvard University",
        tr: "Harvard Üniversitesi"
      },
      link: "https://www.harvard.edu/",
      ranking: "1",
      icon: <FaCheckCircle />,
      // تصویر به عنوان پس‌زمینه می‌تواند جایگزین شود
      background: "https://via.placeholder.com/300x200?text=Harvard"
    },
    {
      name: {
        fa: "دانشگاه آکسفورد",
        en: "Oxford University",
        tr: "Oxford Üniversitesi"
      },
      link: "https://www.ox.ac.uk/",
      ranking: "2",
      icon: <FaCheckCircle />,
      background: "https://via.placeholder.com/300x200?text=Oxford"
    },
    {
      name: {
        fa: "دانشگاه توکیو",
        en: "University of Tokyo",
        tr: "Tokyo Üniversitesi"
      },
      link: "https://www.u-tokyo.ac.jp/en",
      ranking: "3",
      icon: <FaCheckCircle />,
      background: "https://via.placeholder.com/300x200?text=Tokyo"
    },
    {
      name: {
        fa: "دانشگاه ملی سنگاپور",
        en: "National University of Singapore",
        tr: "Singapur Ulusal Üniversitesi"
      },
      link: "https://www.nus.edu.sg/",
      ranking: "4",
      icon: <FaCheckCircle />,
      background: "https://via.placeholder.com/300x200?text=NUS"
    }
  ],
  entrepreneurshipTitle: {
    fa: "کارآفرینی و فرصت‌های شغلی",
    en: "Entrepreneurship and Job Opportunities",
    tr: "Girişimcilik ve İş Fırsatları"
  },
  announcementTitle: {
    fa: "🎓 فرصت طلایی: بورسیه تحصیلی 50%",
    en: "🎓 Golden Opportunity: 50% Scholarship",
    tr: "🎓 Altın Fırsat: %50 Burs İmkanı"
  },
  announcementText: {
    fa: "با ثبت‌نام از طریق UniManager، مسیر موفقیت خود را هموار کنید! تنها با چند کلیک، واجد شرایط دریافت بورسیه ۵۰ درصدی خواهید شد و هزینه‌های تحصیل شما کاهش می‌یابد. این پیشنهاد محدود، گامی بزرگ برای آینده سازان است!",
    en: "Pave your path to success with UniManager! Register now to be eligible for an exclusive 40% scholarship that lowers your tuition fees. This limited-time offer is a big step towards a brighter future!",
    tr: "UniManager ile başarı yolunuzu açın! Hemen kayıt olun, özel %40 burs imkanından yararlanarak öğrenim ücretlerinizi düşürün. Bu sınırlı süreli teklif, geleceğe emin adımlarla ilerlemeniz için büyük bir fırsat!"
  },
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  faqData: [
    {
      question: {
        fa: "شرایط پذیرش دانشگاه آیدین چیست؟",
        en: "What are the admission requirements for Aydin University?",
        tr: "Aydın Üniversitesi'ne kabul şartları nelerdir?"
      },
      answer: {
        fa: "برای پذیرش در دانشگاه آیدین، ارائه مدارک تحصیلی معتبر، نمرات قابل قبول و در برخی موارد مصاحبه الزامی است.",
        en: "Admission to Aydin University requires valid academic certificates, acceptable grades, and in some cases, an interview.",
        tr: "Aydın Üniversitesi'ne kabul için geçerli akademik belgeler, yeterli notlar ve bazı durumlarda mülakat gerekmektedir."
      }
    },
    {
      question: {
        fa: "شهریه تحصیلی دانشگاه آیدین در سال 2025 چقدر است؟",
        en: "What is the tuition fee for Aydin University in 2025?",
        tr: "2025 yılında Aydın Üniversitesi'nin öğrenim ücreti ne kadardır?"
      },
      answer: {
        fa: "شهریه دانشگاه آیدین بسته به رشته از ۴۰۰۰ تا ۱۰۰۰۰ دلار متغیر است.",
        en: "The tuition fee at Aydin University in 2025 ranges between $4,000 and $10,000 depending on the program.",
        tr: "Aydın Üniversitesi'nde 2025 yılında öğrenim ücreti, programa bağlı olarak 4.000 ila 10.000 dolar arasında değişmektedir."
      }
    },
    {
      question: {
        fa: "رشته‌های ارائه شده در دانشگاه آیدین کدامند؟",
        en: "What programs or faculties are offered at Aydin University?",
        tr: "Aydın Üniversitesi'nde hangi bölümler sunulmaktadır?"
      },
      answer: {
        fa: "دانشگاه آیدین در رشته‌هایی همچون مهندسی، فناوری اطلاعات، مدیریت، حقوق، هنرهای زیبا و علوم پایه فعالیت می‌کند.",
        en: "Aydin University offers programs in Engineering, Information Technology, Management, Law, Fine Arts, and Basic Sciences.",
        tr: "Aydın Üniversitesi, Mühendislik, Bilgi Teknolojileri, Yönetim, Hukuk, Güzel Sanatlar ve Temel Bilimler gibi bölümlerde eğitim vermektedir."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه آیدین بورسیه‌های ویژه‌ای برای دانشجویان بین‌المللی ارائه می‌دهد؟",
        en: "Does Aydin University offer special scholarships for international students?",
        tr: "Aydın Üniversitesi uluslararası öğrenciler için özel burs imkanları sunuyor mu?"
      },
      answer: {
        fa: "بله، دانشگاه آیدین بورسیه‌هایی شامل تخفیف شهریه و کمک هزینه‌های مالی به دانشجویان بین‌المللی ارائه می‌دهد.",
        en: "Yes, Aydin University offers scholarships, including tuition discounts and financial aid for international students.",
        tr: "Evet, Aydın Üniversitesi uluslararası öğrenciler için öğrenim ücreti indirimleri ve maddi destek sağlayan burs imkanları sunmaktadır."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  financialAidData: [
    {
      title: {
        fa: "بورسیه تحصیلی کامل",
        en: "Full Scholarship",
        tr: "Tam Burs"
      },
      description: {
        fa: "پوشش کامل شهریه و هزینه‌های زندگی برای دانشجویان ممتاز.",
        en: "Complete coverage of tuition and living expenses for outstanding students.",
        tr: "Başarılı öğrenciler için öğrenim ve yaşam giderlerinin tam karşılanması."
      }
    },
    {
      title: {
        fa: "بورسیه جزئی",
        en: "Partial Scholarship",
        tr: "Kısmi Burs"
      },
      description: {
        fa: "تخفیف قابل توجهی در شهریه به همراه کمک هزینه‌های جزئی.",
        en: "Significant tuition discount along with partial financial support.",
        tr: "Öğrenim ücretinde belirgin indirim ve kısmi maddi destek."
      }
    },
    {
      title: {
        fa: "وام دانشجویی",
        en: "Student Loan",
        tr: "Öğrenci Kredisi"
      },
      description: {
        fa: "امکان دریافت وام با شرایط مساعد جهت تأمین هزینه‌های تحصیلی.",
        en: "Availability of student loans under favorable conditions.",
        tr: "Uygun şartlarda öğrenci kredisi imkanı."
      }
    }
  ]
};

const facultiesData = [
    { 
      name: { 
        fa: "دانشکده مهندسی", 
        en: "Faculty of Engineering", 
        tr: "Mühendislik Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های پیشرفته در مهندسی مکانیک، برق، عمران و صنایع، با رویکرد نوآورانه و کاربردی.",
        en: "Advanced programs in Mechanical, Electrical, Civil, and Industrial Engineering with innovative and practical approaches.",
        tr: "Makine, Elektrik, İnşaat ve Endüstri mühendisliği alanlarında yenilikçi ve uygulamalı programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده مدیریت", 
        en: "Faculty of Management", 
        tr: "Yönetim Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های نوآورانه در مدیریت کسب‌وکار، بازاریابی، منابع انسانی و کارآفرینی.",
        en: "Innovative programs in Business Management, Marketing, Human Resources, and Entrepreneurship.",
        tr: "İşletme Yönetimi, Pazarlama, İnsan Kaynakları ve Girişimcilik alanlarında yenilikçi programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده حقوق", 
        en: "Faculty of Law", 
        tr: "Hukuk Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های حقوقی با تأکید بر حقوق بین‌الملل، تطبیقی و حقوق تجارت.",
        en: "Law programs with an emphasis on International Law, Comparative Law, and Commercial Law.",
        tr: "Uluslararası Hukuk, Karşılaştırmalı Hukuk ve Ticaret Hukuku üzerine odaklanan hukuk programları."
      }
    },
    { 
      name: { 
        fa: "دانشکده علوم اجتماعی", 
        en: "Faculty of Social Sciences", 
        tr: "Sosyal Bilimler Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های تحصیلی در روانشناسی، جامعه‌شناسی، علوم سیاسی و ارتباطات.",
        en: "Programs in Psychology, Sociology, Political Science, and Communication.",
        tr: "Psikoloji, Sosyoloji, Siyaset Bilimi ve İletişim alanlarında programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده هنر و طراحی", 
        en: "Faculty of Arts and Design", 
        tr: "Güzel Sanatlar ve Tasarım Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های خلاقانه در هنرهای تجسمی، طراحی گرافیک، عکاسی و طراحی صنعتی.",
        en: "Creative programs in Visual Arts, Graphic Design, Photography, and Industrial Design.",
        tr: "Görsel sanatlar, Grafik Tasarım, Fotoğrafçılık ve Endüstriyel Tasarım alanlarında yaratıcı programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده فناوری اطلاعات", 
        en: "Faculty of Information Technology", 
        tr: "Bilgi Teknolojileri Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های تخصصی در علوم کامپیوتر، مهندسی نرم‌افزار، امنیت سایبری و داده‌کاوی.",
        en: "Specialized programs in Computer Science, Software Engineering, Cybersecurity, and Data Mining.",
        tr: "Bilgisayar Bilimleri, Yazılım Mühendisliği, Siber Güvenlik ve Veri Madenciliği alanlarında uzmanlaşmış programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده آموزش", 
        en: "Faculty of Education", 
        tr: "Eğitim Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های جامع در تربیت معلم، علوم تربیتی و روش‌های نوین آموزشی.",
        en: "Comprehensive programs in Teacher Education, Educational Sciences, and Innovative Teaching Methods.",
        tr: "Öğretmen yetiştirme, Eğitim Bilimleri ve yenilikçi öğretim yöntemleri alanlarında kapsamlı programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده علوم پزشکی", 
        en: "Faculty of Medical Sciences", 
        tr: "Tıp ve Sağlık Bilimleri Fakültesi" 
      },
      description: { 
        fa: "آموزش و پژوهش در علوم پزشکی، دندانپزشکی و پرستاری.",
        en: "Education and research in Medical Sciences, Dentistry, and Nursing.",
        tr: "Tıp, Diş Hekimliği ve Hemşirelik alanlarında eğitim ve araştırma programları."
      }
    },
    { 
      name: { 
        fa: "دانشکده گردشگری", 
        en: "Faculty of Tourism", 
        tr: "Turizm Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های مدیریت گردشگری، هتلداری و صنایع مرتبط با گردشگری.",
        en: "Programs in Tourism Management, Hospitality, and related industries.",
        tr: "Turizm Yönetimi, Otelcilik ve turizmle ilgili sektörlerde programlar."
      }
    },
    { 
      name: { 
        fa: "دانشکده ارتباطات", 
        en: "Faculty of Communication", 
        tr: "İletişim Fakültesi" 
      },
      description: { 
        fa: "برنامه‌های تخصصی در رسانه، تبلیغات، روابط عمومی و ارتباطات جمعی.",
        en: "Specialized programs in Media, Advertising, Public Relations, and Mass Communication.",
        tr: "Medya, Reklamcılık, Halkla İlişkiler ve Kitle İletişimi alanlarında uzmanlaşmış programlar."
      }
    }
  ];
  

const FacultyCard = ({ name, description }) => (
  <div className="uni-faculty-card">
    <h3 className="uni-faculty-card-title">{name}</h3>
    <p className="uni-faculty-card-text">{description}</p>
  </div>
);

const TuitionTable = ({ lang }) => {
const tuitionData = [
  {
    faculty: { 
      fa: "مهندسی مکانیک", 
      en: "Mechanical Engineering", 
      tr: "Makine Mühendisliği" 
    },
    fee: { 
      fa: "$10,000", 
      en: "$10,000", 
      tr: "$10,000" 
    },
    description: {
      fa: "برنامه تخصصی در طراحی، تحلیل و بهینه‌سازی سیستم‌های مکانیکی.",
      en: "Specialized program in the design, analysis, and optimization of mechanical systems.",
      tr: "Makine sistemlerinin tasarımı, analizi ve optimizasyonuna odaklanan program."
    }
  },
  {
    faculty: { 
      fa: "مهندسی برق", 
      en: "Electrical Engineering", 
      tr: "Elektrik Mühendisliği" 
    },
    fee: { 
      fa: "$10,500", 
      en: "$10,500", 
      tr: "$10,500" 
    },
    description: {
      fa: "برنامه تخصصی در الکترونیک، قدرت و مخابرات.",
      en: "Specialized program in electronics, power systems, and telecommunications.",
      tr: "Elektronik, güç sistemleri ve telekomünikasyon alanlarında program."
    }
  },
  {
    faculty: { 
      fa: "مهندسی عمران", 
      en: "Civil Engineering", 
      tr: "İnşaat Mühendisliği" 
    },
    fee: { 
      fa: "$9,800", 
      en: "$9,800", 
      tr: "$9,800" 
    },
    description: {
      fa: "برنامه تخصصی در طراحی و ساخت سازه‌های عمرانی.",
      en: "Specialized program in the design and construction of civil structures.",
      tr: "İnşaat yapıların tasarımı ve inşasına odaklanan program."
    }
  },
  {
    faculty: { 
      fa: "مهندسی صنایع", 
      en: "Industrial Engineering", 
      tr: "Endüstri Mühendisliği" 
    },
    fee: { 
      fa: "$9,200", 
      en: "$9,200", 
      tr: "$9,200" 
    },
    description: {
      fa: "برنامه تخصصی در بهینه‌سازی سیستم‌های تولید و خدمات.",
      en: "Specialized program in optimizing production and service systems.",
      tr: "Üretim ve hizmet sistemlerinin optimizasyonuna odaklanan program."
    }
  },
  {
    faculty: { 
      fa: "مهندسی کامپیوتر", 
      en: "Computer Engineering", 
      tr: "Bilgisayar Mühendisliği" 
    },
    fee: { 
      fa: "$11,000", 
      en: "$11,000", 
      tr: "$11,000" 
    },
    description: {
      fa: "برنامه تخصصی در طراحی و توسعه سخت‌افزار و نرم‌افزار.",
      en: "Specialized program in the design and development of hardware and software.",
      tr: "Donanım ve yazılım geliştirme üzerine odaklanmış program."
    }
  },
  {
    faculty: { 
      fa: "مدیریت کسب‌وکار", 
      en: "Business Management", 
      tr: "İşletme Yönetimi" 
    },
    fee: { 
      fa: "$8,500", 
      en: "$8,500", 
      tr: "$8,500" 
    },
    description: {
      fa: "برنامه تخصصی در مدیریت کسب‌وکار و کارآفرینی.",
      en: "Specialized program in business management and entrepreneurship.",
      tr: "İşletme yönetimi ve girişimcilik üzerine uzmanlaşmış program."
    }
  },
  {
    faculty: { 
      fa: "بازاریابی", 
      en: "Marketing", 
      tr: "Pazarlama" 
    },
    fee: { 
      fa: "$8,000", 
      en: "$8,000", 
      tr: "$8,000" 
    },
    description: {
      fa: "برنامه تخصصی در بازاریابی دیجیتال و استراتژی‌های فروش.",
      en: "Specialized program in digital marketing and sales strategies.",
      tr: "Dijital pazarlama ve satış stratejileri üzerine program."
    }
  },
  {
    faculty: { 
      fa: "منابع انسانی", 
      en: "Human Resources", 
      tr: "İnsan Kaynakları" 
    },
    fee: { 
      fa: "$7,500", 
      en: "$7,500", 
      tr: "$7,500" 
    },
    description: {
      fa: "برنامه تخصصی در مدیریت نیروی انسانی و توسعه سازمانی.",
      en: "Specialized program in human resource management and organizational development.",
      tr: "İnsan kaynakları yönetimi ve organizasyonel gelişim üzerine program."
    }
  },
  {
    faculty: { 
      fa: "کارآفرینی", 
      en: "Entrepreneurship", 
      tr: "Girişimcilik" 
    },
    fee: { 
      fa: "$7,000", 
      en: "$7,000", 
      tr: "$7,000" 
    },
    description: {
      fa: "برنامه تخصصی در راه‌اندازی و مدیریت استارتاپ‌ها.",
      en: "Specialized program in startup creation and management.",
      tr: "Startup kurulumu ve yönetimi üzerine program."
    }
  },
  {
    faculty: { 
      fa: "حقوق بین‌الملل", 
      en: "International Law", 
      tr: "Uluslararası Hukuk" 
    },
    fee: { 
      fa: "$7,800", 
      en: "$7,800", 
      tr: "$7,800" 
    },
    description: {
      fa: "برنامه تخصصی در حقوق بین‌الملل و تطبیقی.",
      en: "Specialized program in international and comparative law.",
      tr: "Uluslararası ve karşılaştırmalı hukuk üzerine program."
    }
  },
  {
    faculty: { 
      fa: "حقوق تجارت", 
      en: "Commercial Law", 
      tr: "Ticaret Hukuku" 
    },
    fee: { 
      fa: "$7,500", 
      en: "$7,500", 
      tr: "$7,500" 
    },
    description: {
      fa: "برنامه تخصصی در حقوق تجارت و قراردادهای بین‌المللی.",
      en: "Specialized program in commercial law and international contracts.",
      tr: "Ticaret hukuku ve uluslararası sözleşmeler üzerine program."
    }
  },
  {
    faculty: { 
      fa: "روانشناسی", 
      en: "Psychology", 
      tr: "Psikoloji" 
    },
    fee: { 
      fa: "$6,500", 
      en: "$6,500", 
      tr: "$6,500" 
    },
    description: {
      fa: "برنامه تخصصی در روانشناسی بالینی، مشاوره و توسعه فردی.",
      en: "Specialized program in clinical psychology, counseling, and personal development.",
      tr: "Klinik psikoloji, danışmanlık ve kişisel gelişim üzerine program."
    }
  },
  {
    faculty: { 
      fa: "جامعه‌شناسی", 
      en: "Sociology", 
      tr: "Sosyoloji" 
    },
    fee: { 
      fa: "$6,000", 
      en: "$6,000", 
      tr: "$6,000" 
    },
    description: {
      fa: "برنامه تخصصی در مطالعه ساختارها و رفتارهای اجتماعی.",
      en: "Specialized program in studying social structures and behaviors.",
      tr: "Sosyal yapı ve davranışların incelenmesine yönelik program."
    }
  },
  {
    faculty: { 
      fa: "علوم سیاسی", 
      en: "Political Science", 
      tr: "Siyaset Bilimi" 
    },
    fee: { 
      fa: "$6,200", 
      en: "$6,200", 
      tr: "$6,200" 
    },
    description: {
      fa: "برنامه تخصصی در نظریه‌های سیاسی و سیاست‌های عمومی.",
      en: "Specialized program in political theories and public policies.",
      tr: "Politik teoriler ve kamu politikaları üzerine program."
    }
  },
  {
    faculty: { 
      fa: "ارتباطات جمعی", 
      en: "Mass Communication", 
      tr: "Kitle İletişimi" 
    },
    fee: { 
      fa: "$6,000", 
      en: "$6,000", 
      tr: "$6,000" 
    },
    description: {
      fa: "برنامه تخصصی در رسانه‌ها، تبلیغات و روابط عمومی.",
      en: "Specialized program in media, advertising, and public relations.",
      tr: "Medya, reklam ve halkla ilişkiler üzerine program."
    }
  },
  {
    faculty: { 
      fa: "هنرهای تجسمی", 
      en: "Visual Arts", 
      tr: "Görsel Sanatlar" 
    },
    fee: { 
      fa: "$5,500", 
      en: "$5,500", 
      tr: "$5,500" 
    },
    description: {
      fa: "برنامه تخصصی در نقاشی، طراحی و مجسمه‌سازی.",
      en: "Specialized program in painting, design, and sculpture.",
      tr: "Resim, tasarım ve heykel üzerine program."
    }
  },
  {
    faculty: { 
      fa: "طراحی گرافیک", 
      en: "Graphic Design", 
      tr: "Grafik Tasarım" 
    },
    fee: { 
      fa: "$5,500", 
      en: "$5,500", 
      tr: "$5,500" 
    },
    description: {
      fa: "برنامه تخصصی در طراحی لوگو، بسته‌بندی و تبلیغات.",
      en: "Specialized program in logo design, packaging, and advertising.",
      tr: "Logo, ambalaj ve reklam tasarımı üzerine program."
    }
  },
  {
    faculty: { 
      fa: "عکاسی", 
      en: "Photography", 
      tr: "Fotoğrafçılık" 
    },
    fee: { 
      fa: "$5,200", 
      en: "$5,200", 
      tr: "$5,200" 
    },
    description: {
      fa: "برنامه تخصصی در عکاسی حرفه‌ای و دیجیتال.",
      en: "Specialized program in professional and digital photography.",
      tr: "Profesyonel ve dijital fotoğrafçılık üzerine program."
    }
  },
  {
    faculty: { 
      fa: "طراحی صنعتی", 
      en: "Industrial Design", 
      tr: "Endüstriyel Tasarım" 
    },
    fee: { 
      fa: "$5,700", 
      en: "$5,700", 
      tr: "$5,700" 
    },
    description: {
      fa: "برنامه تخصصی در طراحی محصولات و مبلمان.",
      en: "Specialized program in product and furniture design.",
      tr: "Ürün ve mobilya tasarımı üzerine program."
    }
  },
  {
    faculty: { 
      fa: "علوم کامپیوتر", 
      en: "Computer Science", 
      tr: "Bilgisayar Bilimleri" 
    },
    fee: { 
      fa: "$10,500", 
      en: "$10,500", 
      tr: "$10,500" 
    },
    description: {
      fa: "برنامه تخصصی در الگوریتم‌ها، داده‌کاوی و هوش مصنوعی.",
      en: "Specialized program in algorithms, data mining, and artificial intelligence.",
      tr: "Algoritmalar, veri madenciliği ve yapay zeka üzerine program."
    }
  },
  {
    faculty: { 
      fa: "مهندسی نرم‌افزار", 
      en: "Software Engineering", 
      tr: "Yazılım Mühendisliği" 
    },
    fee: { 
      fa: "$10,000", 
      en: "$10,000", 
      tr: "$10,000" 
    },
    description: {
      fa: "برنامه تخصصی در توسعه نرم‌افزار و طراحی سیستم‌های توزیع شده.",
      en: "Specialized program in software development and distributed systems design.",
      tr: "Yazılım geliştirme ve dağıtık sistemler tasarımı üzerine program."
    }
  },
  {
    faculty: { 
      fa: "امنیت سایبری", 
      en: "Cybersecurity", 
      tr: "Siber Güvenlik" 
    },
    fee: { 
      fa: "$9,000", 
      en: "$9,000", 
      tr: "$9,000" 
    },
    description: {
      fa: "برنامه تخصصی در محافظت از اطلاعات و زیرساخت‌های دیجیتال.",
      en: "Specialized program in protecting information and digital infrastructures.",
      tr: "Bilgi ve dijital altyapıların korunması üzerine program."
    }
  },
  {
    faculty: { 
      fa: "داده‌کاوی", 
      en: "Data Mining", 
      tr: "Veri Madenciliği" 
    },
    fee: { 
      fa: "$9,200", 
      en: "$9,200", 
      tr: "$9,200" 
    },
    description: {
      fa: "برنامه تخصصی در تحلیل داده‌های بزرگ و استخراج الگوهای پنهان.",
      en: "Specialized program in big data analytics and uncovering hidden patterns.",
      tr: "Büyük veri analizi ve gizli kalıpların keşfi üzerine program."
    }
  },
  {
    faculty: { 
      fa: "تربیت معلم", 
      en: "Teacher Education", 
      tr: "Öğretmen Yetiştirme" 
    },
    fee: { 
      fa: "$6,200", 
      en: "$6,200", 
      tr: "$6,200" 
    },
    description: {
      fa: "برنامه جامع تربیت معلم و آموزش علوم تربیتی برای آماده‌سازی معلمان.",
      en: "Comprehensive teacher education program for preparing educators.",
      tr: "Öğretmen yetiştirme ve eğitim bilimleri alanında kapsamlı program."
    }
  },
  {
    faculty: { 
      fa: "علوم تربیتی", 
      en: "Educational Sciences", 
      tr: "Eğitim Bilimleri" 
    },
    fee: { 
      fa: "$6,000", 
      en: "$6,000", 
      tr: "$6,000" 
    },
    description: {
      fa: "برنامه تخصصی در پژوهش‌های آموزشی و نظریه‌های یادگیری.",
      en: "Specialized program in educational research and learning theories.",
      tr: "Eğitim araştırmaları ve öğrenme teorileri üzerine program."
    }
  },
  {
    faculty: { 
      fa: "پزشکی", 
      en: "Medicine", 
      tr: "Tıp" 
    },
    fee: { 
      fa: "$12,000", 
      en: "$12,000", 
      tr: "$12,000" 
    },
    description: { 
      fa: "برنامه تخصصی در علوم پزشکی با رویکرد بالینی و تحقیقاتی.",
      en: "Specialized program in medical sciences with clinical and research focus.",
      tr: "Klinik ve araştırmaya dayalı tıp eğitimi programı."
    }
  },
  {
    faculty: { 
      fa: "دندانپزشکی", 
      en: "Dentistry", 
      tr: "Diş Hekimliği" 
    },
    fee: { 
      fa: "$11,000", 
      en: "$11,000", 
      tr: "$11,000" 
    },
    description: {
      fa: "برنامه تخصصی در دندانپزشکی با تأکید بر روش‌های درمانی مدرن.",
      en: "Specialized program in dentistry with a focus on modern treatment methods.",
      tr: "Modern tedavi yöntemlerine odaklanan diş hekimliği programı."
    }
  },
  {
    faculty: { 
      fa: "پرستاری", 
      en: "Nursing", 
      tr: "Hemşirelik" 
    },
    fee: { 
      fa: "$10,000", 
      en: "$10,000", 
      tr: "$10,000" 
    },
    description: {
      fa: "برنامه تخصصی در پرستاری با تأکید بر مراقبت‌های بالینی.",
      en: "Specialized program in nursing with clinical care focus.",
      tr: "Klinik bakım üzerine odaklanan hemşirelik programı."
    }
  }
];

  const headers =
    lang === "fa"
      ? [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerFee[lang],
          translations.tuitionTable.headerDescription[lang]
        ]
      : [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerDescription[lang],
          translations.tuitionTable.headerFee[lang]
        ];

  return (
    <table className="uni-tuition-table">
      <thead>
        <tr>
          <th>{headers[0]}</th>
          <th>{headers[1]}</th>
          <th>{headers[2]}</th>
        </tr>
      </thead>
      <tbody>
        {tuitionData.map((item, index) =>
          lang === "fa" ? (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.fee[lang]}</td>
              <td>{item.description[lang]}</td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.description[lang]}</td>
              <td>{item.fee[lang]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const FAQSection = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);
  return (
    <section className="uni-faq-section">
      <h2 className="uni-faq-title">{translations.faqTitle[lang]}</h2>
      <div className="uni-faq-grid">
        {translations.faqData.map((faq, index) => (
          <div
            key={index}
            className="uni-faq-card"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="uni-faq-question">{faq.question[lang]}</h3>
            {activeIndex === index && (
              <p className="uni-faq-answer">{faq.answer[lang]}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

const AydinUniversityMiddle = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className={`uni-container ${lang !== "fa" ? "ltr" : ""}`}>
      {/* دانشکده‌ها */}
      <section className="uni-faculties-section">
        <h2 className="uni-faculties-title">{translations.facultiesTitle[lang]}</h2>
        <div className="uni-faculties-grid">
          {facultiesData.map((item, index) => (
            <FacultyCard 
              key={index} 
              name={item.name[lang]} 
              description={item.description[lang]} 
            />
          ))}
        </div>
      </section>

      {/* جدول شهریه */}
      <section className="uni-tuition-section">
        <h2 className="uni-tuition-title">{translations.tuitionTitle[lang]}</h2>
        <TuitionTable lang={lang} />
      </section>

      {/* اخبار و رویدادها */}
      <section className="uni-news-section">
        <h2 className="uni-news-title">{translations.newsTitle[lang]}</h2>
        <div className="uni-news-grid">
          {[
            {
                title: {
                  fa: "افتتاح مرکز نوآوری و پژوهش‌های کاربردی",
                  en: "Inauguration of Center for Innovation and Applied Research",
                  tr: "Uygulamalı Araştırma ve İnovasyon Merkezi Açılışı"
                },
                date: "۱۴۰۲/۱۰/۱۰",
                description: {
                  fa: "دانشگاه اطلس استانبول مرکز نوآوری و پژوهش‌های کاربردی خود را با هدف ارتقای دستاوردهای علمی و فناوری به طور رسمی افتتاح کرد.",
                  en: "Atlas University Istanbul officially inaugurated its Center for Innovation and Applied Research aimed at enhancing scientific and technological achievements.",
                  tr: "Atlas Üniversitesi İstanbul, bilimsel ve teknolojik başarıları artırmayı amaçlayan Uygulamalı Araştırma ve İnovasyon Merkezini resmen açtı."
                }
              },
              {
                title: {
                  fa: "نمایشگاه بین‌المللی پژوهش‌های دانشجویی",
                  en: "International Student Research Exhibition",
                  tr: "Uluslararası Öğrenci Araştırmaları Sergisi"
                },
                date: "۱۴۰۲/۱۰/۲۰",
                description: {
                  fa: "دانشجویان دانشگاه اطلس استانبول در نمایشگاهی بین‌المللی، دستاوردهای پژوهشی خود را به نمایش گذاشتند.",
                  en: "Students of Atlas University Istanbul showcased their research achievements at an international exhibition.",
                  tr: "Atlas Üniversitesi İstanbul öğrencileri, uluslararası bir sergide araştırma başarılarını sergilediler."
                }
              },
            {
              title: {
                fa: "پژوهش‌های پیشرفته در حوزه هوش مصنوعی و یادگیری عمیق",
                en: "Advanced Research in AI and Deep Learning",
                tr: "Yapay Zeka ve Derin Öğrenmede İleri Araştırmalar"
              },
              date: "۱۴۰۲/۱۰/۳۰",
              description: {
                fa: "تیم پژوهشی دانشگاه اطلس استانبول به بررسی کاربردهای نوین هوش مصنوعی در صنایع مختلف پرداخت.",
                en: "The research team at Atlas University Istanbul explored innovative applications of AI in various industries.",
                tr: "Atlas Üniversitesi İstanbul araştırma ekibi, yapay zekanın çeşitli sektörlerdeki yenilikçi uygulamalarını inceledi."
              }
            },
            {
              title: {
                fa: "تحقیقات نوین در حوزه اینترنت اشیا و رباتیک",
                en: "Novel Research in IoT and Robotics",
                tr: "Nesnelerin İnterneti ve Robotikte Yeni Araştırmalar"
              },
              date: "۱۴۰۲/۱۱/۱۰",
              description: {
                fa: "پژوهشگران دانشگاه اطلس استانبول فناوری‌های نوین در زمینه اینترنت اشیا و رباتیک را توسعه داده و نتایج امیدوارکننده‌ای کسب کردند.",
                en: "Researchers at Atlas University Istanbul developed innovative technologies in IoT and robotics with promising results.",
                tr: "Atlas Üniversitesi İstanbul araştırmacıları, Nesnelerin İnterneti ve robotik alanlarında yenilikçi teknolojiler geliştirerek umut verici sonuçlar elde etti."
              }
            }
          ].map((news, index) => (
            <div key={index} className="uni-news-card">
              <h3 className="uni-news-card-title">{news.title[lang]}</h3>
              <span className="uni-news-card-date">
                {formatDate(news.date, lang)}
              </span>
              <p className="uni-news-card-text">{news.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* مقالات تحقیقاتی */}
      <section className="uni-research-section">
        <h2 className="uni-research-title">{translations.researchTitle[lang]}</h2>
        <div className="uni-research-grid">
          {[
            {
              title: {
                fa: "پژوهشی در حوزه هوش مصنوعی و داده‌های بزرگ",
                en: "Research on AI and Big Data",
                tr: "Yapay Zeka ve Büyük Veri Araştırması"
              },
              date: "۱۴۰۲/۰۳/۲۰",
              description: {
                fa: "نتایج یک مطالعه پیشرفته در زمینه هوش مصنوعی و تحلیل داده‌های حجیم.",
                en: "Results of an advanced study in AI and big data analytics.",
                tr: "Yapay zeka ve büyük veri analizine dair ileri düzey bir çalışmanın sonuçları."
              }
            },
            {
              title: {
                fa: "تحقیقات در زمینه نوآوری در مدیریت",
                en: "Research on Innovation in Management",
                tr: "Yönetimde İnovasyon Araştırması"
              },
              date: "۱۴۰۲/۰۲/۱۰",
              description: {
                fa: "بررسی روش‌های نوین مدیریت و استراتژی‌های کسب‌وکار.",
                en: "Exploring innovative management methods and business strategies.",
                tr: "İnovatif yönetim yöntemleri ve iş stratejilerinin incelenmesi."
              }
            }
          ].map((article, index) => (
            <div key={index} className="uni-research-card">
              <h3 className="uni-research-card-title">{article.title[lang]}</h3>
              <span className="uni-research-card-date">
                {formatDate(article.date, lang)}
              </span>
              <p className="uni-research-card-text">{article.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* همکاری‌های بین‌المللی */}
      <section className="uni-collaboration-section">
        <h2 className="uni-collaboration-title">{translations.collaborationTitle[lang]}</h2>
        <div className="uni-collaboration-grid">
          {translations.collaborationData.map((item, index) => (
            <div 
              key={index} 
              className="uni-collaboration-item"
              style={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.name[lang]}
              </a>
              <div className="uni-collaboration-ranking">
                {lang === "fa"
                  ? `رتبه جهانی: ${item.ranking}`
                  : `Global Ranking: ${item.ranking}`}
                {item.icon}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* کارآفرینی و فرصت‌های شغلی */}
      <section className="uni-entrepreneurship-section">
        <h2 className="uni-entrepreneurship-title">{translations.entrepreneurshipTitle[lang]}</h2>
        <div className="uni-entrepreneurship-grid">
          {[
            {
              title: {
                fa: "کارگاه استارتاپینگ",
                en: "Startup Workshop",
                tr: "Startup Atölyesi"
              },
              description: {
                fa: "آموزش‌های تخصصی برای راه‌اندازی استارتاپ و کسب و کار نوپا.",
                en: "Specialized training for launching startups and new businesses.",
                tr: "Yeni işletmeler ve startup'lar için özel eğitim."
              }
            },
            {
              title: {
                fa: "مشاوره شغلی",
                en: "Career Counseling",
                tr: "Kariyer Danışmanlığı"
              },
              description: {
                fa: "ارائه خدمات مشاوره جهت ورود به بازار کار.",
                en: "Career counseling services to help you enter the job market.",
                tr: "İş piyasasına giriş için danışmanlık hizmetleri."
              }
            }
          ].map((item, index) => (
            <div key={index} className="uni-entrepreneurship-card">
              <h3 className="uni-entrepreneurship-card-title">{item.title[lang]}</h3>
              <p className="uni-entrepreneurship-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* اعلان ویژه بورسیه */}
      <section className="uni-announcement-section">
        <div
          className="announcement-bg-overlay"
          style={{ backgroundImage: `url(${uniManagerImage})` }}
        ></div>
        <div className="announcement-content">
          <h2 className="uni-announcement-title">
            {translations.announcementTitle[lang]}
          </h2>
          <p className="uni-announcement-text">
            {translations.announcementText[lang]}
          </p>
        </div>
      </section>

      {/* سوالات متداول */}
      <FAQSection lang={lang} />

      {/* خدمات مالی و بورسیه */}
      <section className="uni-financial-section">
        <h2 className="uni-financial-title">{translations.financialTitle[lang]}</h2>
        <div className="uni-financial-grid">
          {translations.financialAidData.map((item, index) => (
            <div key={index} className="uni-financial-card">
              <h3 className="uni-financial-card-title">{item.title[lang]}</h3>
              <p className="uni-financial-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AydinUniversityMiddle;
