import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { RiArrowDownSLine, RiArrowDropRightLine } from "react-icons/ri";
import { useLanguage } from "../contexts/LanguageContext";
import "../components/styles/Toolbar.css";

const menuText = {
  fa: {
    about: "درباره ما",
    contact: "تماس با ما",
    residency: "امور اقامتی",
    student: "امور دانشجویی",
    studentResidency: "اقامت دانشجویی",
    houseRental: "اجاره خانه",
    universities: "دانشگاه‌ها",
    dormitories: "خوابگاه دانشجویی",
    faq: "سوالات متداول",
  },
  en: {
    about: "About Us",
    contact: "Contact Us",
    residency: "Residency Affairs",
    student: "Student Affairs",
    studentResidency: "Student Residency",
    houseRental: "House Rental",
    universities: "Universities",
    dormitories: "Student Dormitories",
    faq: "FAQ",
  },
  tr: {
    about: "Hakkımızda",
    contact: "İletişim",
    residency: "Oturma İşleri",
    student: "Öğrenci İşleri",
    studentResidency: "Öğrenci Oturumu",
    houseRental: "Ev Kiralama",
    universities: "Üniversiteler",
    dormitories: "Öğrenci Yurtları",
    faq: "SSS",
  },
};

const Toolbar = () => {
  const { language, setLanguage } = useLanguage();
  const [menuOpen, setMenuOpen] = useState(false);
  const [residencyOpen, setResidencyOpen] = useState(false);
  const [studentOpen, setStudentOpen] = useState(false);
  const [showLangDropdown, setShowLangDropdown] = useState(false);

  // نگاشت زبان بر اساس مقادیر context (با استفاده از مقادیر کامل)
  const languageAbbr = {
    English: "EN",
    Persian: "FA",
    Turkish: "TR",
  };

  const selectedLanguage = languageAbbr[language] || "EN";

  // نگاشت متن منو براساس زبان
  const getText = () => {
    if (language === "fa") return menuText.fa;
    if (language === "tr") return menuText.tr;
    return menuText.en;
  };

  // تابع تغییر زبان همانند ExteraToolbar
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setShowLangDropdown(false);
  };

  // دکمه‌های تغییر زبان
  const languageButtons = (
    <>
      <button onClick={() => handleLanguageChange("English")} className="tool-lang-btn">
        <span className="tool-lang-abbr">EN</span>
      </button>
      <button onClick={() => handleLanguageChange("Persian")} className="tool-lang-btn">
        <span className="tool-lang-abbr">FA</span>
      </button>
      <button onClick={() => handleLanguageChange("Turkish")} className="tool-lang-btn">
        <span className="tool-lang-abbr">TR</span>
      </button>
    </>
  );

  const closeMenu = () => {
    setMenuOpen(false);
    setResidencyOpen(false);
    setStudentOpen(false);
  };

  const t = getText();

  return (
    <>
      <header className="tool-home-navbar">
        <div className="tool-toolbar-right">
          <button className="tool-menu-btn" onClick={() => setMenuOpen((prev) => !prev)}>
            <FaBars />
          </button>
        </div>

        <div className="tool-toolbar-center uni-manager-title">Uni Manager</div>

        <div className="tool-toolbar-left">
          {/* نمایش دکمه‌های تغییر زبان برای دسکتاپ */}
          <div className="tool-lang-desktop desktop-only">
            {languageButtons}
          </div>
          {/* نمایش منوی کشویی برای موبایل */}
          <div className="tool-lang-mobile mobile-only">
            <div className="tool-dropdown-container">
              <button 
                onClick={() => setShowLangDropdown(!showLangDropdown)} 
                className="tool-lang-dropdown-toggle"
              >
                <span className="tool-lang-abbr">{selectedLanguage}</span>
                <RiArrowDownSLine className="dropdown-icon" />
              </button>
              {showLangDropdown && (
                <div className="tool-lang-dropdown-menu">
                  {languageButtons}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {menuOpen && (
        <div className="tool-backdrop" onClick={closeMenu}>
          <div className="tool-dropdown-menu" onClick={(e) => e.stopPropagation()}>
            <div className={`tool-nested-dropdown ${studentOpen ? "open" : ""}`}>
              <button
                className="tool-nested-dropdown-button"
                onClick={() => setStudentOpen((prev) => !prev)}
              >
                {t.student}
                <RiArrowDropRightLine className="submenu-icon" />
              </button>
              {studentOpen && (
                <div className="tool-nested-dropdown-content">
                  <Link to="/universities" onClick={closeMenu}>
                    <button>{t.universities}</button>
                  </Link>
                  <Link to="/dormitories" onClick={closeMenu}>
                    <button>{t.dormitories}</button>
                  </Link>
                </div>
              )}
            </div>

            <div className={`tool-nested-dropdown ${residencyOpen ? "open" : ""}`}>
              <button
                className="tool-nested-dropdown-button"
                onClick={() => setResidencyOpen((prev) => !prev)}
              >
                {t.residency}
                <RiArrowDropRightLine className="submenu-icon" />
              </button>
              {residencyOpen && (
                <div className="tool-nested-dropdown-content">
                  <Link to="/student-residency" onClick={closeMenu}>
                    <button>{t.studentResidency}</button>
                  </Link>
                  <Link to="/house-rental" onClick={closeMenu}>
                    <button>{t.houseRental}</button>
                  </Link>
                </div>
              )}
            </div>

            <Link to="/about" onClick={closeMenu}>
              <button>{t.about}</button>
            </Link>

            <Link to="/contact" onClick={closeMenu}>
              <button>{t.contact}</button>
            </Link>

            <Link to="/faq" onClick={closeMenu}>
              <button>{t.faq}</button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Toolbar;
