import React, { useState } from "react";
import "../components/styles/FAQ.css";
import Footer from "../components/Footer";
import { useLanguage } from "../contexts/LanguageContext";

const FAQ = () => {
  const { language } = useLanguage(); // language: "fa", "en", or "tr"

  const faqs = {
    fa: [
      {
        question: "چگونه می‌توانم برای دانشگاه‌های برتر استانبول اپلای کنم؟",
        answer:
          "برای اپلای به دانشگاه‌های برتر استانبول ابتدا باید مدارک تحصیلی، نتایج آزمون‌های زبان و سایر اسناد مورد نیاز را آماده کنید و سپس از طریق سامانه‌های آنلاین دانشگاه‌ها اقدام نمایید. خدمات اپلای ما نیز در این روند به شما کمک می‌کند تا مراحل را به‌طور دقیق و سریع طی کنید.",
      },
      {
        question: "مدارک مورد نیاز برای اپلای چیست؟",
        answer:
          "مدارک اصلی شامل ریزنمرات، مدرک زبان (مانند IELTS یا TOEFL)، رزومه، نامه‌های توصیه و انگیزه‌نامه می‌باشد. بسته به دانشگاه، ممکن است مدارک اضافی نیز مورد نیاز باشد.",
      },
      {
        question: "آیا امکان اخذ بورسیه تحصیلی وجود دارد؟",
        answer:
          "بله، بسیاری از دانشگاه‌های برتر استانبول بورسیه‌های متنوعی ارائه می‌دهند. ما در خدمات اپلای، شما را در بررسی شرایط بورسیه و روند درخواست آن راهنمایی می‌کنیم.",
      },
      {
        question: "هزینه‌های اپلای چقدر است؟",
        answer:
          "هزینه‌های اپلای شامل هزینه‌های ارسال مدارک، آزمون‌های زبان و کارمزدهای خدمات مشاوره‌ای می‌شود. این هزینه‌ها بسته به دانشگاه و برنامه تحصیلی ممکن است متفاوت باشد.",
      },
      {
        question: "زمان دریافت پاسخ از دانشگاه‌ها چقدر است؟",
        answer:
          "زمان دریافت پاسخ از دانشگاه‌ها معمولاً بین ۴ تا ۸ هفته است، اما بسته به دانشگاه و شرایط ممکن است این زمان تغییر کند.",
      },
      {
        question: "خدمات اپلای ما چه مزایایی دارد؟",
        answer:
          "خدمات اپلای ما شامل مشاوره تخصصی، بررسی مدارک، راهنمایی در تهیه رزومه و نامه‌های توصیه و پیگیری دقیق روند اپلای می‌شود که به شما کمک می‌کند بهترین انتخاب را در دانشگاه‌های برتر استانبول داشته باشید.",
      },
    ],
    en: [
      {
        question: "How can I apply to top universities in Istanbul?",
        answer:
          "To apply to top universities in Istanbul, you should first prepare your academic transcripts, language test scores, and other required documents, then submit your application through the university's online portal. Our application services will guide you through each step to ensure a smooth process.",
      },
      {
        question: "What documents are required for the application?",
        answer:
          "The main documents include academic transcripts, language test results (such as IELTS or TOEFL), a resume, letters of recommendation, and a statement of purpose. Additional documents may be required depending on the university.",
      },
      {
        question: "Is it possible to obtain a scholarship?",
        answer:
          "Yes, many top universities in Istanbul offer a variety of scholarships. Our application services help you assess your eligibility and guide you through the scholarship application process.",
      },
      {
        question: "What are the costs involved in the application process?",
        answer:
          "Application costs include fees for document submission, language tests, and consultancy services. These costs may vary depending on the university and the chosen program.",
      },
      {
        question: "How long does it take to receive a response from universities?",
        answer:
          "Typically, it takes between 4 to 8 weeks to receive a response from universities, although this may vary depending on the institution and other factors.",
      },
      {
        question: "What benefits do your application services provide?",
        answer:
          "Our application services include expert consultation, document review, guidance on resume and recommendation letters, and thorough tracking of your application process. This support helps you make the best decision for your academic future.",
      },
    ],
    tr: [
      {
        question: "İstanbul'daki en iyi üniversitelere nasıl başvurabilirim?",
        answer:
          "İstanbul'daki en iyi üniversitelere başvurmak için öncelikle akademik transkriptler, dil sınavı sonuçları ve gerekli diğer belgeleri hazırlamanız gerekir. Başvurunuzu üniversitenin online portalı üzerinden gerçekleştirip, başvuru hizmetlerimizle süreci kolaylaştırabilirsiniz.",
      },
      {
        question: "Başvuru için hangi belgeler gereklidir?",
        answer:
          "Ana belgeler arasında akademik transkriptler, dil sınavı sonuçları (IELTS/TOEFL), özgeçmiş, tavsiye mektupları ve niyet mektubu bulunmaktadır. Üniversiteye bağlı olarak ek belgeler de istenebilir.",
      },
      {
        question: "Burs alma imkânı var mı?",
        answer:
          "Evet, İstanbul'daki birçok üst düzey üniversite çeşitli burs olanakları sunmaktadır. Başvuru hizmetlerimiz, burs uygunluğunuzu değerlendirmenize ve başvuru sürecinde size rehberlik etmenize yardımcı olur.",
      },
      {
        question: "What are the costs involved in the application process?",
        answer:
          "Application costs include fees for document submission, language tests, and consultancy services. These costs may vary depending on the university and the chosen program.",
      },
      {
        question: "How long does it take to receive a response from universities?",
        answer:
          "Typically, it takes between 4 to 8 weeks to receive a response from universities, although this may vary depending on the institution and other factors.",
      },
      {
        question: "What benefits do your application services provide?",
        answer:
          "Our application services include expert consultation, document review, guidance on resume and recommendation letters, and thorough tracking of your application process. This support helps you make the best decision for your academic future.",
      },
    ],
  };

  const currentFaqs = faqs[language] || faqs["fa"];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className={`faq-faq-container ${language === "fa" ? "faq-rtl" : ""}`}>
        <h1 className="faq-faq-header">
          {language === "en"
            ? "Frequently Asked Questions"
            : language === "fa"
            ? "سوالات متداول"
            : "Sıkça Sorulan Sorular"}
        </h1>
        <div className="faq-faq-list">
          {currentFaqs.map((faq, index) => (
            <div key={index} className="faq-faq-item">
              <div className="faq-faq-question" onClick={() => toggleFAQ(index)}>
                <h3>{faq.question}</h3>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </div>
              {activeIndex === index && (
                <div className="faq-faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer language={language} />
    </>
  );
};

export default FAQ;
