// src/components/universities/BahcesehirUniversity.js
import React from "react";
import NisantasiUniversityTop from "./nisUni1";
import MedipolUniversityMiddle from "./nisUni2";
import NisantasiUniversityBottom from "./nisUni3";

const NisantasiUniversity = (props) => (
  <>
    <NisantasiUniversityTop lang={props.lang} />
    <MedipolUniversityMiddle lang={props.lang} />
    <NisantasiUniversityBottom lang={props.lang} />
  </>
);

NisantasiUniversity.defaultProps = {
  lang: "fa",
};

export default NisantasiUniversity;
