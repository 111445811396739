import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../components/styles/Home.css";

// ایمپورت تصاویر دانشگاه‌ها
import atlasImg from "../assets/image/Home/atlas.webp";
import bahcesehirImg from "../assets/image/Home/ba.webp";
import nisantasiImg from "../assets/image/Home/nis.webp";
import medipolImg from "../assets/image/Home/Medipol.webp";
import slider1 from "../assets/image/Home/slider1.webp";
import slider2 from "../assets/image/Home/slider2.webp";
import slider3 from "../assets/image/Home/slider3.webp";
import slider4 from "../assets/image/Home/slider4.webp";

// ایمپورت تصاویر خدمات مهاجرتی
import visaImg from "../assets/image/Home/home-card/visa.webp";
import scholarshipImg from "../assets/image/Home/home-card/scholarship.webp";
import housingImg from "../assets/image/Home/home-card/housing.webp";
import consultationImg from "../assets/image/Home/home-card/consultation.webp";
// ایمپورت کامپوننت‌های Toolbar و Footer
import Toolbar from "./Toolbar";
import Footer from "./Footer";
import { useLanguage } from "../contexts/LanguageContext";

const universities = [
  {
    name: {
      English: "Atlas University",
      Persian: "دانشگاه اطلس",
      Turkish: "Atlas Üniversitesi"
    },
    image: atlasImg,
    description: {
      English: "Atlas University is a modern institution known for its innovative programs and strong industry collaborations.",
      Persian: "دانشگاه اطلس با برنامه‌های نوآورانه و همکاری‌های قوی با صنعت، به عنوان یک نهاد مدرن شناخته می‌شود.",
      Turkish: "Atlas Üniversitesi, yenilikçi programları ve güçlü sanayi iş birlikleri ile tanınan modern bir kuruluştur."
    }
  },
  {
    name: {
      English: "Bahçeşehir University",
      Persian: "دانشگاه باهچه‌شهیر",
      Turkish: "Bahçeşehir Üniversitesi"
    },
    image: bahcesehirImg,
    description: {
      English: "Bahçeşehir University remains a leader in private education in Istanbul, celebrated for its international outlook and innovative curriculum.",
      Persian: "دانشگاه باهچه‌شهیر همچنان به عنوان پیشتاز آموزش خصوصی در استانبول شناخته شده و به خاطر دیدگاه بین‌المللی و برنامه‌های نوآورانه‌اش شهرت دارد.",
      Turkish: "Bahçeşehir Üniversitesi, uluslararası bakış açısı ve yenilikçi müfredatı ile İstanbul'da özel eğitimin lideri olmaya devam ediyor."
    }
  },
  {
    name: {
      English: "Nišantaşı University",
      Persian: "دانشگاه نیشانتاشی",
      Turkish: "Nišantaşı Üniversitesi"
    },
    image: nisantasiImg,
    description: {
      English: "Nišantaşı University is recognized for its rigorous academic standards and vibrant campus life.",
      Persian: "دانشگاه نیشانتاشی به دلیل استانداردهای بالای آموزشی و زندگی پویا در دانشگاه، مورد توجه قرار گرفته است.",
      Turkish: "Nišantaşı Üniversitesi, yüksek akademik standartları ve dinamik kampüs yaşamıyla tanınır."
    }
  },
  {
    name: {
      English: "Medipol University",
      Persian: "دانشگاه مدیپل",
      Turkish: "Medipol Üniversitesi"
    },
    image: medipolImg,
    description: {
      English: "Medipol University is known for its innovative research, comprehensive academic programs, and strong global partnerships.",
      Persian: "دانشگاه مدیپل به دلیل تحقیقات نوآورانه، برنامه‌های جامع آموزشی و همکاری‌های جهانی قدرتمند شهرت دارد.",
      Turkish: "Medipol Üniversitesi, yenilikçi araştırmaları, kapsamlı akademik programları ve güçlü küresel iş birlikleri ile tanınır."
    }
  }
];

const services = [
  {
    name: {
      English: "Student Visa Assistance",
      Persian: "راهنمایی دریافت ویزای دانشجویی",
      Turkish: "Öğrenci Vizesi Yardımı"
    },
    image: visaImg,
    description: {
      English: "We provide complete guidance and support in obtaining a student visa for Turkey.",
      Persian: "ما راهنمایی کامل و پشتیبانی برای دریافت ویزای دانشجویی ترکیه ارائه می‌دهیم.",
      Turkish: "Türkiye öğrenci vizesi almanız için eksiksiz rehberlik ve destek sağlıyoruz."
    }
  },
  {
    name: {
      English: "Scholarship Opportunities",
      Persian: "فرصت‌های بورسیه تحصیلی",
      Turkish: "Burs Fırsatları"
    },
    image: scholarshipImg,
    description: {
      English: "We help students find the best scholarships available in Turkish universities",
      Persian: "ما به دانشجویان در یافتن بهترین بورسیه‌های تحصیلی در دانشگاه‌های ترکیه کمک می‌کنیم",
      Turkish: "Öğrencilere Türkiye'deki üniversitelerde en iyi bursları bulmaları konusunda yardımcı oluyoruz"
    }
  },
  {
    name: {
      English: "Student Housing Assistance",
      Persian: "راهنمایی در پیدا کردن خوابگاه دانشجویی",
      Turkish: "Öğrenci Konaklama Yardımı"
    },
    image: housingImg,
    description: {
      English: "We assist in finding the best student dormitories and rental apartments",
      Persian: "ما در یافتن بهترین خوابگاه‌های دانشجویی و آپارتمان‌های اجاره‌ای کمک می‌کنیم",
      Turkish: "En iyi öğrenci yurtlarını ve kiralık daireleri bulmanıza yardımcı oluyoruz"
    }
  },
  {
    name: {
      English: "Consultation & Legal Support",
      Persian: "مشاوره و پشتیبانی حقوقی",
      Turkish: "Danışmanlık ve Hukuki Destek"
    },
    image: consultationImg,
    description: {
      English: "Our experts provide professional consultation and legal support for students",
      Persian: "متخصصان ما مشاوره حرفه‌ای و پشتیبانی حقوقی برای دانشجویان ارائه می‌دهند",
      Turkish: "Uzmanlarımız öğrencilere profesyonel danışmanlık ve hukuki destek sunmaktadır"
    }
  }
];

const aboutText = {
  English: `At Uni Manager, we don’t just see education as a goal — we see it as a bridge to a brighter future and boundless possibilities. Our mission is to walk beside you through every step of your academic journey abroad, offering expert guidance on university admissions, seamless visa processing, scholarship opportunities, and personalized accommodation solutions. We believe every student has a unique story, and our dedicated team ensures tailored support that respects your ambitions and overcomes your challenges. Uni Manager is your trusted partner in building an extraordinary future.`,
  
  Persian: `در Uni Manager، ما تحصیل را نه صرفاً یک مسیر، بلکه پلی به‌سوی آینده‌ای درخشان و فرصت‌های بی‌پایان می‌دانیم. رسالت ما، همراهی دقیق و حرفه‌ای شما در تمامی مراحل مهاجرت تحصیلی است؛ از مشاوره تخصصی برای پذیرش در برترین دانشگاه‌های ترکیه، تا پشتیبانی کامل در امور ویزا، دریافت بورسیه، و تأمین محل اقامت متناسب با نیاز شما. ما به یکتایی اهداف و دغدغه‌های هر دانشجو باور داریم و با تکیه بر تیمی متخصص و شبکه‌ای قوی از مؤسسات آموزشی معتبر، تلاش می‌کنیم مسیری هموار، مطمئن و غنی از تجربه‌های علمی و فرهنگی برایتان فراهم کنیم. Uni Manager، شریک مطمئن شما در تحقق رؤیاهای تحصیلی و ساختن آینده‌ای متفاوت است.`,
  
  Turkish: `Uni Manager olarak eğitimi yalnızca bir hedef değil, parlak bir geleceğe ve sınırsız fırsatlara açılan bir köprü olarak görüyoruz. Amacımız; sizi yurt dışı eğitim yolculuğunuzda her adımda profesyonelce desteklemek, üniversite kabul sürecinden vize işlemlerine, burs olanaklarından size özel konaklama çözümlerine kadar tüm ihtiyaçlarınıza kapsamlı hizmet sunmaktır. Her öğrencinin kendine özgü hedef ve zorlukları olduğuna inanıyor, uzman ekibimizle bu süreci size özel ve sorunsuz hale getiriyoruz. Türkiye'nin önde gelen üniversiteleriyle güçlü bağlantılarımız ve yerel eğitim sistemine dair derin bilgimiz sayesinde, sizi zenginleştirici bir akademik ve kültürel deneyime hazır hale getiriyoruz. Uni Manager, hayallerinizi gerçeğe dönüştürmede güvenilir yol arkadaşınızdır.`
};

const Home = () => {
  const { language } = useLanguage();
  const langKey = language === "fa" ? "Persian" : language === "tr" ? "Turkish" : "English";

  useEffect(() => {
    document.documentElement.lang = language === "fa" ? "fa" : language === "tr" ? "tr" : "en";
  }, [language]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="home-container">
      <Toolbar />

      <div className="home-slider-container">
        <Slider {...sliderSettings}>
          <div><img src={slider1} alt="Slide 1" /></div>
          <div><img src={slider2} alt="Slide 2" /></div>
          <div><img src={slider3} alt="Slide 3" /></div>
          <div><img src={slider4} alt="Slide 4" /></div>
        </Slider>
      </div>

      <div className="home-company-description">
        <p>{aboutText[langKey]}</p>
      </div>

      <div className="home-university-cards-container">
        <h2 className="home-university-header">
          {langKey === "English" 
            ? "🏛️ Top Universities" 
            : langKey === "Persian" 
              ? "🏛️ دانشگاه‌های برتر" 
              : "🏛️ En İyi Üniversiteler"}
        </h2>
        <div className="home-university-cards">
          {universities.map((university, index) => (
            <div key={index} className="home-university-card">
              <img src={university.image} alt={university.name[langKey]} className="home-university-image" />
              <div className="home-university-info">
                <h3>{university.name[langKey]}</h3>
                <p>{university.description[langKey]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="home-university-comparison">
        <h2 className="home-university-comparison-header">
          {langKey === "English" 
            ? "Comprehensive Comparison of Top Universities 2025" 
            : langKey === "Persian" 
              ? "مقایسه جامع دانشگاه‌های برتر ۲۰۲۵" 
              : "2025 Yılında En İyi Üniversitelerin Kapsamlı Karşılaştırması"}
        </h2>
        <div className="home-comparison-table-container">
          <table className="home-comparison-table">
            <thead>
              <tr>
                <th>{langKey === "English" ? "University" : langKey === "Persian" ? "دانشگاه" : "Üniversite"}</th>
                <th>{langKey === "English" ? "Global Ranking" : langKey === "Persian" ? "رتبه جهانی" : "Küresel Sıralama"}</th>
                <th>{langKey === "English" ? "Academic Programs" : langKey === "Persian" ? "برنامه‌های آموزشی" : "Akademik Programlar"}</th>
                <th>{langKey === "English" ? "Research & Innovation" : langKey === "Persian" ? "تحقیقات و نوآوری" : "Araştırma ve Yenilik"}</th>
                <th>{langKey === "English" ? "Campus Facilities" : langKey === "Persian" ? "امکانات دانشگاهی" : "Kampüs Olanakları"}</th>
                <th>{langKey === "English" ? "International Collaborations" : langKey === "Persian" ? "همکاری‌های بین‌المللی" : "Uluslararası İşbirlikleri"}</th>
                <th>{langKey === "English" ? "Student Satisfaction" : langKey === "Persian" ? "رضایت دانشجویان" : "Öğrenci Memnuniyeti"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{langKey === "English" ? "Atlas University" : langKey === "Persian" ? "دانشگاه اطلس" : "Atlas Üniversitesi"}</td>
                <td>150</td>
                <td>{langKey === "English" ? "Excellent" : langKey === "Persian" ? "عالی" : "Mükemmel"}</td>
                <td>{langKey === "English" ? "Strong" : langKey === "Persian" ? "قوی" : "Güçlü"}</td>
                <td>{langKey === "English" ? "Modern" : langKey === "Persian" ? "مدرن" : "Modern"}</td>
                <td>{langKey === "English" ? "Developing" : langKey === "Persian" ? "در حال توسعه" : "Gelişmekte"}</td>
                <td>{langKey === "English" ? "High" : langKey === "Persian" ? "بالا" : "Yüksek"}</td>
              </tr>
              <tr>
                <td>{langKey === "English" ? "Bahçeşehir University" : langKey === "Persian" ? "دانشگاه باهچه‌شهیر" : "Bahçeşehir Üniversitesi"}</td>
                <td>120</td>
                <td>{langKey === "English" ? "Excellent" : langKey === "Persian" ? "عالی" : "Mükemmel"}</td>
                <td>{langKey === "English" ? "Very Strong" : langKey === "Persian" ? "بسیار قوی" : "Çok Güçlü"}</td>
                <td>{langKey === "English" ? "Up-to-date" : langKey === "Persian" ? "به‌روز" : "Güncel"}</td>
                <td>{langKey === "English" ? "Extensive" : langKey === "Persian" ? "گسترده" : "Kapsamlı"}</td>
                <td>{langKey === "English" ? "Very High" : langKey === "Persian" ? "بسیار بالا" : "Çok Yüksek"}</td>
              </tr>
              <tr>
                <td>{langKey === "English" ? "Nišantaşı University" : langKey === "Persian" ? "دانشگاه نیشانتاشی" : "Nišantaşı Üniversitesi"}</td>
                <td>180</td>
                <td>{langKey === "English" ? "Good" : langKey === "Persian" ? "خوب" : "İyi"}</td>
                <td>{langKey === "English" ? "Moderate" : langKey === "Persian" ? "متوسط" : "Orta"}</td>
                <td>{langKey === "English" ? "Modern" : langKey === "Persian" ? "مدرن" : "Modern"}</td>
                <td>{langKey === "English" ? "Moderate" : langKey === "Persian" ? "متوسط" : "Orta"}</td>
                <td>{langKey === "English" ? "Good" : langKey === "Persian" ? "خوب" : "İyi"}</td>
              </tr>
              <tr>
                <td>{langKey === "English" ? "Medipol University" : langKey === "Persian" ? "دانشگاه مدیپل" : "Medipol Üniversitesi"}</td>
                <td>110</td>
                <td>{langKey === "English" ? "Excellent" : langKey === "Persian" ? "عالی" : "Mükemmel"}</td>
                <td>{langKey === "English" ? "Very Strong" : langKey === "Persian" ? "بسیار قوی" : "Çok Güçlü"}</td>
                <td>{langKey === "English" ? "Advanced" : langKey === "Persian" ? "پیشرفته" : "İleri"}</td>
                <td>{langKey === "English" ? "Extensive" : langKey === "Persian" ? "گسترده" : "Kapsamlı"}</td>
                <td>{langKey === "English" ? "Excellent" : langKey === "Persian" ? "عالی" : "Mükemmel"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="home-services-container">
        <h2 className="home-services-header">
          {langKey === "English" 
            ? "🎓 Student Immigration Services" 
            : langKey === "Persian" 
              ? "🎓 خدمات مهاجرتی دانشجویان" 
              : "🎓 Öğrenci Göç Hizmetleri"}
        </h2>
        <div className="home-services-list">
          {services.map((service, index) => (
            <div key={index} className="home-service-card">
              <img src={service.image} alt={service.name[langKey]} className="home-service-image" />
              <div className="home-service-info">
                <h3>{service.name[langKey]}</h3>
                <p>{service.description[langKey]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
