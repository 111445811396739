// src/components/universities/BahcesehirUniversity.js
import React from "react";
import MedipolUniversityTop from "./medUni1";
import MedipolUniversityMiddle from "./medUni2";
import MedipolUniversityBottom from "./medUni3";

const MedipolUniversity = (props) => (
  <>
    <MedipolUniversityTop lang={props.lang} />
    <MedipolUniversityMiddle lang={props.lang} />
    <MedipolUniversityBottom lang={props.lang} />
  </>
);

MedipolUniversity.defaultProps = {
  lang: "fa",
};

export default MedipolUniversity;
