import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ExteraToolbar from "../../components/ExteraToolbar";
import posterImage from "../../assets/image/university/bahcesehir/bahcesehir1.webp";
import posterImage1 from "../../assets/image/university/bahcesehir/ba1.webp";
import posterImage2 from "../../assets/image/university/bahcesehir/ba2.webp";
import posterImage3 from "../../assets/image/university/bahcesehir/ba3.webp";
import posterImage4 from "../../assets/image/university/bahcesehir/ba4.webp";
import posterImage5 from "../../assets/image/university/bahcesehir/ba5.webp";
import posterImage6 from "../../assets/image/university/bahcesehir/ba6.webp";
import posterImage7 from "../../assets/image/university/bahcesehir/ba7.webp";
import { 
  FaCity,
  FaGlobe,
  FaStar,
  FaLanguage,
  FaUserGraduate,
  FaUniversity,
  FaCheckCircle,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaSubway,
  FaUsers,
  FaBuilding 
} from "react-icons/fa";
import "../../components/styles/Uni1.css";

// شی ترجمه برای متون
const translations = {
  titleUniGlance: {
    fa: "دانشگاه باهچه‌شهیر در یک نگاه",
    en: "Bahcesehir University at a Glance",
    tr: "Bahçeşehir Üniversitesi Bir Bakışta"
  },
  infoData: {
    city: { fa: "شهر", en: "City", tr: "Şehir" },
    cityValue: { fa: "استانبول", en: "Istanbul", tr: "İstanbul" },
    faculties: { fa: "تعداد دانشکده", en: "Number of Faculties", tr: "Fakülte Sayısı" },
    facultiesValue: { fa: "۹ دانشکده", en: "9 Faculties", tr: "9 Fakülte" },
    type: { fa: "نوع دانشگاه", en: "Type of University", tr: "Üniversite Türü" },
    typeValue: { fa: "خصوصی و بین‌المللی", en: "Private & International", tr: "Özel & Uluslararası" },
    ranking: { fa: "رتبه داخلی", en: "Domestic Ranking", tr: "Ulusal Sıra" },
    rankingValue: { fa: "۵ در بین دانشگاه‌های خصوصی", en: "5th among Private Universities", tr: "Özel Üniversiteler arasında 5." },
    accredited: { fa: "تأیید", en: "Accredited", tr: "Onaylı" },
    accreditedValue: { fa: "مورد تأیید وزارت علوم ایران", en: "Accredited by the Iranian Ministry of Science", tr: "İran Bilim Bakanlığı Onaylı" },
    founded: { fa: "سال تأسیس", en: "Year Founded", tr: "Kuruluş Yılı" },
    foundedValue: { fa: "۱۹۹۸ میلادی", en: "1998", tr: "1998" },
    language: { fa: "زبان تحصیل", en: "Language of Instruction", tr: "Öğretim Dili" },
    languageValue: { fa: "ترکی / انگلیسی", en: "Turkish / English", tr: "Türkçe / İngilizce" },
    admission: { fa: "شرایط پذیرش", en: "Admission Criteria", tr: "Kabul Şartları" },
    admissionValue: { fa: "بدون آزمون ورودی", en: "No Entrance Exam", tr: "Giriş Sınavı Yok" },
    students: { fa: "تعداد دانشجو", en: "Number of Students", tr: "Öğrenci Sayısı" },
    studentsValue: { fa: "۴۵۰۰۰+", en: "45,000+", tr: "45,000+" },
    collaborations: { fa: "همکاری‌های بین‌المللی", en: "International Collaborations", tr: "Uluslararası İşbirlikleri" },
    collaborationsValue: { fa: "۱۹۳+", en: "193+", tr: "193+" }
  },
  galleryTitle: {
    fa: "گالری تصاویر دانشگاه باهچه‌شهیر",
    en: "Gallery of Bahcesehir University Images",
    tr: "Bahçeşehir Üniversitesi Resim Galerisi"
  },
  imageAlt: {
    fa: "تصویر",
    en: "Image",
    tr: "Resim"
  },
  details: {
    international: {
      title: {
        fa: "فرصت‌های بین‌المللی",
        en: "International Opportunities",
        tr: "Uluslararası Fırsatlar"
      },
      text: {
        fa: "با بیش از ۱۹۰ قرارداد تبادل دانشجو و استاد با دانشگاه‌های برتر دنیا، محیطی جهانی برای یادگیری ایجاد شده است.",
        en: "With over 190 student and faculty exchange agreements with top universities worldwide, a global learning environment has been created.",
        tr: "Dünya çapındaki önde gelen üniversitelerle 190'dan fazla öğrenci ve öğretim üyesi değişim anlaşmasıyla küresel bir öğrenme ortamı oluşturulmuştur."
      }
    },
    location: {
      title: {
        fa: "موقعیت جغرافیایی خاص",
        en: "Unique Geographical Location",
        tr: "Özel Coğrafi Konum"
      },
      text: {
        fa: "واقع در قلب منطقه بشیکتاش استانبول، با چشم‌انداز الهام‌بخش از بسفر؛ تجربه‌ای فراتر از تحصیل.",
        en: "Located in the heart of the Besiktas district of Istanbul, with an inspiring view of the Bosphorus; an experience beyond just education.",
        tr: "İstanbul'un Beşiktaş bölgesinin kalbinde, Boğaziçi'nin ilham verici manzarasına sahip; sadece eğitimden öte bir deneyim."
      }
    },
    metro: {
      title: {
        fa: "دسترسی آسان به مترو",
        en: "Easy Access to Metro",
        tr: "Metroya Kolay Erişim"
      },
      text: {
        fa: "موقعیت دانشگاه نزدیک به ایستگاه‌های مترو، اتوبوس و فِری",
        en: "The university is located near metro, bus, and ferry stations.",
        tr: "Üniversite, metro, otobüs ve feribot istasyonlarına yakın konumdadır."
      }
    },
    iranians: {
      title: {
        fa: "محیط دوستانه برای ایرانیان",
        en: "Friendly Environment for Iranians",
        tr: "İranlılar için Dostane Ortam"
      },
      text: {
        fa: "جامعه دانشجویی فعال و برگزاری رویدادهای ملی ایران",
        en: "An active student community and hosting of national events for Iranians.",
        tr: "Aktif öğrenci topluluğu ve İranlılar için ulusal etkinliklerin düzenlenmesi."
      }
    },
    facilities: {
      title: {
        fa: "امکانات مدرن و بین‌المللی",
        en: "Modern and International Facilities",
        tr: "Modern ve Uluslararası Olanaklar"
      },
      text: {
        fa: "کتابخانه ۲۴ ساعته، خوابگاه، مرکز کارآفرینی و استارتاپ",
        en: "24-hour library, dormitory, entrepreneurship and startup center.",
        tr: "24 saat açık kütüphane, yurt, girişimcilik ve startup merkezi."
      }
    }
  },
  admissionSection: {
    title: {
      fa: "شرایط پذیرش و بورسیه",
      en: "Admission Requirements & Scholarships",
      tr: "Kabul Şartları ve Burslar"
    },
    items: [
      {
        title: {
          fa: "شرایط پذیرش دانشگاه باهچه‌شهیر",
          en: "Admission Requirements for Bahcesehir University",
          tr: "Bahçeşehir Üniversitesi Kabul Şartları"
        },
        text: {
          fa: "بسیاری از رشته‌ها بدون آزمون ورودی هستند. تنها برخی از رشته‌های تخصصی نیازمند آزمون خاص و مصاحبه می‌باشند.",
          en: "Many programs do not require an entrance exam. Only some specialized programs require a specific exam and interview.",
          tr: "Birçok program giriş sınavı gerektirmez. Sadece bazı uzmanlık programları özel sınav ve mülakat gerektirir."
        }
      },
      {
        title: {
          fa: "شرایط پذیرش ارشد و دکتری",
          en: "Admission Requirements for Master's and PhD",
          tr: "Yüksek Lisans ve Doktora Kabul Şartları"
        },
        text: {
          fa: "برای پذیرش در مقاطع ارشد و دکتری، ارائه مدرک لیسانس معتبر، نمرات خوب و در برخی موارد مصاحبه ضروری است.",
          en: "For admission to master's and PhD programs, a valid bachelor's degree, good grades, and in some cases an interview are required.",
          tr: "Yüksek lisans ve doktora kabulü için geçerli bir lisans diploması, iyi notlar ve bazı durumlarda mülakat gereklidir."
        }
      },
      {
        title: {
          fa: "مدارک ثبت‌نام",
          en: "Enrollment Documents",
          tr: "Kayıt Belgeleri"
        },
        text: {
          fa: "مدارک مورد نیاز شامل مدرک تحصیلی، ریزنمرات، توصیه‌نامه، گذرنامه معتبر و مدارک زبان (انگلیسی یا ترکی) است.",
          en: "Required documents include educational certificate, transcripts, recommendation letter, valid passport, and language certificates (English or Turkish).",
          tr: "Gerekli belgeler arasında eğitim belgesi، not dökümü، tavsiye mektubu، geçerli pasaport ve dil belgeleri (İngilizce veya Türkçe) bulunur."
        }
      },
      {
        title: {
          fa: "بورسیه دانشگاه باهچه‌شهیر",
          en: "Bahcesehir University Scholarships",
          tr: "Bahçeşehir Üniversitesi Bursları"
        },
        text: {
          fa: "بورسیه‌هایی شامل تخفیف شهریه، خوابگاه و کمک هزینه به دانشجویان بین‌المللی ارائه می‌شود.",
          en: "Scholarships include tuition discounts, dormitory, and financial aid for international students.",
          tr: "Burslar, uluslararası öğrenciler için öğrenim ücreti indirimi، yurt ve maddi destek içerir."
        }
      },
      {
        title: {
          fa: "شهریه در سال 2025",
          en: "Tuition Fees in 2025",
          tr: "2025 Yılı Öğrenim Ücreti"
        },
        text: {
          fa: "شهریه دانشگاه برای دانشجویان بین‌المللی در سال 2025 بسته به رشته بین ۵۰۰۰ تا ۱۲۰۰۰ دلار متغیر است.",
          en: "Tuition fees for international students in 2025 vary between $5000 and $12000 depending on the program.",
          tr: "2025 yılında uluslararası öğrenciler için öğrenim ücreti، programa bağlı olarak 5000 ila 12000 dolar arasında değişmektedir."
        }
      }
    ]
  }
};

// کامپوننت‌های فرعی
const InfoCard = ({ title, value, icon }) => (
  <div className="uni-info-card">
    <div className="uni-info-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

const AdmissionCard = ({ title, text }) => (
  <div className="uni-admission-card">
    <h3 className="uni-admission-card-title">{title}</h3>
    <p className="uni-admission-card-text">{text}</p>
  </div>
);

const BahcesehirUniversityTop = (props) => {
  // دریافت زبان از پراپ (پیش‌فرض فارسی)
  const lang = props.lang || "fa";
  const containerClass = lang === "fa" ? "uni-container" : "uni-container ltr";
  const detailBlockClass = lang === "fa" ? "uni-detail-icon-block rtl" : "uni-detail-icon-block ltr";

  // آرایه اطلاعات کلی دانشگاه با استفاده از ترجمه
  const infoData = [
    { title: translations.infoData.city[lang], value: translations.infoData.cityValue[lang], icon: <FaCity /> },
    { title: translations.infoData.faculties[lang], value: translations.infoData.facultiesValue[lang], icon: <FaUniversity /> },
    { title: translations.infoData.type[lang], value: translations.infoData.typeValue[lang], icon: <FaGlobe /> },
    { title: translations.infoData.ranking[lang], value: translations.infoData.rankingValue[lang], icon: <FaStar /> },
    { title: translations.infoData.accredited[lang], value: translations.infoData.accreditedValue[lang], icon: <FaCheckCircle /> },
    { title: translations.infoData.founded[lang], value: translations.infoData.foundedValue[lang], icon: <FaCalendarAlt /> },
    { title: translations.infoData.language[lang], value: translations.infoData.languageValue[lang], icon: <FaLanguage /> },
    { title: translations.infoData.admission[lang], value: translations.infoData.admissionValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.students[lang], value: translations.infoData.studentsValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.collaborations[lang], value: translations.infoData.collaborationsValue[lang], icon: <FaGlobe /> }
  ];

  // تنظیمات اسلایدر
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={containerClass}>
      <ExteraToolbar />
      <div className="uni-hero-minimal">
        <img
          src={posterImage}
          alt="دانشگاه باهچه‌شهیر"
          className="uni-hero-image-minimal"
        />
      </div>

      {/* بخش اطلاعات کلی دانشگاه */}
      <section className="uni-info-section">
        <h2 className="uni-info-title">{translations.titleUniGlance[lang]}</h2>
        <div className="uni-info-grid">
          {infoData.map((item, index) => (
            <InfoCard key={index} {...item} />
          ))}
        </div>
      </section>

      {/* بخش گالری تصاویر */}
      <section className="uni-gallery-section">
        <h2 className="uni-gallery-title">{translations.galleryTitle[lang]}</h2>
        <Slider {...sliderSettings}>
          <div className="uni-gallery-card">
            <img src={posterImage1} alt={`${translations.imageAlt[lang]} 1`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage2} alt={`${translations.imageAlt[lang]} 2`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage3} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage4} alt={`${translations.imageAlt[lang]} 4`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage5} alt={`${translations.imageAlt[lang]} 5`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage6} alt={`${translations.imageAlt[lang]} 6`} />
          </div>
          <div className="uni-gallery-card">
            <img src={posterImage7} alt={`${translations.imageAlt[lang]} 7`} />
          </div>
        </Slider>
      </section>

      {/* بخش اطلاعات تکمیلی */}
      <section className="uni-details-section">
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaGlobe /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.international.title[lang]}</h3>
            <p>{translations.details.international.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaMapMarkerAlt /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.location.title[lang]}</h3>
            <p>{translations.details.location.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaSubway /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.metro.title[lang]}</h3>
            <p>{translations.details.metro.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaUsers /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.iranians.title[lang]}</h3>
            <p>{translations.details.iranians.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaBuilding /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.facilities.title[lang]}</h3>
            <p>{translations.details.facilities.text[lang]}</p>
          </div>
        </div>
      </section>

      {/* بخش شرایط پذیرش */}
      <section className="uni-admission-section">
        <h2 className="uni-admission-title">{translations.admissionSection.title[lang]}</h2>
        <div className="uni-admission-grid">
          {translations.admissionSection.items.map((item, index) => (
            <AdmissionCard
              key={index}
              title={item.title[lang]}
              text={item.text[lang]}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default BahcesehirUniversityTop;
