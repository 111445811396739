import React from "react";
import Lottie from "react-lottie";
import { useLanguage } from "../contexts/LanguageContext";
import Footer from "../components/Footer";
import aboutUsAnimation from "../assets/animation/Contact.json";
// استفاده از آیکون‌های رسمی
import { FaEnvelope, FaWhatsapp, FaInstagram } from "react-icons/fa";
import "./styles/Contact.css";

const translations = {
  fa: {
    contactTitle: "ارتباط با ما",
    description:
      "برای کسب اطلاعات بیشتر و دریافت مشاوره، از طریق راه‌های ارتباطی زیر با ما تماس بگیرید. تیم ما آماده پاسخگویی به شماست!"
  },
  en: {
    contactTitle: "Contact Us",
    description:
      "For more information and consultation, please reach out through the contact options below. Our team is ready to assist you!"
  },
  tr: {
    contactTitle: "Bize Ulaşın",
    description:
      "Daha fazla bilgi ve danışmanlık için lütfen aşağıdaki iletişim seçeneklerinden bize ulaşın. Ekibimiz size yardımcı olmak için hazır!"
  }
};

const Contact = () => {
  const { language, setLanguage } = useLanguage();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aboutUsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };



  return (
    <div className={`contact-page ${language === "fa" ? "rtl" : "ltr"}`}>
      {/* انیمیشن در بالاترین بخش */}
      <div className="animation-container">
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
      <div className="contact-header">
        <h1>{translations[language].contactTitle}</h1>
        <p>{translations[language].description}</p>
      </div>
      <div className="contact-methods">
        <div className="contact-item">
          <div className="icon">
            <FaEnvelope />
          </div>
          <div className="contact-info">
            <h2>Email</h2>
            <a href="mailto:uni.manager2025@gmail.com">uni.manager2025@gmail.com</a>
          </div>
        </div>
        <div className="contact-item">
          <div className="icon">
            <FaWhatsapp />
          </div>
          <div className="contact-info">
            <h2>WhatsApp</h2>
            <a
              href="https://wa.me/your_number"
              target="_blank"
              rel="noopener noreferrer"
            >
              +90 555 555 5555
            </a>
          </div>
        </div>
        <div className="contact-item">
          <div className="icon">
            <FaInstagram />
          </div>
          <div className="contact-info">
            <h2>Instagram</h2>
            <a
              href="https://www.instagram.com/uni_manager_?igsh=MTJ5dGtjcTZiYzQzZw%3D%3D&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              @uni_manager_
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
