import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ExteraToolbar from "../../components/ExteraToolbar";
import aydinPoster from "../../assets/image/university/aydin/aydin.webp";
import aydinPoster1 from "../../assets/image/university/aydin/aydin1.webp";
import aydinPoster2 from "../../assets/image/university/aydin/aydin2.webp";
import aydinPoster3 from "../../assets/image/university/aydin/aydin3.webp";
import aydinPoster4 from "../../assets/image/university/aydin/aydin4.webp";
import aydinPoster5 from "../../assets/image/university/aydin/aydin5.webp";
import aydinPoster6 from "../../assets/image/university/aydin/aydin6.webp";
import aydinPoster7 from "../../assets/image/university/aydin/aydin7.webp";
import { 
  FaCity,
  FaGlobe,
  FaStar,
  FaLanguage,
  FaUserGraduate,
  FaUniversity,
  FaCheckCircle,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaSubway,
  FaUsers,
  FaBuilding 
} from "react-icons/fa";
import "../../components/styles/Uni1.css";

// شی ترجمه برای متون دانشگاه آیدین استانبول
const translations = {
  titleUniGlance: {
    fa: "دانشگاه آیدین استانبول در یک نگاه",
    en: "Aydin University Istanbul at a Glance",
    tr: "Aydın Üniversitesi İstanbul Bir Bakışta"
  },
  infoData: {
    city: { fa: "شهر", en: "City", tr: "Şehir" },
    cityValue: { fa: "استانبول", en: "Istanbul", tr: "İstanbul" },
    faculties: { fa: "تعداد دانشکده", en: "Number of Faculties", tr: "Fakülte Sayısı" },
    facultiesValue: { fa: "۷ دانشکده", en: "7 Faculties", tr: "7 Fakülte" },
    type: { fa: "نوع دانشگاه", en: "Type of University", tr: "Üniversite Türü" },
    typeValue: { fa: "خصوصی و بین‌المللی", en: "Private & International", tr: "Özel & Uluslararası" },
    ranking: { fa: "رتبه داخلی", en: "Domestic Ranking", tr: "Ulusal Sıra" },
    rankingValue: { fa: "۱۰ در بین دانشگاه‌های خصوصی", en: "10th among Private Universities", tr: "Özel Üniversiteler arasında 10." },
    accredited: { fa: "تأیید", en: "Accredited", tr: "Onaylı" },
    accreditedValue: { fa: "مورد تأیید شورای آموزش عالی ترکیه", en: "Accredited by the Turkish Council of Higher Education", tr: "Türkiye Yükseköğretim Kurulu Onaylı" },
    founded: { fa: "سال تأسیس", en: "Year Founded", tr: "Kuruluş Yılı" },
    foundedValue: { fa: "۲۰۰۸ میلادی", en: "2008", tr: "2008" },
    language: { fa: "زبان تحصیل", en: "Language of Instruction", tr: "Öğretim Dili" },
    languageValue: { fa: "ترکی / انگلیسی", en: "Turkish / English", tr: "Türkçe / İngilizce" },
    admission: { fa: "شرایط پذیرش", en: "Admission Criteria", tr: "Kabul Şartları" },
    admissionValue: { fa: "با آزمون ورودی", en: "Entrance Exam Required", tr: "Giriş Sınavı Var" },
    students: { fa: "تعداد دانشجو", en: "Number of Students", tr: "Öğrenci Sayısı" },
    studentsValue: { fa: "۲۰۰۰۰+", en: "20,000+", tr: "20,000+" },
    collaborations: { fa: "همکاری‌های بین‌المللی", en: "International Collaborations", tr: "Uluslararası İşbirlikleri" },
    collaborationsValue: { fa: "۱۵۰+", en: "150+", tr: "150+" }
  },
  galleryTitle: {
    fa: "گالری تصاویر دانشگاه آیدین استانبول",
    en: "Gallery of Aydin University Istanbul Images",
    tr: "Aydın Üniversitesi İstanbul Resim Galerisi"
  },
  imageAlt: {
    fa: "تصویر",
    en: "Image",
    tr: "Resim"
  },
  details: {
    international: {
      title: {
        fa: "فرصت‌های بین‌المللی",
        en: "International Opportunities",
        tr: "Uluslararası Fırsatlar"
      },
      text: {
        fa: "با همکاری با دانشگاه‌های معتبر بین‌المللی، فرصت‌های تبادل دانشجو فراهم شده است.",
        en: "Through partnerships with reputable international universities, student exchange opportunities are provided.",
        tr: "Önde gelen uluslararası üniversitelerle yapılan işbirlikleri sayesinde öğrenci değişim fırsatları sunulmaktadır."
      }
    },
    location: {
      title: {
        fa: "موقعیت جغرافیایی خاص",
        en: "Unique Geographical Location",
        tr: "Özel Coğrafi Konum"
      },
      text: {
        fa: "موقعیت دانشگاه در قلب استانبول، دسترسی آسان به امکانات شهری را تضمین می‌کند.",
        en: "Located in the heart of Istanbul, the university ensures easy access to urban amenities.",
        tr: "İstanbul'un kalbinde yer alan üniversite, şehir imkanlarına kolay erişim sağlar."
      }
    },
    metro: {
      title: {
        fa: "دسترسی آسان به مترو",
        en: "Easy Access to Metro",
        tr: "Metroya Kolay Erişim"
      },
      text: {
        fa: "دانشگاه در نزدیکی ایستگاه‌های مترو واقع شده است.",
        en: "The university is situated near metro stations.",
        tr: "Üniversite, metro istasyonlarına yakın konumda yer almaktadır."
      }
    },
    iranians: {
      title: {
        fa: "محیط دوستانه برای ایرانیان",
        en: "Friendly Environment for Iranians",
        tr: "İranlılar için Dostane Ortam"
      },
      text: {
        fa: "فضایی گرم و صمیمی برای دانشجویان ایرانی فراهم شده است.",
        en: "A warm and welcoming environment is provided for Iranian students.",
        tr: "İranlı öğrenciler için sıcak ve samimi bir ortam sunulmaktadır."
      }
    },
    facilities: {
      title: {
        fa: "امکانات مدرن و بین‌المللی",
        en: "Modern and International Facilities",
        tr: "Modern ve Uluslararası Olanaklar"
      },
      text: {
        fa: "کتابخانه مدرن، آزمایشگاه‌های پیشرفته و فضای آموزشی به‌روز در دانشگاه موجود است.",
        en: "The university offers a modern library, advanced laboratories, and up-to-date educational facilities.",
        tr: "Üniversitede modern kütüphane, ileri teknoloji laboratuvarlar ve güncel eğitim olanakları mevcuttur."
      }
    }
  },
  admissionSection: {
    title: {
      fa: "شرایط پذیرش و بورسیه دانشگاه آیدین استانبول",
      en: "Admission Requirements & Scholarships for Aydin University Istanbul",
      tr: "Aydın Üniversitesi İstanbul Kabul Şartları ve Burslar"
    },
    items: [
      {
        title: {
          fa: "شرایط پذیرش دانشگاه آیدین استانبول",
          en: "Admission Requirements for Aydin University Istanbul",
          tr: "Aydın Üniversitesi İstanbul Kabul Şartları"
        },
        text: {
          fa: "برخی رشته‌ها نیاز به آزمون ورودی دارند و در برخی موارد مصاحبه نیز الزامی است.",
          en: "Some programs require an entrance exam and, in certain cases, an interview.",
          tr: "Bazı programlar giriş sınavı gerektirir ve bazı durumlarda mülakat zorunludur."
        }
      },
      {
        title: {
          fa: "شرایط پذیرش دوره‌های تحصیلات تکمیلی",
          en: "Admission Requirements for Master's and PhD",
          tr: "Yüksek Lisans ve Doktora Kabul Şartları"
        },
        text: {
          fa: "برای دوره‌های تکمیلی ارائه مدرک کارشناسی معتبر، نمرات بالا و در برخی موارد مصاحبه لازم است.",
          en: "For graduate programs, a valid bachelor's degree, high grades, and in some cases an interview are required.",
          tr: "Lisansüstü programlar için geçerli bir lisans diploması, yüksek notlar ve bazı durumlarda mülakat gereklidir."
        }
      },
      {
        title: {
          fa: "مدارک ثبت‌نام",
          en: "Enrollment Documents",
          tr: "Kayıt Belgeleri"
        },
        text: {
          fa: "مدارک مورد نیاز شامل مدرک تحصیلی، ریزنمرات، مدرک زبان و پاسپورت معتبر است.",
          en: "Required documents include educational certificates, transcripts, language certificates, and a valid passport.",
          tr: "Gerekli belgeler arasında eğitim belgeleri, transkriptler, dil sertifikaları ve geçerli pasaport yer almaktadır."
        }
      },
      {
        title: {
          fa: "بورسیه دانشگاه آیدین استانبول",
          en: "Aydin University Istanbul Scholarships",
          tr: "Aydın Üniversitesi İstanbul Bursları"
        },
        text: {
          fa: "بورسیه‌های مختلف شامل تخفیف شهریه، کمک هزینه و خوابگاه برای دانشجویان بین‌المللی ارائه می‌شود.",
          en: "Various scholarships including tuition discounts, financial aid, and dormitory support are available for international students.",
          tr: "Uluslararası öğrenciler için öğrenim ücreti indirimi, maddi destek ve yurt imkânlarını içeren çeşitli burslar sunulmaktadır."
        }
      },
      {
        title: {
          fa: "شهریه در سال 2025",
          en: "Tuition Fees in 2025",
          tr: "2025 Yılı Öğrenim Ücreti"
        },
        text: {
          fa: "شهریه دانشگاه برای دانشجویان بین‌المللی در سال 2025 بسته به رشته بین ۴۰۰۰ تا ۱۰۰۰۰ دلار متغیر است.",
          en: "Tuition fees for international students in 2025 range between $4000 and $10000 depending on the program.",
          tr: "2025 yılında uluslararası öğrenciler için öğrenim ücreti, programa bağlı olarak 4000 ila 10000 dolar arasında değişmektedir."
        }
      }
    ]
  }
};

// کامپوننت‌های فرعی
const InfoCard = ({ title, value, icon }) => (
  <div className="uni-info-card">
    <div className="uni-info-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{value}</p>
  </div>
);

const AdmissionCard = ({ title, text }) => (
  <div className="uni-admission-card">
    <h3 className="uni-admission-card-title">{title}</h3>
    <p className="uni-admission-card-text">{text}</p>
  </div>
);

const AydinUniversityTop = (props) => {
  // دریافت زبان از پراپ (پیش‌فرض فارسی)
  const lang = props.lang || "fa";
  const containerClass = lang === "fa" ? "uni-container" : "uni-container ltr";
  const detailBlockClass = lang === "fa" ? "uni-detail-icon-block rtl" : "uni-detail-icon-block ltr";

  // آرایه اطلاعات کلی دانشگاه با استفاده از ترجمه
  const infoData = [
    { title: translations.infoData.city[lang], value: translations.infoData.cityValue[lang], icon: <FaCity /> },
    { title: translations.infoData.faculties[lang], value: translations.infoData.facultiesValue[lang], icon: <FaUniversity /> },
    { title: translations.infoData.type[lang], value: translations.infoData.typeValue[lang], icon: <FaGlobe /> },
    { title: translations.infoData.ranking[lang], value: translations.infoData.rankingValue[lang], icon: <FaStar /> },
    { title: translations.infoData.accredited[lang], value: translations.infoData.accreditedValue[lang], icon: <FaCheckCircle /> },
    { title: translations.infoData.founded[lang], value: translations.infoData.foundedValue[lang], icon: <FaCalendarAlt /> },
    { title: translations.infoData.language[lang], value: translations.infoData.languageValue[lang], icon: <FaLanguage /> },
    { title: translations.infoData.admission[lang], value: translations.infoData.admissionValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.students[lang], value: translations.infoData.studentsValue[lang], icon: <FaUserGraduate /> },
    { title: translations.infoData.collaborations[lang], value: translations.infoData.collaborationsValue[lang], icon: <FaGlobe /> }
  ];

  // تنظیمات اسلایدر
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <div className={containerClass}>
      <ExteraToolbar />
      <div className="uni-hero-minimal">
        <img
          src={aydinPoster}
          alt="دانشگاه آیدین استانبول"
          className="uni-hero-image-minimal"
        />
      </div>

      {/* بخش اطلاعات کلی دانشگاه */}
      <section className="uni-info-section">
        <h2 className="uni-info-title">{translations.titleUniGlance[lang]}</h2>
        <div className="uni-info-grid">
          {infoData.map((item, index) => (
            <InfoCard key={index} {...item} />
          ))}
        </div>
      </section>

      {/* بخش گالری تصاویر */}
      <section className="uni-gallery-section">
        <h2 className="uni-gallery-title">{translations.galleryTitle[lang]}</h2>
        <Slider {...sliderSettings}>
          <div className="uni-gallery-card">
            <img src={aydinPoster1} alt={`${translations.imageAlt[lang]} 1`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster2} alt={`${translations.imageAlt[lang]} 2`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster3} alt={`${translations.imageAlt[lang]} 3`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster4} alt={`${translations.imageAlt[lang]} 4`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster5} alt={`${translations.imageAlt[lang]} 5`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster6} alt={`${translations.imageAlt[lang]} 6`} />
          </div>
          <div className="uni-gallery-card">
            <img src={aydinPoster7} alt={`${translations.imageAlt[lang]} 7`} />
          </div>
        </Slider>
      </section>

      {/* بخش اطلاعات تکمیلی */}
      <section className="uni-details-section">
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaGlobe /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.international.title[lang]}</h3>
            <p>{translations.details.international.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaMapMarkerAlt /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.location.title[lang]}</h3>
            <p>{translations.details.location.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaSubway /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.metro.title[lang]}</h3>
            <p>{translations.details.metro.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaUsers /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.iranians.title[lang]}</h3>
            <p>{translations.details.iranians.text[lang]}</p>
          </div>
        </div>
        <div className={detailBlockClass}>
          <div className="uni-detail-icon"><FaBuilding /></div>
          <div className="uni-detail-icon-text">
            <h3>{translations.details.facilities.title[lang]}</h3>
            <p>{translations.details.facilities.text[lang]}</p>
          </div>
        </div>
      </section>

      {/* بخش شرایط پذیرش */}
      <section className="uni-admission-section">
        <h2 className="uni-admission-title">{translations.admissionSection.title[lang]}</h2>
        <div className="uni-admission-grid">
          {translations.admissionSection.items.map((item, index) => (
            <AdmissionCard
              key={index}
              title={item.title[lang]}
              text={item.text[lang]}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default AydinUniversityTop;
