import React, { useState } from "react";
import "../../components/styles/Uni.css";
import { FaCheckCircle } from "react-icons/fa"; // آیکون معتبر بودن
import "../../components/styles/Uni2.css";
import harvardImage from "../../assets/image/uni-card/harvard.webp";
import oxfordImage from "../../assets/image/uni-card/oxford.webp";
import tokyoImage from "../../assets/image/uni-card/tokyo.webp";
import technicalImage from "../../assets/image/uni-card/Technical.webp";
import uniManagerImage from "../../assets/image/uni-card/UniManager.png";

// تابع کمکی برای تبدیل ارقام فارسی به انگلیسی
const convertPersianDigitsToEnglish = (str) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let result = str;
  persianDigits.forEach((pd, index) => {
    const reg = new RegExp(pd, "g");
    result = result.replace(reg, englishDigits[index]);
  });
  return result;
};

// نگاشت تاریخ‌های جلالی به میلادی برای داده‌های ثابت
const jalaliToGregorianMapping = {
  "1402/06/10": "2023/09/01",
  "1402/05/25": "2023/08/16",
  "1402/04/15": "2023/07/06",
  "1402/03/20": "2023/06/10",
  "1402/02/10": "2023/04/30",
  "1402/01/30": "2023/04/19"
};

// تابع تبدیل تاریخ بر اساس زبان
const formatDate = (dateStr, lang) => {
  if (lang === "fa") return dateStr;
  const engDate = convertPersianDigitsToEnglish(dateStr);
  if (jalaliToGregorianMapping[engDate]) {
    return jalaliToGregorianMapping[engDate];
  }
  return engDate;
};

const translations = {
  facultiesTitle: {
    fa: "دانشکده‌های دانشگاه باهچه‌شهیر",
    en: "Bahcesehir University Faculties",
    tr: "Bahçeşehir Üniversitesi Fakülteleri"
  },
  tuitionTitle: {
    fa: "جدول شهریه دانشگاه باهچه‌شهیر در سال 2025",
    en: "Bahcesehir University Tuition Fees 2025",
    tr: "2025 Yılı Bahçeşehir Üniversitesi Öğrenim Ücreti Tablosu"
  },
  tuitionTable: {
    headerFaculty: {
      fa: "رشته",
      en: "Faculty",
      tr: "Bölüm"
    },
    headerFee: {
      fa: "شهریه (سالانه)",
      en: "Tuition Fee (Yearly)",
      tr: "Yıllık Öğrenim Ücreti"
    },
    headerDescription: {
      fa: "توضیحات",
      en: "Description",
      tr: "Açıklama"
    }
  },
  newsTitle: {
    fa: "اخبار و رویدادها",
    en: "News and Events",
    tr: "Haberler ve Etkinlikler"
  },
  researchTitle: {
    fa: "مقالات تحقیقاتی و تحقیقات دانشجویی",
    en: "Research Articles and Student Research",
    tr: "Araştırma Makaleleri ve Öğrenci Araştırmaları"
  },
  collaborationTitle: {
    fa: "همکاری‌های بین‌المللی",
    en: "International Collaborations",
    tr: "Uluslararası İşbirlikleri"
  },
  collaborationData: [
    {
      name: {
        fa: "دانشگاه هاروارد",
        en: "Harvard University",
        tr: "Harvard Üniversitesi"
      },
      link: "https://www.harvard.edu/",
      ranking: "1",
      icon: <FaCheckCircle />,
      background: harvardImage
    },
    {
      name: {
        fa: "دانشگاه آکسفورد",
        en: "Oxford University",
        tr: "Oxford Üniversitesi"
      },
      link: "https://www.ox.ac.uk/",
      ranking: "2",
      icon: <FaCheckCircle />,
      background: oxfordImage
    },
    {
      name: {
        fa: "دانشگاه توکیو",
        en: "University of Tokyo",
        tr: "Tokyo Üniversitesi"
      },
      link: "https://www.u-tokyo.ac.jp/en",
      ranking: "3",
      icon: <FaCheckCircle />,
      background: tokyoImage
    },
    {
      name: {
        fa: "دانشگاه فنی مونیخ",
        en: "Technical University of Munich",
        tr: "Münih Teknik Üniversitesi"
      },
      link: "https://www.tum.de/",
      ranking: "4",
      icon: <FaCheckCircle />,
      background: technicalImage
    }
  ],
  entrepreneurshipTitle: {
    fa: "کارآفرینی و فرصت‌های شغلی",
    en: "Entrepreneurship and Job Opportunities",
    tr: "Girişimcilik ve İş Fırsatları"
  },
  announcementTitle: {
    fa: "🎓 فرصت طلایی: بورسیه تحصیلی 50%",
    en: "🎓 Golden Chance: 50% Scholarship Opportunity",
    tr: "🎓 Altın Fırsat: %50 Burs İmkanı"
  },
  announcementText: {
    fa: "با ثبت‌نام از طریق مجموعه UniManager، مسیر موفقیت خود را هموار کنید! تنها با چند کلیک، واجد شرایط دریافت بورسیه ۵۰ درصدی خواهید شد و هزینه‌های تحصیل شما به‌طور چشمگیری کاهش می‌یابد. این یک پیشنهاد محدود برای آینده‌سازان است!",
    en: "Pave your path to success with UniManager! By registering through our platform, you become eligible for an exclusive 50% scholarship that significantly reduces your tuition costs. This is a limited-time offer for tomorrow's leaders!",
    tr: "UniManager ile başarı yolunuzu açın! Platformumuz aracılığıyla kayıt olarak, öğrenim masraflarınızı önemli ölçüde azaltacak özel %50 burs için uygun hale gelirsiniz. Bu fırsat, geleceğin liderleri için sınırlı bir tekliftir!"
  },
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  faqData: [
    {
      question: {
        fa: "شرایط پذیرش دانشگاه باهچه شهیر چیست؟",
        en: "What are the admission requirements for Bahcesehir University?",
        tr: "Bahçeşehir Üniversitesi'ne kabul şartları nelerdir?"
      },
      answer: {
        fa: "برای پذیرش در دانشگاه باهچه شهیر، ارائه مدارک تحصیلی معتبر، نمرات خوب و در برخی موارد مصاحبه الزامی است.",
        en: "To be admitted to Bahcesehir University, you must submit valid academic certificates, have good grades, and in some cases, attend an interview.",
        tr: "Bahçeşehir Üniversitesi'ne kabul için geçerli akademik belgeler sunmak, iyi notlar almak ve bazı durumlarda mülakata katılmak gerekmektedir."
      }
    },
    {
      question: {
        fa: "شهریه تحصیلی دانشگاه باهچه شهیر در سال 2025 چقدر است؟",
        en: "What is the tuition fee for Bahcesehir University in 2025?",
        tr: "2025 yılında Bahçeşehir Üniversitesi'nin öğrenim ücreti ne kadardır?"
      },
      answer: {
        fa: "شهریه تحصیلی دانشگاه باهچه شهیر بسته به رشته از ۵۰۰۰ تا ۱۲۰۰۰ دلار متغیر است.",
        en: "The tuition fee at Bahcesehir University in 2025 varies between $5,000 and $12,000 depending on the program.",
        tr: "2025 yılında Bahçeşehir Üniversitesi'nde öğrenim ücreti, programa bağlı olarak 5.000 ila 12.000 dolar arasında değişmektedir."
      }
    },
    {
      question: {
        fa: "چه رشته‌هایی در دانشگاه باهچه شهیر ارائه می‌شود؟",
        en: "What programs or faculties are offered at Bahcesehir University?",
        tr: "Bahçeşehir Üniversitesi'nde hangi bölümler sunulmaktadır?"
      },
      answer: {
        fa: "دانشگاه باهچه شهیر در رشته‌های مختلفی از جمله مهندسی، پزشکی، حقوق، علوم اجتماعی، مدیریت، معماری و سایر رشته‌ها فعالیت می‌کند.",
        en: "Bahcesehir University offers various programs including Engineering, Medicine, Law, Social Sciences, Management, Architecture, and more.",
        tr: "Bahçeşehir Üniversitesi, Mühendislik, Tıp, Hukuk, Sosyal Bilimler, Yönetim, Mimarlık ve daha fazlasını içeren bölümler sunmaktadır."
      }
    },
    {
      question: {
        fa: "دانشگاه باهچه شهیر چه امکانات بین‌المللی ارائه می‌دهد؟",
        en: "What international opportunities and facilities does Bahcesehir University offer?",
        tr: "Bahçeşehir Üniversitesi hangi uluslararası olanakları sunuyor?"
      },
      answer: {
        fa: "دانشگاه باهچه شهیر با قراردادهای تبادل دانشجو و استاد با دانشگاه‌های برتر جهان و امکانات مدرن بین‌المللی، محیطی جهانی برای یادگیری فراهم می‌کند.",
        en: "Bahcesehir University offers global learning opportunities through student and faculty exchange programs with top universities, along with modern international facilities.",
        tr: "Bahçeşehir Üniversitesi, dünyanın önde gelen üniversiteleriyle öğrenci ve öğretim üyesi değişim programları ile modern uluslararası olanaklar sunarak küresel bir öğrenme ortamı sağlar."
      }
    },
    {
      question: {
        fa: "آیا دانشگاه باهچه شهیر برای دانشجویان بین‌المللی بورسیه ارائه می‌دهد؟",
        en: "Does Bahcesehir University offer scholarships for international students?",
        tr: "Bahçeşehir Üniversitesi uluslararası öğrenciler için burs imkanı sağlıyor mu?"
      },
      answer: {
        fa: "بله، دانشگاه باهچه شهیر بورسیه‌هایی شامل تخفیف شهریه و کمک هزینه‌های مالی به دانشجویان بین‌المللی ارائه می‌دهد.",
        en: "Yes, Bahcesehir University offers scholarships, including tuition discounts and financial aid for international students.",
        tr: "Evet, Bahçeşehir Üniversitesi uluslararası öğrenciler için öğrenim ücreti indirimleri ve maddi destek sağlayan burs olanakları sunmaktadır."
      }
    },
    {
      question: {
        fa: "فرآیند پذیرش در دانشگاه باهچه شهیر چگونه است؟",
        en: "What is the application process for admission at Bahcesehir University?",
        tr: "Bahçeşehir Üniversitesi'ne kabul süreci nasıl işlemektedir?"
      },
      answer: {
        fa: "فرآیند پذیرش شامل ارائه مدارک تحصیلی، مصاحبه (در صورت نیاز) و ارزیابی نمرات می‌باشد.",
        en: "The admission process involves submitting academic documents, an interview (if required), and evaluation of your grades.",
        tr: "Kabul süreci, akademik belgelerin sunulması، gerekirse mülakat ve notların değerlendirilmesini içerir."
      }
    },
    {
      question: {
        fa: "امکانات رفاهی و خوابگاه‌های دانشگاه باهچه شهیر چگونه است؟",
        en: "What are the accommodation and campus facilities like at Bahcesehir University?",
        tr: "Bahçeşehir Üniversitesi'nin konaklama ve kampüs olanakları nasıldır?"
      },
      answer: {
        fa: "دانشگاه باهچه شهیر امکانات رفاهی مدرن، خوابگاه‌های مجهز و خدمات جامعی برای دانشجویان فراهم کرده است.",
        en: "Bahcesehir University provides modern amenities, well-equipped dormitories, and comprehensive services for its students.",
        tr: "Bahçeşehir Üniversitesi, modern olanaklar, donanımlı yurtlar ve öğrencilere yönelik kapsamlı hizmetler sunmaktadır."
      }
    }
  ],
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  newsData: [
    {
      title: {
        fa: "برگزاری سمینار بین‌المللی فناوری",
        en: "International Technology Seminar Held",
        tr: "Uluslararası Teknoloji Semineri Düzenlendi"
      },
      date: "۱۴۰۲/۰۶/۱۰",
      description: {
        fa: "در این رویداد، اساتید و متخصصان بین‌المللی در حوزه فناوری گرد هم آمدند.",
        en: "In this event, international faculty and technology experts gathered.",
        tr: "Bu etkinlikte, uluslararası öğretim üyeleri ve teknoloji uzmanları bir araya geldi."
      }
    },
    {
      title: {
        fa: "نمایشگاه علمی دانشجویی",
        en: "Student Scientific Exhibition",
        tr: "Öğrenci Bilim Sergisi"
      },
      date: "۱۴۰۲/۰۵/۲۵",
      description: {
        fa: "دانشجویان در نمایشگاهی جذاب به معرفی پروژه‌های تحقیقاتی خود پرداختند.",
        en: "Students presented their research projects in an engaging exhibition.",
        tr: "Öğrenciler çekici bir sergide araştırma projelerini sundular."
      }
    },
    {
      title: {
        fa: "افتتاح مرکز نوآوری و استارتاپ",
        en: "Inauguration of the Innovation and Startup Center",
        tr: "İnovasyon ve Girişim Merkezi Açılışı"
      },
      date: "۱۴۰۲/۰۴/۱۵",
      description: {
        fa: "با هدف حمایت از جوانان کارآفرین، مرکز نوآوری دانشگاه به صورت رسمی افتتاح شد.",
        en: "Aimed at supporting young entrepreneurs, the university's Innovation Center was officially inaugurated.",
        tr: "Girişimci gençleri desteklemek amacıyla, üniversitenin İnovasyon Merkezi resmen açıldı."
      }
    }
  ],
  researchData: [
    {
      title: {
        fa: "پژوهشی در حوزه هوش مصنوعی",
        en: "Research in Artificial Intelligence",
        tr: "Yapay Zeka Alanında Araştırma"
      },
      date: "۱۴۰۲/۰۳/۲۰",
      description: {
        fa: "نتایج یک مطالعه پیشرفته در زمینه هوش مصنوعی و یادگیری ماشین.",
        en: "Results of an advanced study in AI and machine learning.",
        tr: "Yapay zeka ve makine öğrenimi üzerine ileri düzey bir çalışmanın sonuçları."
      }
    },
    {
      title: {
        fa: "تحقیقات در زمینه پزشکی نوین",
        en: "Research in Modern Medicine",
        tr: "Modern Tıp Alanında Araştırma"
      },
      date: "۱۴۰۲/۰۲/۱۰",
      description: {
        fa: "بررسی تکنیک‌های جدید در درمان بیماری‌های مزمن و نادر.",
        en: "Exploring new techniques in treating chronic and rare diseases.",
        tr: "Kronik ve nadir hastalıkların tedavisinde yeni tekniklerin incelenmesi."
      }
    },
    {
      title: {
        fa: "تحلیل داده‌های بزرگ در مهندسی عمران",
        en: "Big Data Analysis in Civil Engineering",
        tr: "İnşaat Mühendisliğinde Büyük Veri Analizi"
      },
      date: "۱۴۰۲/۰۱/۳۰",
      description: {
        fa: "کاربرد روش‌های نوین تحلیل داده در بهبود سازه‌های عمرانی.",
        en: "Application of modern data analysis methods in improving structural engineering.",
        tr: "Yapısal mühendislikte modern veri analiz yöntemlerinin uygulanması."
      }
    }
  ],
  entrepreneurshipData: [
    {
      title: {
        fa: "کارگاه استارتاپینگ",
        en: "Startup Workshop",
        tr: "Startup Atölyesi"
      },
      description: {
        fa: "آموزش‌های تخصصی برای راه‌اندازی استارتاپ و کسب و کار نوپا.",
        en: "Specialized training for launching a startup and new businesses.",
        tr: "Startup ve yeni işletmelerin kurulması için özel eğitim."
      }
    },
    {
      title: {
        fa: "مشاوره شغلی",
        en: "Career Counseling",
        tr: "Kariyer Danışmanlığı"
      },
      description: {
        fa: "ارائه خدمات مشاوره و راهنمایی جهت ورود به بازار کار.",
        en: "Providing counseling and guidance for entering the job market.",
        tr: "İş piyasasına giriş için danışmanlık ve rehberlik hizmetleri sunuluyor."
      }
    },
    {
      title: {
        fa: "نمایشگاه استخدامی",
        en: "Job Fair",
        tr: "İstihdam Fuarı"
      },
      description: {
        fa: "فرصت‌های شغلی و کارآفرینی در همایش‌های تخصصی دانشگاه.",
        en: "Job and entrepreneurial opportunities at specialized university events.",
        tr: "Üniversitenin özel etkinliklerinde iş ve girişimcilik fırsatları."
      }
    }
  ],
  faqTitle: {
    fa: "سوالات متداول",
    en: "Frequently Asked Questions",
    tr: "Sık Sorulan Sorular"
  },
  financialTitle: {
    fa: "خدمات مالی و بورسیه‌ها",
    en: "Financial Services and Scholarships",
    tr: "Mali Hizmetler ve Burslar"
  },
  financialAidData: [
    {
      title: {
        fa: "بورسیه تحصیلی کامل",
        en: "Full Scholarship",
        tr: "Tam Burs"
      },
      description: {
        fa: "پوشش کامل شهریه و هزینه‌های زندگی برای دانشجویان برجسته.",
        en: "Full coverage of tuition and living expenses for outstanding students.",
        tr: "Önemli öğrenciler için öğrenim ve yaşam masraflarının tam karşılanması."
      }
    },
    {
      title: {
        fa: "بورسیه جزئی",
        en: "Partial Scholarship",
        tr: "Kısmi Burs"
      },
      description: {
        fa: "تخفیف قابل توجهی در شهریه به همراه کمک هزینه‌های جزئی.",
        en: "Significant tuition discount along with partial financial aid.",
        tr: "Önemli öğrenim ücreti indirimi ve kısmi maddi destek."
      }
    },
    {
      title: {
        fa: "وام دانشجویی",
        en: "Student Loan",
        tr: "Öğrenci Kredisi"
      },
      description: {
        fa: "امکان دریافت وام با شرایط مساعد جهت تأمین هزینه‌های تحصیلی.",
        en: "Availability of loans under favorable conditions to cover tuition fees.",
        tr: "Öğrenim ücretlerini karşılamak için uygun şartlarda kredi imkanı."
      }
    }
  ]
};

const facultiesData = [
  { 
    name: { 
      fa: "دانشکده مهندسی", 
      en: "Faculty of Engineering", 
      tr: "Mühendislik Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های تحصیلی معتبر در رشته‌های مهندسی و فناوری اطلاعات.", 
      en: "Accredited academic programs in engineering and information technology.", 
      tr: "Mühendislik ve bilgi teknolojileri alanında saygın akademik programlar." 
    }
  },
  { 
    name: { 
      fa: "دانشکده پزشکی", 
      en: "Faculty of Medicine", 
      tr: "Tıp Fakültesi" 
    },
    description: { 
      fa: "آموزش و پژوهش‌های پیشرو در زمینه‌های بالینی و پزشکی.", 
      en: "Pioneering education and research in clinical and medical fields.", 
      tr: "Klinik ve tıbbi alanlarda öncü eğitim ve araştırmalar." 
    }
  },
  { 
    name: { 
      fa: "دانشکده حقوق", 
      en: "Faculty of Law", 
      tr: "Hukuk Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های حقوقی با استانداردهای بین‌المللی و همکاری اساتید برجسته.", 
      en: "Internationally standardized law programs with collaborations with eminent professors.", 
      tr: "Uluslararası standartlara sahip hukuk programları ve önde gelen akademisyenlerle işbirliği." 
    }
  },
  { 
    name: { 
      fa: "دانشکده علوم اجتماعی", 
      en: "Faculty of Social Sciences", 
      tr: "Sosyal Bilimler Fakültesi" 
    },
    description: { 
      fa: "تحلیل و آموزش علوم انسانی و اجتماعی در سطح جهانی.", 
      en: "Global level analysis and education in humanities and social sciences.", 
      tr: "İnsan ve sosyal bilimlerde küresel düzeyde analiz ve eğitim." 
    }
  },
  { 
    name: { 
      fa: "دانشکده مدیریت", 
      en: "Faculty of Management", 
      tr: "Yönetim Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های مدیریت با رویکرد نوآورانه و جهانی.", 
      en: "Innovative and globally oriented management programs.", 
      tr: "Yenilikçi ve küresel bakış açısına sahip yönetim programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده معماری", 
      en: "Faculty of Architecture", 
      tr: "Mimarlık Fakültesi" 
    },
    description: { 
      fa: "طراحی مدرن و خلاقانه در معماری و ساختار.", 
      en: "Modern and creative design in architecture and construction.", 
      tr: "Mimarlık ve yapı tasarımında modern ve yaratıcı yaklaşımlar." 
    }
  },
  { 
    name: { 
      fa: "دانشکده علوم کامپیوتر", 
      en: "Faculty of Computer Science", 
      tr: "Bilgisayar Bilimleri Fakültesi" 
    },
    description: { 
      fa: "توسعه نرم‌افزار و فناوری اطلاعات با بکارگیری آخرین دستاوردها.", 
      en: "Software development and information technology utilizing the latest innovations.", 
      tr: "En son teknolojik gelişmeleri kullanan yazılım geliştirme ve bilgi teknolojileri." 
    }
  },
  { 
    name: { 
      fa: "دانشکده هنر", 
      en: "Faculty of Arts", 
      tr: "Güzel Sanatlar Fakültesi" 
    },
    description: { 
      fa: "برنامه‌های هنری با خلاقیت و نوآوری در هنرهای تجسمی.", 
      en: "Art programs fostering creativity and innovation in visual arts.", 
      tr: "Görsel sanatlarda yaratıcılık ve yeniliği teşvik eden sanat programları." 
    }
  },
  { 
    name: { 
      fa: "دانشکده ارتباطات", 
      en: "Faculty of Communications", 
      tr: "İletişim Fakültesi" 
    },
    description: { 
      fa: "آموزش‌های ارتباطات و رسانه با تمرکز بر فناوری نوین.", 
      en: "Communication and media education focusing on modern technologies.", 
      tr: "Modern teknolojilere odaklanan iletişim ve medya eğitimi." 
    }
  }
];

const FacultyCard = ({ name, description }) => (
  <div className="uni-faculty-card">
    <h3 className="uni-faculty-card-title">{name}</h3>
    <p className="uni-faculty-card-text">{description}</p>
  </div>
);

const TuitionTable = ({ lang }) => {
  const tuitionData = [
    {
      faculty: { 
        fa: "پزشکی ", 
        en: "Medicine ", 
        tr: "Tıp " 
      },
      fee: { fa: "$28,000", en: "$28,000", tr: "$28,000" },
      description: {
        fa: "آموزش در زمینه‌های پزشکی با تأکید بر پژوهش‌های بالینی و پزشکی پیشرفته",
        en: "Education in medicine with an emphasis on clinical research and advanced medical practices",
        tr: "Klinik araştırmalar ve ileri tıbbi uygulamalara vurgu yapan tıp eğitimi"
      }
    },
    {
      faculty: { 
        fa: "دندانپزشکی ", 
        en: "Dentistry ", 
        tr: "Diş Hekimliği " 
      },
      fee: { fa: "$24,000", en: "$24,000", tr: "$24,000" },
      description: {
        fa: "تحصیل در زمینه دندانپزشکی و درمان‌های پیشرفته دندانی",
        en: "Study in dentistry and advanced dental treatments",
        tr: "Diş hekimliği ve ileri diş tedavileri üzerine آموزش"
      }
    },
    {
      faculty: { 
        fa: "داروسازی ", 
        en: "Pharmacy ", 
        tr: "Eczacılık " 
      },
      fee: { fa: "$15,000", en: "$15,000", tr: "$15,000" },
      description: {
        fa: "آموزش در زمینه داروسازی و علم داروها",
        en: "Education in pharmacy and pharmaceutical sciences",
        tr: "Eczacılık ve ilaç bilimleri eğitimi"
      }
    },
    {
      faculty: { 
        fa: "فیزیوتراپی ", 
        en: "Physiotherapy ", 
        tr: "Fizyoterapi " 
      },
      fee: { fa: "$6,375", en: "$6,375", tr: "$6,375" },
      description: {
        fa: "دوره‌های فیزیوتراپی با تمرکز بر درمان‌های جسمی و حرکت شناسی",
        en: "Physiotherapy courses focusing on physical therapy and kinesiology",
        tr: "Fizyoterapi dersleri, fiziksel tedavi ve kinezyolojiye odaklanarak"
      }
    },
    {
      faculty: { 
        fa: "پرستاری ", 
        en: "Nursing ", 
        tr: "Hemşirelik " 
      },
      fee: { fa: "$5,950", en: "$5,950", tr: "$5,950" },
      description: {
        fa: "آموزش در زمینه پرستاری و مراقبت‌های بهداشتی",
        en: "Education in nursing and healthcare",
        tr: "Hemşirelik ve sağlık hizmetleri eğitimi"
      }
    },
    {
      faculty: { 
        fa: "تغذیه ", 
        en: "Nutrition ", 
        tr: "Beslenme " 
      },
      fee: { fa: "$5,950", en: "$5,950", tr: "$5,950" },
      description: {
        fa: "دوره‌های آموزشی در زمینه تغذیه و سلامت عمومی",
        en: "Courses in nutrition and public health",
        tr: "Beslenme ve halk sağlığı dersleri"
      }
    },
    {
      faculty: { 
        fa: "هوش مصنوعی ", 
        en: "Artificial Intelligence ", 
        tr: "Yapay Zeka " 
      },
      fee: { fa: "$12,000", en: "$12,000", tr: "$12,000" },
      description: {
        fa: "تحقیق و آموزش در زمینه یادگیری ماشین و هوش مصنوعی",
        en: "Research and education in machine learning and artificial intelligence",
        tr: "Makine öğrenimi ve yapay zeka üzerine araştırma ve eğitim"
      }
    },
    {
      faculty: { 
        fa: "معماری ", 
        en: "Architecture ", 
        tr: "Mimarlık " 
      },
      fee: { fa: "$8,500", en: "$8,500", tr: "$8,500" },
      description: {
        fa: "آموزش در زمینه طراحی معماری با تمرکز بر طراحی نوآورانه",
        en: "Education in architectural design with an emphasis on innovative design",
        tr: "Yenilikçi tasarıma vurgu yapan mimari tasarım eğitimi"
      }
    },
    {
      faculty: { 
        fa: "فیلم و تلویزیون ", 
        en: "Film and Television ", 
        tr: "Film ve Televizyon " 
      },
      fee: { fa: "$8,500", en: "$8,500", tr: "$8,500" },
      description: {
        fa: "دوره‌های آموزشی در زمینه فیلم‌سازی و صنعت تلویزیون",
        en: "Courses in filmmaking and the television industry",
        tr: "Film yapımı ve televizyon sektörü üzerine dersler"
      }
    },
    {
      faculty: { 
        fa: "مهندسی عمران ", 
        en: "Civil Engineering ", 
        tr: "İnşaat Mühendisliği " 
      },
      fee: { fa: "$9,000", en: "$9,000", tr: "$9,000" },
      description: {
        fa: "آموزش در زمینه طراحی و ساخت سازه‌های عمرانی",
        en: "Education in designing and constructing civil structures",
        tr: "Altyapı yapıların tasarımı ve inşası eğitimi"
      }
    },
    {
      faculty: { 
        fa: "مهندسی کامپیوتر ", 
        en: "Computer Engineering ", 
        tr: "Bilgisayar Mühendisliği " 
      },
      fee: { fa: "$9,000", en: "$9,000", tr: "$9,000" },
      description: {
        fa: "آموزش در زمینه برنامه‌نویسی، توسعه نرم‌افزار و داده‌کاوی",
        en: "Education in programming, software development, and data mining",
        tr: "Programlama, yazılım geliştirme ve veri madenciliği eğitimi"
      }
    },
    {
      faculty: { 
        fa: "روابط عمومی ", 
        en: "Public Relations ", 
        tr: "Halkla İlişkiler " 
      },
      fee: { fa: "$8,500", en: "$8,500", tr: "$8,500" },
      description: {
        fa: "آموزش در زمینه ارتباطات، رسانه‌ها و مدیریت روابط عمومی",
        en: "Education in communications, media, and public relations management",
        tr: "İletişim, medya ve halkla ilişkiler yönetimi eğitimi"
      }
    },
    {
      faculty: { 
        fa: "معماری داخلی ", 
        en: "Interior Architecture ", 
        tr: "İç Mimarlık " 
      },
      fee: { fa: "$8,500", en: "$8,500", tr: "$8,500" },
      description: {
        fa: "دوره‌های آموزشی در زمینه طراحی داخلی و دکوراسیون",
        en: "Courses in interior design and decoration",
        tr: "İç tasarım ve dekorasyon dersleri"
      }
    }
  ];

  const headers =
    lang === "fa"
      ? [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerFee[lang],
          translations.tuitionTable.headerDescription[lang]
        ]
      : [
          translations.tuitionTable.headerFaculty[lang],
          translations.tuitionTable.headerDescription[lang],
          translations.tuitionTable.headerFee[lang]
        ];

  return (
    <table className="uni-tuition-table">
      <thead>
        <tr>
          <th>{headers[0]}</th>
          <th>{headers[1]}</th>
          <th>{headers[2]}</th>
        </tr>
      </thead>
      <tbody>
        {tuitionData.map((item, index) =>
          lang === "fa" ? (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.fee[lang]}</td>
              <td>{item.description[lang]}</td>
            </tr>
          ) : (
            <tr key={index}>
              <td>{item.faculty[lang]}</td>
              <td>{item.description[lang]}</td>
              <td>{item.fee[lang]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

const FAQSection = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);
  return (
    <section className="uni-faq-section">
      <h2 className="uni-faq-title">{translations.faqTitle[lang]}</h2>
      <div className="uni-faq-grid">
        {translations.faqData.map((faq, index) => (
          <div
            key={index}
            className="uni-faq-card"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="uni-faq-question">{faq.question[lang]}</h3>
            {activeIndex === index && (
              <p className="uni-faq-answer">{faq.answer[lang]}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

const BahcesehirUniversityMiddle = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className={`uni-container ${lang !== "fa" ? "ltr" : ""}`}>
      {/* دانشکده‌ها */}
      <section className="uni-faculties-section">
        <h2 className="uni-faculties-title">{translations.facultiesTitle[lang]}</h2>
        <div className="uni-faculties-grid">
          {facultiesData.map((item, index) => (
            <FacultyCard 
              key={index} 
              name={item.name[lang]} 
              description={item.description[lang]} 
            />
          ))}
        </div>
      </section>

      {/* جدول شهریه */}
      <section className="uni-tuition-section">
        <h2 className="uni-tuition-title">{translations.tuitionTitle[lang]}</h2>
        <TuitionTable lang={lang} />
      </section>

      {/* اخبار و رویدادها */}
      <section className="uni-news-section">
        <h2 className="uni-news-title">{translations.newsTitle[lang]}</h2>
        <div className="uni-news-grid">
          {translations.newsData.map((news, index) => (
            <div key={index} className="uni-news-card">
              <h3 className="uni-news-card-title">{news.title[lang]}</h3>
              <span className="uni-news-card-date">
                {formatDate(news.date, lang)}
              </span>
              <p className="uni-news-card-text">{news.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* مقالات تحقیقاتی */}
      <section className="uni-research-section">
        <h2 className="uni-research-title">{translations.researchTitle[lang]}</h2>
        <div className="uni-research-grid">
          {translations.researchData.map((article, index) => (
            <div key={index} className="uni-research-card">
              <h3 className="uni-research-card-title">{article.title[lang]}</h3>
              <span className="uni-research-card-date">{formatDate(article.date, lang)}</span>
              <p className="uni-research-card-text">{article.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* همکاری‌های بین‌المللی */}
      <section className="uni-collaboration-section">
        <h2 className="uni-collaboration-title">{translations.collaborationTitle[lang]}</h2>
        <div className="uni-collaboration-grid">
          {translations.collaborationData.map((item, index) => (
            <div 
              key={index} 
              className="uni-collaboration-item"
              style={{
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.name[lang]}
              </a>
              <div className="uni-collaboration-ranking">
                {lang === "fa"
                  ? `رتبه جهانی: ${item.ranking}`
                  : `Global Ranking: ${item.ranking}`}
                {item.icon}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* کارآفرینی و فرصت‌های شغلی */}
      <section className="uni-entrepreneurship-section">
        <h2 className="uni-entrepreneurship-title">{translations.entrepreneurshipTitle[lang]}</h2>
        <div className="uni-entrepreneurship-grid">
          {translations.entrepreneurshipData.map((item, index) => (
            <div key={index} className="uni-entrepreneurship-card">
              <h3 className="uni-entrepreneurship-card-title">{item.title[lang]}</h3>
              <p className="uni-entrepreneurship-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>

      {/* اعلان ویژه بورسیه */}
      <section className="uni-announcement-section">
        <div
          className="announcement-bg-overlay"
          style={{ backgroundImage: `url(${uniManagerImage})` }}
        ></div>
        <div className="announcement-content">
          <h2 className="uni-announcement-title">
            {translations.announcementTitle[lang]}
          </h2>
          <p className="uni-announcement-text">
            {translations.announcementText[lang]}
          </p>
        </div>
      </section>

      {/* سوالات متداول */}
      <FAQSection lang={lang} />

      {/* خدمات مالی و بورسیه */}
      <section className="uni-financial-section">
        <h2 className="uni-financial-title">{translations.financialTitle[lang]}</h2>
        <div className="uni-financial-grid">
          {translations.financialAidData.map((item, index) => (
            <div key={index} className="uni-financial-card">
              <h3 className="uni-financial-card-title">{item.title[lang]}</h3>
              <p className="uni-financial-card-text">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default BahcesehirUniversityMiddle;
