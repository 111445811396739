import React from "react";
import Lottie from "react-lottie"; // کتابخانه نمایش انیمیشن
import { useLanguage } from "../contexts/LanguageContext"; // استفاده از context برای مدیریت زبان
import "../components/styles/About.css"; // فایل CSS اختصاصی صفحه About
import aboutUsAnimation from "../assets/animation/AboutUs.json"; // ایمپورت انیمیشن
import Footer from "../components/Footer"; // ایمپورت فوتر

const About = () => {
  // استفاده از context برای مدیریت زبان
  const { language } = useLanguage();

  // تنظیمات انیمیشن Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aboutUsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

// محتوای صفحه درباره ما به سه زبان
const aboutContent = {
  English: `Welcome to Uni Manager, your gateway to academic excellence and a global future. At Uni Manager, we understand that applying to universities and navigating the complex world of visas can be overwhelming. That’s why we’re here to be your trusted ally in every step of your journey.

We specialize in providing bespoke services for students who aspire to study abroad. From personalized university admission guidance to visa processing, scholarship assistance, and even accommodation support, we’ve got you covered.

Thanks to our strong and trusted relationships with top-tier universities, we offer exclusive 50% bonuses for all universities in our network, making your educational journey not only smoother but also more affordable. Whether you’re applying to universities in Istanbul or any other city in Turkey, we’re committed to making the entire process simpler, faster, and more cost-effective.

Additionally, we offer personalized, face-to-face consultations in both Istanbul and Tabriz, ensuring you get one-on-one support to address your unique needs.

At Uni Manager, we believe your dreams should have no boundaries. Join us and let us help you unlock the doors to a bright, successful, and global future.`,

  Persian: `به Uni Manager خوش آمدید، جایی که در آن می‌توانید به رویاهای تحصیلی‌تان رنگ واقعیت ببخشید و در مسیر موفقیت‌های جهانی گام بردارید. در Uni Manager، ما می‌دانیم که فرآیند اپلای به دانشگاه‌ها و اخذ ویزا می‌تواند پیچیده و دلهره‌آور باشد، به همین دلیل ما اینجا هستیم تا همراه قابل اعتماد شما در تمامی مراحل این مسیر باشیم.

ما تخصص داریم در ارائه خدمات شخصی‌سازی‌شده برای دانشجویانی که به تحصیل در خارج از کشور علاقه دارند. از مشاوره دقیق برای پذیرش دانشگاه‌ها، پردازش ویزا، کمک به دریافت بورسیه، تا پشتیبانی در زمینه خوابگاه‌ها، تمامی نیازهای شما را در کنار هم تامین می‌کنیم.

با توجه به روابط مستحکم و معتبر با دانشگاه‌های برتر، ما بونوس‌های ۵۰ درصدی اختصاصی برای تمامی دانشگاه‌هایی که با آنها همکاری داریم ارائه می‌دهیم. این همکاری‌ها مسیر تحصیلی شما را نه تنها هموارتر، بلکه مقرون‌به‌صرفه‌تر می‌کند. چه قصد اپلای به دانشگاه‌های استانبول را داشته باشید و چه سایر نقاط ترکیه، ما اینجا هستیم تا کل روند را سریع‌تر، ساده‌تر و اقتصادی‌تر کنیم.

علاوه بر این، مشاوره حضوری در استانبول و تبریز نیز برای شما فراهم است تا از نزدیک و به‌طور شخصی در تمامی مراحل راهنمای شما باشیم.

در Uni Manager ما به این باور داریم که رویاهای شما هیچ مرزی ندارند. به ما بپیوندید و اجازه دهید در باز کردن دروازه‌های یک آینده روشن، موفق و جهانی همراه شما باشیم.`,

  Turkish: `Uni Manager'a hoş geldiniz, burada akademik hayalleriniz gerçeğe dönüşüyor ve küresel bir geleceğe adım atıyorsunuz. Uni Manager olarak, üniversite başvuruları ve vize işlemleri gibi karmaşık süreçlerin sizi zorlamaması gerektiğine inanıyoruz. Bu yüzden her adımda yanınızda olmak ve size güvenilir bir rehberlik sağlamak için buradayız.

Yurt dışında eğitim almak isteyen öğrencilere özel çözümler sunuyoruz. Kişiye özel üniversite kabul rehberliği, vize işlemleri, burs desteği ve konaklama yardımı gibi hizmetlerimizle her konuda yanınızdayız.

En iyi üniversitelerle olan güçlü ve güvenilir ilişkilerimiz sayesinde, tüm üniversitelerimizde özel %50 bonuslar sunuyoruz. Bu sayede eğitim yolculuğunuz hem daha kolay hem de daha uygun fiyatlı oluyor. İster İstanbul’daki üniversitelere başvuruyor olun, ister Türkiye’nin diğer şehirlerine, her adımda size yardımcı olmak için buradayız.

Ayrıca, İstanbul ve Trabzon'da yüz yüze danışmanlık hizmeti de sunuyoruz. Bu sayede, her adımda size kişisel destek sağlayarak, tüm süreçleri sorunsuz bir şekilde yönetmenize yardımcı oluyoruz.

Uni Manager'da, hayallerinizin hiçbir sınırı olmasını istiyoruz. Bizimle birlikte olun ve parlak, başarılı ve küresel bir geleceğe giden kapıları birlikte açalım.`,
};


  return (
    <div className="aboutus-container">
      {/* بخش انیمیشن */}
      <div className="about-animation-container">
        <Lottie
          options={defaultOptions}
          style={{ width: "100%", maxWidth: "800px", overflow: "visible" }}
        />
      </div>

      {/* محتوای صفحه درباره ما */}
      <div className={`about-content ${language === "fa" ? "rtl" : "ltr"}`}>
        <h1>
          {language === "en" && "About Us"}
          {language === "fa" && "درباره ما"}
          {language === "tr" && "Hakkımızda"}
        </h1>
        <p>{aboutContent[language === "fa" ? "Persian" : language === "en" ? "English" : "Turkish"]}</p>
      </div>

      {/* فوتر صفحه */}
      <Footer language={language} />
    </div>
  );
};

export default About;
