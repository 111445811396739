import React, { useState } from "react";
import "../../components/styles/Uni3.css";
import Footer from "../Footer"; // Importing the Footer component

// شی ترجمه برای بخش پایینی
const translations = {
  rankingTitle: {
    fa: "رتبه‌بندی دانشگاه‌ها و مقایسه",
    en: "University Rankings and Comparison",
    tr: "Üniversite Sıralamaları ve Karşılaştırma"
  },
  tableHeaders: {
    university: { fa: "دانشگاه", en: "University", tr: "Üniversite" },
    ranking: { fa: "رتبه", en: "Ranking", tr: "Sıra" },
    score: { fa: "امتیاز", en: "Score", tr: "Puan" },
    field: { fa: "رشته", en: "Field", tr: "Bölüm" },
    feeUSD: { fa: "شهریه (USD)", en: "Tuition (USD)", tr: "Öğrenim Ücreti (USD)" },
    feeIRR: { fa: "شهریه (IRR)", en: "Tuition (IRR)", tr: "Öğrenim Ücreti (IRR)" },
    feeTRY: { fa: "شهریه (TRY)", en: "Tuition (TRY)", tr: "Öğrenim Ücreti (TRY)" },
    feeAED: { fa: "شهریه (AED)", en: "Tuition (AED)", tr: "Öğrenim Ücreti (AED)" },
  },
  calculatorTitle: {
    fa: "ماشین حساب شهریه",
    en: "Tuition Fee Calculator",
    tr: "Öğrenim Ücreti Hesaplayıcı"
  },
  selectFieldLabel: {
    fa: "رشته تحصیلی خود را انتخاب کنید:",
    en: "Select your field of study:",
    tr: "Çalışma alanınızı seçin:"
  },
  options: {
    medical: { fa: "پزشکی", en: "Medical", tr: "Tıp" },
    engineering: { fa: "مهندسی", en: "Engineering", tr: "Mühendislik" },
    business: { fa: "مدیریت و تجارت", en: "Business", tr: "İşletme" },
    other: { fa: "سایر", en: "Other", tr: "Diğer" }
  },
  calculateButton: {
    fa: "محاسبه شهریه",
    en: "Calculate Tuition",
    tr: "Öğrenim Ücreti Hesapla"
  },
  resultTitle: {
    fa: "نتیجه محاسبه شهریه",
    en: "Tuition Calculation Result",
    tr: "Öğrenim Ücreti Hesaplama Sonucu"
  },
  tuitionInUSD: {
    fa: "شهریه به دلار:",
    en: "Tuition in USD:",
    tr: "USD cinsinden öğrenim ücreti:"
  },
  inIRR: {
    fa: "به ریال:",
    en: "In IRR:",
    tr: "IRR olarak:"
  },
  inTRY: {
    fa: "به لیر:",
    en: "In TRY:",
    tr: "TRY olarak:"
  },
  inAED: {
    fa: "به دینار دبی:",
    en: "In AED:",
    tr: "AED olarak:"
  },
  mapTitle: {
    fa: "نقشه دانشگاه اطلس",
    en: "Atlas University Map",
    tr: "Atlas Üniversitesi Haritası"
  },
};

// نرخ تبدیل ارز
const conversionRates = {
  IRR: 100000, // 1 دلار = 100,000 تومان
  TRY: 2700,   // 1 دلار = 2,700 لیر
  AED: 28000   // 1 دلار = 28,000 درهم
};

// داده‌های رتبه‌بندی به صورت سه‌زبانه
const rankingData = [
  {
    university: { 
      fa: "دانشگاه اطلس", 
      en: "Atlas University", 
      tr: "Atlas Üniversitesi" 
    },
    ranking: { 
      fa: "۴ در میان دانشگاه‌های خصوصی استانبول", 
      en: "4th among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 4." 
    },
    score: { 
      fa: "8.2/10", 
      en: "8.2/10", 
      tr: "8.2/10" 
    }
  },
  {
    university: { 
      fa: "دانشگاه آکادمی", 
      en: "Academy University", 
      tr: "Akademi Üniversitesi" 
    },
    ranking: { 
      fa: "۷ در میان دانشگاه‌های خصوصی استانبول", 
      en: "7th among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 7." 
    },
    score: { 
      fa: "7.8/10", 
      en: "7.8/10", 
      tr: "7.8/10" 
    }
  },
  {
    university: { 
      fa: "دانشگاه نیشانتاشی", 
      en: "Nisantasi University", 
      tr: "Nişantaşı Üniversitesi" 
    },
    ranking: { 
      fa: "۵ در میان دانشگاه‌های خصوصی استانبول", 
      en: "5th among private universities in Istanbul", 
      tr: "İstanbul’daki özel üniversiteler arasında 5." 
    },
    score: { 
      fa: "8.0/10", 
      en: "8.0/10", 
      tr: "8.0/10" 
    }
  }
];

const TuitionCalculator = (props) => {
  const lang = props.lang || "fa";
  const [field, setField] = useState("");
  const [result, setResult] = useState(null);
  const [converted, setConverted] = useState(null);

  const calculateFee = (e) => {
    e.preventDefault();
    let feeUSD = 0;
    if (field === "medical") feeUSD = 30000;
    else if (field === "engineering") feeUSD = 10000;
    else if (field === "business") feeUSD = 9500;
    else feeUSD = 6500;
    setResult(feeUSD);
    setConverted({
      IRR: feeUSD * conversionRates.IRR,
      TRY: feeUSD * conversionRates.TRY,
      AED: feeUSD * conversionRates.AED
    });
  };

  return (
    <section className="uni-calculator-section">
      <h2 className="uni-calculator-title">{translations.calculatorTitle[lang]}</h2>
      <form className="uni-calculator-form" onSubmit={calculateFee}>
        <label htmlFor="field-select">{translations.selectFieldLabel[lang]}</label>
        <select
          id="field-select"
          value={field}
          onChange={(e) => setField(e.target.value)}
        >
          <option value="">{`-- ${translations.options.other[lang]} --`}</option>
          <option value="medical">{translations.options.medical[lang]}</option>
          <option value="engineering">{translations.options.engineering[lang]}</option>
          <option value="business">{translations.options.business[lang]}</option>
          <option value="other">{translations.options.other[lang]}</option>
        </select>
        <button type="submit">{translations.calculateButton[lang]}</button>
      </form>
      {result && converted && (
        <div className="conversion-card">
          <h3>{translations.resultTitle[lang]}</h3>
          <p>{translations.tuitionInUSD[lang]} ${result.toLocaleString()}</p>
          <hr />
          <p>
            {translations.inIRR[lang]} {converted.IRR.toLocaleString()} (1 USD =
            {conversionRates.IRR.toLocaleString()} IRR)
          </p>
          <p>
            {translations.inTRY[lang]} {converted.TRY.toLocaleString()} (1 USD =
            {conversionRates.TRY} TRY)
          </p>
          <p>
            {translations.inAED[lang]} {converted.AED.toLocaleString()} (1 USD =
            {conversionRates.AED} AED)
          </p>
        </div>
      )}
    </section>
  );
};

// حذف بخش همکاری‌های بین‌المللی و اخبار و رویدادها

const AtlasUniversityBottom = (props) => {
  const lang = props.lang || "fa";

  return (
    <div className="uni-container">
      {/* بخش رتبه‌بندی */}
      <section className="uni-ranking-section">
        <h2 className="uni-ranking-title">{translations.rankingTitle[lang]}</h2>
        <table className="uni-ranking-table">
          <thead>
            <tr>
              <th>{translations.tableHeaders.university[lang]}</th>
              <th>{translations.tableHeaders.ranking[lang]}</th>
              <th>{translations.tableHeaders.score[lang]}</th>
            </tr>
          </thead>
          <tbody>
            {rankingData.map((item, index) => (
              <tr key={index}>
                <td>{item.university[lang]}</td>
                <td>{item.ranking[lang]}</td>
                <td>{item.score[lang]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* ماشین حساب شهریه */}
      <TuitionCalculator lang={lang} />

      {/* نقشه دانشگاه */}
      <section className="uni-map-section">
        <h2 className="uni-map-title">{translations.mapTitle[lang]}</h2>
        <div className="uni-map-container">
          <iframe
            title="Campus Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3137.123456789!2d28.900000000000!3d41.030000000000!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba1111111111%3A0x2222222222222222!2sAtlas%20University!5e0!3m2!1sen!2str!4v1612145160193!5m2!1sen!2str"
            width="100%"
            height="400"
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </section>

      {/* افزودن فوتر */}
      <Footer language={lang} />
    </div>
  );
};

export default AtlasUniversityBottom;
